import React, { Fragment, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Input, Loader, Button } from 'semantic-ui-react';
import { getAuthUser } from '../auth/selectors';
import { Flex } from '../components';
import { IssueTable } from './IssueTable';
import { getIssues, searchTermMatcher, getIssueLoading } from './selectors';
import { issueFetchAll } from './actions';

export const IssueIndexPage = props => {
  const [searchText, setSearchText] = useState('');
  const user = useSelector(getAuthUser);
  if (user.role !== 'administrator') {
    return (
      <div className="ui message error">
        Jūs neturite prieigos prie šito funkcionalumo.
      </div>
    );
  }
  const issues = useSelector(getIssues)
    .filter(searchTermMatcher(searchText));
  const loading = useSelector(getIssueLoading);
  const dispatch = useDispatch();
  return (
    <Fragment>
      <Flex m={-1} mb={1}>
        <Flex.Item m={1}>
          <h1>Visos registracijos</h1>
        </Flex.Item>
        <Flex.Item m={1}>
          {loading && (
            <Loader inline active />
          ) || (
            <Button basic
              icon="refresh"
              onClick={() => dispatch(issueFetchAll())} />
          )}
        </Flex.Item>
        <Flex.Item grow={1} />
        <Flex.Item m={1}>
          <Input
            icon="search"
            placeholder="Ieškoti..."
            value={searchText}
            onChange={(e, data) => setSearchText(data.value)} />
        </Flex.Item>
      </Flex>
      <IssueTable
        issues={issues}
        fields={{
          seqId: true,
          displayName: true,
          personalCode: true,
          faculty: true,
          studyCycle: true,
          disadvantaged: true,
          createdAt: true,
          type: true,
          status: true,
        }} />
    </Fragment>
  );
};
