import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Header, Icon, Input, Modal, TextArea, Form } from 'semantic-ui-react';
import { requestApi } from '../api';
import { getCampuses } from '../campus/selectors';
import { DatePicker, DropdownSelect, LabeledButton } from '../components';
import { getCandidateFilters } from './selectors';
import { STATUSES } from '../constants';
import TEMPLATES from 'src/constants/mailTemplates.js';

// TODO: Refactor this into Saga
const sendTemplatedMail = data => {
  return requestApi.post('/application/send', data);
};

export const SendMailModal = props => {
  const { queueType, issues } = props;
  const [modalOpened, setModalOpened] = useState(false);
  const [invitationSending, setInvitationSending] = useState(false);
  const [invitationDate, setInvitationDate] = useState();
  const [invitationAddress, setInvitationAddress] = useState('');
  const [invitationCampus, setInvitationCampus] = useState();
  const [template, setTemplate] = useState(TEMPLATES[queueType][0].key);
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [status, setStatus] = useState();

  const requireCampus = TEMPLATES[queueType]
    .some(item => item.key === template
      && item.requireCampus)

  const freeform = TEMPLATES[queueType]
    .some(item => item.key === template
      && item.freeform)

  // Add an extra filter
  const filters = useSelector(getCandidateFilters);
  const campuses = useSelector(getCampuses);

  return (
    <Modal size="small"
      open={modalOpened}
      onOpen={() => setModalOpened(true)}
      trigger={(
        <LabeledButton
          text={`Veiksmai (${issues.length})`}
          icon="ellipsis horizontal" />
      )}>
      <Header content="Veiksmai" />
      {invitationSending && (
        <Modal.Content>
          <div className="ui loader massive inverted"></div>
        </Modal.Content>
      ) || (
        <Modal.Content>
          <p>Bus atlikti šie veiksmai su {issues.length} prašymų:</p>
          <div>
            Siųsti laišką:<br />
            <DropdownSelect button
              text="Šablonas"
              items={TEMPLATES[queueType]}
              value={template}
              mapToKey={x => x.key}
              mapToValue={x => x.key}
              mapToText={x => x.text}
              onChange={value => setTemplate(value)} />
          </div>
          {requireCampus && (
            <Fragment>
              <div>
                Pasirašymo data:<br />
                <DatePicker
                  value={invitationDate}
                  onChange={date => setInvitationDate(date)} />
              </div>
              <div>
                Pasirašymo adresas:<br />
                <Input value={invitationAddress}
                  onChange={(e, data) => setInvitationAddress(data.value)} />
              </div>
              <div>
                Pasirinkite miestelį:<br />
                <DropdownSelect button
                  text="Miestelis"
                  items={campuses}
                  value={invitationCampus}
                  mapToKey={x => x.id}
                  mapToValue={x => x}
                  mapToText={x => x && x.abbr}
                  onChange={value => setInvitationCampus(value)} />
              </div>
            </Fragment>
          )}
          {freeform && (
            <Fragment>
              <div>
                Tema:<br />
                <Input fluid value={subject}
                  onChange={(e, data) => setSubject(data.value)} />
              </div>
              <div>
                Tekstas:<br />
                <Form>
                  <TextArea value={body}
                    rows={10}
                    onChange={(e, data) => setBody(data.value)} />
                </Form>
              </div>
            </Fragment>
          )}
          <div>
            Pakeisti būseną į:<br />
            <DropdownSelect button
              items={[
                {
                  key: 'undefined',
                  value: undefined,
                  text: '— nekeisti —',
                },
                ...STATUSES.map(item => ({
                  key: item.id,
                  value: item.id,
                  text: `${item.name} (${item.id})`,
                })),
              ]}
              value={status}
              onChange={value => setStatus(value)} />
          </div>
        </Modal.Content>
      )}
      <Modal.Actions>
        <Button basic
          onClick={() => setModalOpened(false)}>
          <Icon name="remove" />
          Atšaukti
        </Button>
        <Button color="primary"
          onClick={async () => {
            console.log('inviting...');
            try {
              setInvitationSending(true);
              const res = await sendTemplatedMail({
                ids: issues.map(issue => issue.id),
                campusId: invitationCampus && invitationCampus.id,
                invitationDate,
                invitationAddress,
                template,
                subject,
                body,
                status,
              });
              console.log('OK', res);
            }
            finally {
              setInvitationSending(false);
            }
            setModalOpened(false);
          }}>
          <Icon name="checkmark" />
          Pradėti ({issues.length})
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
