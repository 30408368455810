import { addDays as dateAddDays, format as dateFormat, isEqual as dateIsEqual, parseISO } from 'date-fns';
import { max } from 'lodash';
import React from 'react';
import { Bar, Line, Pie } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { Loader } from '../components';
import { STUDY_CYCLES } from '../constants';
import { getFaculties } from '../faculty/selectors';
import { getInternationalIssues } from './selectors';

const COLOR_DEFAULT = 'rgba(0, 0, 0, 0.25)';
const COLOR_UNCONFIRMED = 'rgba(0, 0, 0, 0.15)';
const COLOR_PENDING = 'rgba(0, 0, 0, 0.25)';
const COLOR_INVITED = '#2185d0';
const COLOR_ACCEPTED = '#0165b0';
const COLOR_CANCELLED = '#fbbd08';
const COLOR_EXPIRED = '#fb9d08';
const COLOR_VOID = '#db2828';
const COLOR_COMPLETED = '#21ba45';


const countIssuesWithStatus = status => issues => issues
  .filter(issue => issue.status === status)
  .length;

export const InternationalStatisticsByDate = props => {
  const { issues } = props;
  if (!issues) {
    return <Loader />;
  }
  const labels = [];
  const dataAll = [];
  const dataMale = [];
  const dataFemale = [];
  const maxDate = max(issues.map(issue => parseISO(issue.createdAt)));
  const baseDate = maxDate || new Date();
  let date = dateAddDays(baseDate, -21);
  while (!dateIsEqual(date, baseDate)) {
    date = dateAddDays(date, 1);
    // Push label
    labels.push(dateFormat(date, 'MM-dd'))
    // Filter daily issues
    const filteredIssues = issues
      .filter(issue => dateFormat(parseISO(issue.createdAt), 'yyyy-MM-dd')
        === dateFormat(date, 'yyyy-MM-dd'));
    // Push values
    dataAll.push(filteredIssues.length);
    dataMale.push(filteredIssues
      .filter(issue => issue.sex === 'male')
      .length);
    dataFemale.push(filteredIssues
      .filter(issue => issue.sex === 'female')
      .length);
  }
  return (
    <Line
      width={100}
      height={35}
      data={{
        datasets: [
          {
            data: dataAll,
            label: 'Visi',
            borderColor: COLOR_DEFAULT,
            backgroundColor: COLOR_DEFAULT,
            fill: false,
          },
          {
            data: dataMale,
            // hidden: true,
            label: 'Vyrai',
            borderColor: 'rgba(0, 64, 192, 0.5)',
            backgroundColor: 'rgba(0, 64, 192, 0.5)',
            fill: false,
          },
          {
            data: dataFemale,
            // hidden: true,
            label: 'Moteris',
            borderColor: 'rgba(192, 0, 64, 0.5)',
            backgroundColor: 'rgba(192, 0, 64, 0.5)',
            fill: false,
          },
        ],
        labels,
      }}
      options={{
        scales: {
          yAxes: [{
            ticks: {
              suggestedMin: 0,
              suggestedMax: 5,
              precision: 0,
            },
          }],
        },
      }}
      {...props} />
  );
};

export const InternationalStatisticsByFacultySex = props => {
  const { issues } = props;
  const faculties = useSelector(getFaculties);
  if (!issues || !faculties) {
    return <Loader />;
  }
  const dataMale = faculties
    .map(faculty => issues
      .filter(issue => issue.faculty === faculty)
      .filter(issue => issue.sex === 'male')
      .length);
  const dataFemale = faculties
    .map(faculty => issues
      .filter(issue => issue.faculty === faculty)
      .filter(issue => issue.sex === 'female')
      .length);
  return (
    <Bar
      width={100}
      height={40}
      data={{
        datasets: [
          {
            data: dataMale,
            label: 'Vyrai',
            backgroundColor: 'rgba(0, 64, 192, 0.5)',
          },
          {
            data: dataFemale,
            label: 'Moteris',
            backgroundColor: 'rgba(192, 0, 64, 0.5)',
          },
        ],
        labels: faculties.map(faculty => faculty.abbr),
      }}
      options={{
        scales: {
          xAxes: [{
            stacked: true,
          }],
          yAxes: [{
            stacked: true,
            ticks: {
              suggestedMin: 0,
              suggestedMax: 5,
              precision: 0,
            },
          }],
        },
      }}
      {...props} />
  );
};

export const InternationalStatisticsByFacultyStatus = props => {
  const { issues } = props;
  const faculties = useSelector(getFaculties);
  if (!issues || !faculties) {
    return <Loader />;
  }
  const issueByFaculty = faculties
    .map(faculty => issues
      .filter(issue => issue.faculty === faculty));
  return (
    <Bar
      width={100}
      height={40}
      data={{
        datasets: [
          {
            data: issueByFaculty
              .map(countIssuesWithStatus('completed')),
            label: 'completed',
            backgroundColor: COLOR_COMPLETED,
          },
          {
            data: issueByFaculty
              .map(countIssuesWithStatus('accepted')),
            label: 'accepted',
            backgroundColor: COLOR_ACCEPTED,
          },
          {
            data: issueByFaculty
              .map(countIssuesWithStatus('invited')),
            label: 'invited',
            backgroundColor: COLOR_INVITED,
          },
          {
            data: issueByFaculty
              .map(countIssuesWithStatus('cancelled')),
            label: 'cancelled',
            backgroundColor: COLOR_CANCELLED,
          },
          {
            data: issueByFaculty
              .map(countIssuesWithStatus('expired')),
            label: 'expired',
            backgroundColor: COLOR_EXPIRED,
          },
          {
            data: issueByFaculty
              .map(countIssuesWithStatus('void')),
            label: 'void',
            backgroundColor: COLOR_VOID,
          },
          {
            data: issueByFaculty
              .map(countIssuesWithStatus('pending')),
            label: 'pending',
            backgroundColor: COLOR_PENDING,
          },
          {
            data: issueByFaculty
              .map(countIssuesWithStatus('unconfirmed')),
            label: 'unconfirmed',
            backgroundColor: COLOR_UNCONFIRMED,
          },
        ],
        labels: faculties.map(faculty => faculty.abbr),
      }}
      options={{
        scales: {
          xAxes: [{
            stacked: true,
          }],
          yAxes: [{
            stacked: true,
            ticks: {
              suggestedMin: 0,
              suggestedMax: 5,
              precision: 0,
            },
          }],
        },
      }}
      {...props} />
  );
};

export const InternationalStatisticsByStudyCycle = props => {
  const { issues } = props;
  if (!issues) {
    return <Loader />;
  }
  const STUDY_CYCLE_IDS = STUDY_CYCLES
    .filter(cycle => cycle.id)
    .map(cycle => cycle.id);
  const dataMale = STUDY_CYCLE_IDS
    .map(cycle => issues
      .filter(issue => issue.studyCycle === cycle)
      .filter(issue => issue.sex === 'male')
      .length);
  const dataFemale = STUDY_CYCLE_IDS
    .map(cycle => issues
      .filter(issue => issue.studyCycle === cycle)
      .filter(issue => issue.sex === 'female')
      .length);
  return (
    <Bar
      width={100}
      height={40}
      data={{
        datasets: [
          {
            data: dataMale,
            // hidden: true,
            label: 'Vyrai',
            backgroundColor: 'rgba(0, 64, 192, 0.5)',
          },
          {
            data: dataFemale,
            // hidden: true,
            label: 'Moteris',
            backgroundColor: 'rgba(192, 0, 64, 0.5)',
          },
        ],
        labels: STUDY_CYCLES
          .filter(item => item.id)
          .map(item => item.id),
      }}
      options={{
        scales: {
          xAxes: [{
            stacked: true,
          }],
          yAxes: [{
            stacked: true,
            ticks: {
              suggestedMin: 0,
              suggestedMax: 5,
              precision: 0,
            },
          }],
        },
      }}
      {...props} />
  );
};

export const InternationalStatisticsByStatus = props => {
  const { issues } = props;
  if (!issues) {
    return <Loader />;
  }
  return (
    <Pie
      width={100}
      height={40}
      data={{
        datasets: [
          {
            data: [
              countIssuesWithStatus('unconfirmed')(issues),
              countIssuesWithStatus('pending')(issues),
              countIssuesWithStatus('invited')(issues),
              countIssuesWithStatus('accepted')(issues),
              countIssuesWithStatus('cancelled')(issues),
              countIssuesWithStatus('expired')(issues),
              countIssuesWithStatus('void')(issues),
              countIssuesWithStatus('completed')(issues),
            ],
            label: 'data',
            backgroundColor: [
              COLOR_UNCONFIRMED,
              COLOR_PENDING,
              COLOR_INVITED,
              COLOR_ACCEPTED,
              COLOR_CANCELLED,
              COLOR_EXPIRED,
              COLOR_VOID,
              COLOR_COMPLETED,
            ],
          },
        ],
        labels: [
          'unconfirmed',
          'pending',
          'invited',
          'accepted',
          'cancelled',
          'expired',
          'void',
          'completed',
        ],
      }}
      {...props} />
  );
};
