import React from 'react';
import { computeBoxProps } from './Box';

export const Flex = props => {
  const { direction, wrap, alignItems, ...rest } = props;
  const computedProps = computeBoxProps({
    ...rest,
    style: {
      ...rest.style,
      display: 'flex',
      flexDirection: direction,
      flexWrap: wrap,
      alignItems,
    },
  });
  return <div {...computedProps} />
}

export const FlexItem = props => {
  const { grow, order, align, ...rest } = props;
  const computedProps = computeBoxProps({
    ...rest,
    style: {
      ...rest.style,
      flexGrow: grow,
      order,
      alignSelf: align,
    },
  });
  return <div {...computedProps} />
}

Flex.Item = FlexItem;
