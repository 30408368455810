import produce from 'immer';

export const candidateReducer = produce((state, action) => {
  const { type, payload } = action;

  if (type === 'candidate/SEARCH_TERM') {
    state.filters.searchText = payload;
  }

  if (type === 'candidate/FILTER_SET') {
    Object.assign(state.filters, payload);
  }

  if (type === 'candidate/OBJECT_FETCH_SUCCESS') {
    const { objects } = payload;
    state.objects = objects;
  }

  if (type === 'candidate/IMPORT') {
    state.list = [];
    state.faculties = [];
    state.csvData = undefined;
    state.csvHeader = undefined;
    state.objectUuid = payload.objectUuid;
  }

  if (
    type === 'candidate/IMPORT_START' ||
    type === 'freshmen/FETCH_START' ||
    type === 'international/FETCH_START'
  ) {
    state.loading = true;
  }

  if (
    type === 'candidate/IMPORT_SUCCESS' ||
    type === 'freshmen/FETCH_SUCCESS' ||
    type === 'international/FETCH_SUCCESS'
  ) {
    state.loading = false;
  }

  if (
    type === 'candidate/IMPORT_ERROR' ||
    type === 'freshmen/FETCH_ERROR' ||
    type === 'international/FETCH_ERROR'
  ) {
    state.loading = false;
  }

  if (type === 'candidate/LOAD') {
    state.list = payload.candidates;
    state.faculties = payload.faculties;
    state.csvData = payload.csvData;
    state.csvHeader = payload.csvHeader;
  }

  if (type === 'candidate/SAVE_SUCCESS') {
    const { object } = payload;
    state.objectUuid = object.uuid;
    state.objects.push(object);
  }

  if (type === 'candidate/DELETE_SUCCESS') {
    const { object } = payload;
    state.objectUuid = undefined;
    const index = state.objects
      .findIndex(x => x.uuid = object.uuid);
    state.objects.splice(index, 1);
  }
}, {
  list: [],
  faculties: [],
  filters: {
    searchText: '',
  },
  objects: [],
  objectUuid: undefined,
  loading: false,
});
