import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-tools/form';
import { Button, Form } from 'semantic-ui-react';
import { getAuthUser } from '../auth/selectors';
import { getCampuses } from '../campus/selectors';
import { Flex, Loader } from '../components';
import { ROOM_TYPES, STUDY_CYCLES, STATUSES } from '../constants';
import { getFaculties } from '../faculty/selectors';
import { getIssueById, getIssueForm } from './selectors';

const EMPTY_SELECT_OPTION = {
  key: 'undefined',
  value: null,
  text: '—',
};

export const IssueEditPage = props => {
  const { issueId } = props;
  const user = useSelector(getAuthUser);
  const issue = useSelector(getIssueById(issueId));
  const { error } = useSelector(getIssueForm);
  const campuses = useSelector(getCampuses);
  const faculties = useSelector(getFaculties);
  const dispatch = useDispatch();
  const [fields, setFields, fieldProps] = useForm({
    status: issue && issue.status || '',
    firstName: issue && issue.firstName || '',
    lastName: issue && issue.lastName || '',
    personalCode: issue && issue.personalCode || '',
    studentCode: issue && issue.studentCode || '',
    email: issue && issue.email || '',
    campus: issue && issue.campus && issue.campus.id || null,
    faculty: issue && issue.faculty && issue.faculty.id || null,
    country: issue && issue.country || '',
    city: issue && issue.city || '',
    studyCycle: issue && issue.studyCycle || null,
    roomType: issue && issue.roomType || null,
    accommodated: issue && issue.accommodated || false,
    disadvantaged: issue && issue.disadvantaged || false,
    handicapped: issue && issue.handicapped || false,
    notes: issue && issue.notes || '',
  });
  if (!issue) {
    return <Loader />;
  }
  if (user.role !== 'administrator') {
    return (
      <div className="ui message error">
        Jūs neturite prieigos prie šito funkcionalumo.
      </div>
    );
  }
  return (
    <Fragment>
      <Flex m={-2} mb={0}>
        <Flex.Item m={2}>
          <Button icon="left arrow"
            onClick={() => history.back()} />
        </Flex.Item>
        <Flex.Item m={2} grow={2}>
          <h1>Prašymas #{issue.seqId}</h1>
        </Flex.Item>
      </Flex>
      {error && (
        <div className="ui message error">{error.message}</div>
      )}
      <Form
        onSubmit={() => {
          dispatch({
            type: 'issue/UPDATE',
            payload: {
              issueId,
              fields,
            },
          });
        }}>
        <Form.Select label="Būsena"
          placeholder="—"
          options={[
            ...STATUSES.map(item => ({
              key: item.id,
              value: item.id,
              text: `${item.name} (${item.id})`,
            })),
          ]}
          {...fieldProps.suiInput('status')} />
        <Form.Input type="text" label="Vardas"
          {...fieldProps.suiInput('firstName')} />
        <Form.Input type="text" label="Pavardė"
          {...fieldProps.suiInput('lastName')} />
        <Form.Input type="text" label="Asmens kodas"
          {...fieldProps.suiInput('personalCode')} />
        <Form.Input type="text" label="Studento kodas"
          {...fieldProps.suiInput('studentCode')} />
        <Form.Input type="text" label="El. paštas"
          {...fieldProps.suiInput('email')} />
        <Form.Select label="Miestelis"
          placeholder="—"
          options={[
            EMPTY_SELECT_OPTION,
            ...campuses.map(campus => ({
              key: campus.id,
              value: campus.id,
              text: campus.abbr,
            })),
          ]}
          {...fieldProps.suiInput('campus')} />
        <Form.Select label="Fakultetas"
          placeholder="—"
          options={[
            EMPTY_SELECT_OPTION,
            ...faculties.map(faculty => ({
              key: faculty.id,
              value: faculty.id,
              text: faculty.name,
            })),
          ]}
          {...fieldProps.suiInput('faculty')} />
        <Form.Select label="Studijų pakopa"
          placeholder="—"
          options={[
            EMPTY_SELECT_OPTION,
            ...STUDY_CYCLES
              .filter(cycle => cycle.id)
              .map(cycle => ({
                key: cycle.id,
                value: cycle.id,
                text: cycle.name,
              })),
          ]}
          {...fieldProps.suiInput('studyCycle')} />
        <Form.Select label="Kambarys"
          placeholder="—"
          options={[
            EMPTY_SELECT_OPTION,
            ...ROOM_TYPES
              .map(roomType => ({
                key: roomType.id || 'undefined',
                value: roomType.id || 'any',
                text: roomType.name,
              })),
          ]}
          {...fieldProps.suiInput('roomType')} />
        <Form.TextArea rows={6} label="Pageidavimai"
          {...fieldProps.suiInput('notes')} />
        <Form.Checkbox label="Apgyvendintas"
          {...fieldProps.suiCheckbox('accommodated')} />
        <Form.Checkbox label="Socialiai remtinas"
          {...fieldProps.suiCheckbox('disadvantaged')} />
        <Form.Checkbox label="Neįgalusis"
          {...fieldProps.suiCheckbox('handicapped')} />
        <Form.Button content="Išsaugoti" />
      </Form>
    </Fragment>
  );
};
