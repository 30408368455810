import { flow } from 'functional';
import { filter, map } from 'lodash/fp';
import { searchTermMatcher } from '../issue/selectors';

export const getCandidateFilters = state => state.candidate.filters;

export const getCandidateFaculties = state => state.candidate.faculties;

export const getFilteredCandidates = state => {
  const filters = getCandidateFilters(state);
  return flow([
    state => state.candidate.list,
    filters.searchText && (
      filter(searchTermMatcher(filters.searchText))
    ),
    filters.facultyName && (
      filter(candidate => candidate.facultyName === filters.facultyName)
    ),
    filters.showRegisteredOnly && (
      filter(candidate => !!candidate.issue)
    ),
    filters.showPendingOnly && (
      filter(issue => issue.status === 'pending')
    ),
    filters.studyCycle && (
      filter(issue => issue.studyCycle === filters.studyCycle)
    ),
    filters.status && (
      filter(issue => issue.status === filters.status)
    ),
    filters.discrepancy && (
      filter(issue => issue.discrepancy)
    ),
    filters.disadvantaged && (
      filter(issue => issue.disadvantaged === filters.disadvantaged)
    ),
    filters.handicapped && (
      filter(issue => issue.handicapped === filters.handicapped)
    ),
    filters.matchLevel && (
      filter(issue => issue.matchLevel === filters.matchLevel)
    ),
    map(candidate => ({
      ...candidate,
      issue: state.issue.byId[candidate.issue],
    })),
  ])(state);
};

export const getCandidateObjects = state => state.candidate.objects;

export const getActiveCandidateObject = state =>
  state.candidate.objects.find(object =>
    object.uuid === state.candidate.objectUuid);

export const getCandidateCsvHeader = state => state.candidate.csvHeader;
export const getCandidateCsvData = state => state.candidate.csvData;
export const getCandidateLoading = state => state.candidate.loading;
