export const issueQueueCriteriaSet = (path, value) => ({
  type: 'issueQueue/CRITERIA_SET',
  payload: { path, value },
});

export const issueQueueLoadStatistics = withCriteria => ({
  type: 'issueQueue/LOAD_STATISTICS',
  payload: { withCriteria },
});

export const issueQueueInvite = (campusId, criteria) => ({
  type: 'issueQueue/INVITE',
  payload: { campusId, criteria },
});
