import { flow } from 'functional';
import produce from 'immer';
import { map, values } from 'lodash/fp';
import { routerActionTypes } from '../router';

const AFTER_TRANSITION = [
  routerActionTypes.TRANSITION_SUCCESS,
  routerActionTypes.TRANSITION_ERROR,
];

const computeDerivedFields = prevUser => {
  const user = { ...prevUser };
  // Rudimentary derivations
  user.fullName = [user.firstName, user.lastName]
    .filter(x => !!x)
    .join(' ')
    .trim()
    || null;
  user.displayName = user.fullName || user.email;
  // Return the result
  return user;
};

export const userReducer = produce((state, action) => {
  const { type, payload } = action;

  if (type === 'user/LOAD') {
    for (let user of payload.users) {
      // Compute derived state
      user = computeDerivedFields(user);
      // Push update
      state.byId[user.id] = user;
    }
    state.all = flow([
      state => values(state.byId),
      map(user => user.id),
    ])(state);
  }

  if (type === 'user/UPDATE_ERROR') {
    state.form = payload;
  }

  if (AFTER_TRANSITION.includes(type)) {
    state.form = {};
  }
}, {
  byId: {},
  all: [],
  form: {},
});
