export const internationalSearchTerm = text => ({
  type: 'international/SEARCH_TERM',
  payload: text,
});

export const internationalExport = () => ({
  type: 'international/EXPORT',
});

export const internationalFilterSet = filters => ({
  type: 'international/FILTER_SET',
  payload: filters,
});

export const internationalCloseIssues = issues => ({
  type: 'international/CLOSE_ISSUES',
  payload: {
    issueIds: issues.map(issue => issue.id),
  },
});

export const internationalFetchAll = () => ({
  type: 'international/FETCH',
});

export const internationalDeleteAll = () => ({
  type: 'international/DELETE_ALL',
});
