import { flow } from 'functional';
import { applyMiddleware, compose, createStore as createReduxStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { all } from 'redux-saga/effects';
import { combineReducers } from 'redux-tools';
import { supervisorSaga } from 'redux-tools/saga';
import { authReducer, authSaga } from './auth';
import { campusReducer, campusSaga } from './campus';
import { candidateReducer, candidateSaga } from './candidate';
import { facultyReducer, facultySaga } from './faculty';
import { freshmenReducer, freshmenSaga } from './freshmen';
import { internationalReducer, internationalSaga } from './international';
import { globalCampusFilterReducer } from './globalCampusFilter';
import { issueReducer, issueSaga } from './issue';
import { issueLogReducer, issueLogSaga } from './issueLog';
import { issueQueueReducer, issueQueueSaga } from './issueQueue';
import { routerMiddleware, routerReducer } from './router';
import { safeModeReducer } from './safeMode';
import { settingsReducer, settingsSaga } from './settings';
import { userReducer, userSaga } from './user';
import { progressReducer, progressSaga } from './progress';
import { labelReducer, labelSaga } from './label';

export const createReducer = () => flow([
  // State initializer
  (state = {}) => state,
  // Reducers from modules
  combineReducers({
    auth: authReducer,
    campus: campusReducer,
    candidate: candidateReducer,
    faculty: facultyReducer,
    freshmen: freshmenReducer,
    international: internationalReducer,
    globalCampusFilter: globalCampusFilterReducer,
    issue: issueReducer,
    issueLog: issueLogReducer,
    issueQueue: issueQueueReducer,
    label: labelReducer,
    progress: progressReducer,
    router: routerReducer,
    safeMode: safeModeReducer,
    settings: settingsReducer,
    user: userReducer,
  }),
]);

export const createSaga = () => {
  return function* rootSaga() {
    yield all([
      supervisorSaga(authSaga)(),
      supervisorSaga(campusSaga)(),
      supervisorSaga(candidateSaga)(),
      supervisorSaga(facultySaga)(),
      supervisorSaga(freshmenSaga)(),
      supervisorSaga(freshmenSaga)(),
      supervisorSaga(internationalSaga)(),
      supervisorSaga(issueQueueSaga)(),
      supervisorSaga(issueSaga)(),
      supervisorSaga(labelSaga)(),
      supervisorSaga(progressSaga)(),
      supervisorSaga(settingsSaga)(),
      supervisorSaga(userSaga)(),
    ]);
  };
}

export const createStore = () => {
  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [
    sagaMiddleware,
    routerMiddleware,
  ];
  const enhancers = [
    applyMiddleware(...middlewares),
  ];
  const reducer = createReducer();

  // If Redux DevTools Extension is installed - use it for composition.
  // Otherwise, use standard composition.
  const useDevtoolsCompose = process.env.NODE_ENV !== 'production'
    && typeof window === 'object'
    && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

  const composeEnhancers = useDevtoolsCompose
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      shouldHotReload: false,
      latency: 100,
      maxAge: 50,
    })
    : compose;

  // Create store
  const store = createReduxStore(reducer, composeEnhancers(...enhancers));

  // Run saga
  let sagaThread = sagaMiddleware.run(createSaga());

  // Patch the store to allow replacing sagas
  store.replaceSaga = async saga => {
    console.debug('Waiting for current saga to finish');
    sagaThread.cancel();
    await sagaThread.done;
    console.debug('Restarting saga');
    sagaThread = sagaMiddleware.run(saga);
  };

  return store;
};
