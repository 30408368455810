import produce from 'immer';
import { createIndexer } from 'redux-tools/gendb';

const { addMany, removeOne } = createIndexer({
  collections: {
    all: labels => labels,
  },
});

const computeDerivedFields = label => {
  const labelMut = { ...label };
  if (!labelMut.issues) {
    labelMut.issues = [];
  }
  return labelMut;
};

export const labelReducer = produce((state, action) => {
  const { type, payload } = action;

  if (type === 'label/LOAD') {
    const { labels } = payload;
    return addMany(state, labels.map(computeDerivedFields));
  }

  if (type === 'label/FETCH_ALL_START') {
    state.loading = true;
  }

  if (type === 'label/FETCH_ALL_SUCCESS') {
    state.loading = false;
    state.loaded = true;
  }

  if (type === 'label/FETCH_ALL_ERROR') {
    state.loading = false;
  }

  if (type === 'label/DELETE_SUCCESS') {
    const { labelId } = payload;
    return removeOne(state, labelId);
  }
}, {
  byId: {},
  all: [],
  loading: false,
  loaded: false,
});
