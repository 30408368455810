import { truncate } from 'lodash';
import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox, Divider, Dropdown, Input, Label } from 'semantic-ui-react';
import { Box, DropdownSelect, Flex, LabeledButton, Loader, PaginatedTable } from '../components';
import { STATUSES, STUDY_CYCLES } from '../constants';
import { Link } from '../router';
import { getSafeMode } from '../safeMode';
import { candidateDelete, candidateExport, candidateFilterSet, candidateImportFile, candidateImportObject, candidateSave, candidateSearchTerm } from './actions';
import { getActiveCandidateObject, getCandidateCsvData, getCandidateFaculties, getCandidateFilters, getCandidateLoading, getCandidateObjects, getFilteredCandidates } from './selectors';
import { SendInvitationModal } from './SendInvitationModal';
import { SendMailModal } from './SendMailModal';
import { formatStatus } from '../formatters';
import { internationalCloseIssues } from '../international/actions';
import { AssignLabelModal } from './AssignLabelModal';

export const InternationalCandidatesPage = props => {
  const [importDropdownOpen, setImportDropdownOpen] = useState(false);
  const objects = useSelector(getCandidateObjects);
  const object = useSelector(getActiveCandidateObject);
  const csvData = useSelector(getCandidateCsvData);
  const candidates = useSelector(getFilteredCandidates);
  const issues = candidates
    .map(candidate => candidate.issue)
    .filter(issue => !!issue);
  const discrepancies = issues.filter(issue => issue.discrepancy);
  const faculties = useSelector(getCandidateFaculties);
  const filters = useSelector(getCandidateFilters);
  const loading = useSelector(getCandidateLoading);
  const safeMode = useSelector(getSafeMode);
  const dispatch = useDispatch();
  if (loading) {
    return <Loader />;
  }
  return (
    <Fragment>
      <Flex m={-1} mb={1}>
        <Flex.Item m={1} grow={1}>
          <h1>Sąrašai ir kvietimas</h1>
        </Flex.Item>
        <Flex.Item m={1}>
          <Input icon="search" placeholder="Ieškoti..."
            value={filters.searchText}
            onChange={(e, data) => {
              dispatch(candidateSearchTerm(data.value));
            }} />
        </Flex.Item>
      </Flex>
      <Flex mb={1} alignItems="center">
        <Flex.Item>
          <Dropdown floating labeled button
            className="icon"
            text="Open..." icon="folder"
            open={importDropdownOpen}
            onOpen={() => setImportDropdownOpen(true)}
            onClose={() => setImportDropdownOpen(false)}>
            <Dropdown.Menu>
              <Dropdown.Item as="label">
                Upload
                <input type="file"
                  style={{ display: 'none' }}
                  onChange={e => {
                    const file = e.target.files[0];
                    setImportDropdownOpen(false);
                    dispatch(candidateImportFile(file));
                  }} />
              </Dropdown.Item>
              <Dropdown.Divider />
              {objects.map(obj => (
                <Dropdown.Item key={obj.uuid}
                  content={obj.name || obj.uuid}
                  onClick={() => {
                    dispatch(candidateImportObject(obj.uuid));
                  }} />
              ))}
              {objects.length === 0 && (
                <Dropdown.Item disabled content="Nėra išsaugotų failų" />
              )}
            </Dropdown.Menu>
          </Dropdown>
          <Button
            labelPosition="left"
            icon="save"
            content="Save"
            disabled={!csvData}
            onClick={() => {
              dispatch(candidateSave());
            }} />
          <Button
            labelPosition="left"
            icon="trash"
            content="Delete"
            disabled={!object}
            onClick={() => {
              dispatch(candidateDelete(object));
            }} />
        </Flex.Item>
        <Flex.Item grow={1} />
        <Flex.Item ml={2} className="text-bold">
          {object && object.name}
        </Flex.Item>
      </Flex>
      <Divider />
      {(!object && !csvData) && (
        <Box mt={2}>
          Atidarykite failą.
        </Box>
      ) || (
          <Fragment>
            <Box>
              <Box my={2}>
                <DropdownSelect inline
                  text="Fakultetas"
                  items={[
                    {
                      key: 'undefined',
                      value: undefined,
                      text: 'Visi fakultetai',
                    },
                    ...faculties.map(facultyName => ({
                      key: facultyName,
                      value: facultyName,
                      text: facultyName,
                      description: candidates
                        .filter(candidate => candidate.facultyName === facultyName)
                        .length,
                    })),
                  ]}
                  value={filters.facultyName}
                  onChange={facultyName => {
                    dispatch(candidateFilterSet({ facultyName }));
                  }} />
                <DropdownSelect inline
                  text="Studijų pakopa"
                  items={STUDY_CYCLES.map(item => ({
                    key: String(item.id),
                    value: item.id,
                    text: item.name,
                    description: candidates
                      .filter(candidate => candidate.studyCycle === item.id)
                      .length,
                  }))}
                  value={filters.studyCycle}
                  onChange={studyCycle => dispatch(
                    candidateFilterSet({ studyCycle }))} />
                <DropdownSelect inline
                  text="Būsena"
                  items={[
                    {
                      key: 'undefined',
                      value: undefined,
                      text: 'Visos būsenos',
                    },
                    ...STATUSES.map(item => ({
                      key: String(item.id),
                      value: item.id,
                      text: item.name,
                      description: candidates
                        .filter(candidate => candidate.status === item.id)
                        .length,
                    })),
                  ]}
                  value={filters.status}
                  onChange={status => dispatch(
                    candidateFilterSet({ status }))} />
                <DropdownSelect inline
                  text="Sutapimo lygis"
                  items={[
                    {
                      key: 'undefined',
                      value: undefined,
                      text: 'Visi sutapimo lygiai',
                    },
                    {
                      key: 'email',
                      value: 'email',
                      text: 'Pagal el. pašto adresą',
                    },
                    {
                      key: 'name',
                      value: 'name',
                      text: 'Pagal vardą ir pavardę',
                    },
                    {
                      key: 'none',
                      value: 'none',
                      text: 'Nera sutapimo',
                    },
                  ]}
                  value={filters.matchLevel}
                  onChange={matchLevel => {
                    dispatch(candidateFilterSet({ matchLevel }));
                  }} />
              </Box>
              {discrepancies.length > 0 && (
                <Box my={1}>
                  <Checkbox
                    label={`Rodyti registracijas su neatitikimais (${discrepancies.length})`}
                    checked={filters.discrepancy}
                    onChange={(e, data) => {
                      dispatch(candidateFilterSet({
                        discrepancy: data.checked,
                      }));
                    }} />
                </Box>
              )}
              <Box my={1}>
                <Checkbox label="Rodyti tik registruotus"
                  checked={filters.showRegisteredOnly}
                  onChange={(e, data) => {
                    dispatch(candidateFilterSet({
                      showRegisteredOnly: data.checked,
                    }))
                  }} />
              </Box>
              <Box my={1}>
                <Checkbox label="Rodyti tik nepakviestus"
                  checked={filters.showPendingOnly}
                  onChange={(e, data) => {
                    dispatch(candidateFilterSet({
                      showPendingOnly: data.checked,
                    }));
                  }} />
              </Box>
            </Box>
            <PaginatedTable
              collection={candidates}
              itemsPerPage={20}
              renderHeader={() => (
                <tr>
                  <th>Vardas, pavardė</th>
                  <th>Fakultetas</th>
                  <th>Programa</th>
                  <th className="collapsing">P</th>
                  <th className="collapsing">Būsena</th>
                  <th className="collapsing">Level</th>
                </tr>
              )}
              renderItem={item => (
                <tr key={item.id}>
                  <td>
                    {item.issue && (
                      <Link
                        routeName="issueShow"
                        routeParams={{ issueId: item.issue.id }}>
                        {item.firstName + ' ' + item.lastName}
                      </Link>
                    ) || (
                        `${item.firstName} ${item.lastName}`
                      )}
                  </td>
                  <td>{truncate(item.facultyName, { length: 26 })}</td>
                  <td>{truncate(item.programme, { length: 26 })}</td>
                  <td className="collapsing">
                    {item.studyCycle || item.issue && item.issue.studyCycle}
                  </td>
                  <td className="collapsing">
                    {item.issue && formatStatus(item.issue.status)}
                  </td>
                  <td className="collapsing">
                    <Label horizontal
                      size="mini"
                      color={(
                        item.matchLevel === 'email' && 'yellow' ||
                        item.matchLevel === 'name' && 'orange' ||
                        item.matchLevel === 'none' && 'red'
                      )}
                      content={item.matchLevel} />
                  </td>
                </tr>
              )} />
            <LabeledButton
              text={`Parsisiusti CSV (${candidates.length})`}
              icon="download"
              onClick={() => {
                dispatch(candidateExport());
              }} />
            {!safeMode && (
              <LabeledButton
                icon="dropbox"
                color="yellow"
                text={`Atmesti (${issues.length})`}
                onClick={() => dispatch(internationalCloseIssues(issues))} />
            )}
            <AssignLabelModal issues={issues} />
            <SendMailModal queueType={'international'} issues={issues} />
            <SendInvitationModal queueType={'international'} issues={issues} />
          </Fragment>
        )}

    </Fragment>
  );
};
