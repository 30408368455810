import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Checkbox, Statistic } from 'semantic-ui-react';
import { get } from 'lodash';
import { Box, LabeledList, Loader, Flex } from '../components';
import { ROOM_TYPES, SEXES } from '../constants';
import { getFaculties } from '../faculty/selectors';
import { GlobalCampusFilter, getSelectedCampusId } from '../globalCampusFilter';
import { issueQueueCriteriaSet, issueQueueInvite } from './actions';
import { getIssueQueueCriteria, getIssueQueueStatistics } from './selectors';

const CriteriaCheckbox = props => {
  const { criteria, criteriaName, item, count = 0 } = props;
  const itemId = item.id;
  const itemName = item.name;
  const checked = !!get(criteria, [criteriaName, itemId]);
  const dimmed = count === 0 && !checked;
  const dispatch = useDispatch();
  return (
    <Box mb={1} opacity={dimmed ? 0.25 : 1}>
      <Checkbox
        label={`${itemName} (${count})`}
        checked={checked}
        onChange={(_, data) => dispatch(
          issueQueueCriteriaSet(
            [criteriaName, itemId],
            data.checked))} />
    </Box>
  );
};

export const IssueQueueInvitePage = props => {
  const campusId = useSelector(getSelectedCampusId);
  const criteria = useSelector(getIssueQueueCriteria);
  const statistics = useSelector(getIssueQueueStatistics);
  const faculties = useSelector(getFaculties);
  const dispatch = useDispatch();
  if (!statistics) {
    return <Loader />;
  }
  return (
    <Fragment>
      <Flex alignItems="center" m={-2}>
        <Flex.Item m={2}>
          <Button icon="left arrow"
            onClick={() => history.back()} />
        </Flex.Item>
        <Flex.Item m={2}>
          <h1>Kviesti</h1>
        </Flex.Item>
        <Flex.Item m={2}>
          <GlobalCampusFilter
            countingFn={campus => get(statistics, ['campuses', campus.id])} />
        </Flex.Item>
      </Flex>
      <Flex m={-2} mt={2}>
        <Flex.Item m={2}>
          <h3>Lytis</h3>
          {SEXES.map(item => (
            <CriteriaCheckbox
              key={String(item.id)}
              criteria={criteria}
              criteriaName="sex"
              count={get(statistics, ['sexes', item.id])}
              item={item} />
          ))}
        </Flex.Item>
        <Flex.Item m={2}>
          <h3>Kambario tipas</h3>
          {ROOM_TYPES.map(item => (
            <CriteriaCheckbox
              key={String(item.id)}
              criteria={criteria}
              criteriaName="roomType"
              count={get(statistics, ['roomTypes', item.id])}
              item={item} />
          ))}
        </Flex.Item>
        <Flex.Item m={2}>
          <h3>Apgyvendintas</h3>
          <CriteriaCheckbox
            key="accommodated"
            criteria={criteria}
            criteriaName="accommodated"
            count={get(statistics, ['accommodated', true])}
            item={{
              id: true,
              name: 'Taip',
            }} 
          />
        </Flex.Item>
        <Flex.Item m={2}>
          <h3>Fakultetas</h3>
          {faculties.map(item => (
            <CriteriaCheckbox
              key={String(item.id)}
              criteria={criteria}
              criteriaName="faculty"
              count={get(statistics, ['faculties', item.id])}
              item={item} />
          ))}
        </Flex.Item>
      </Flex>
      <LabeledList>
        <LabeledList.Item
          label="Atitinka pasirinktus kriterijus:">
          <Statistic size="small"
            value={statistics.size || 0} />
        </LabeledList.Item>
      </LabeledList>
      <Button primary
        content="Pakviesti"
        onClick={() => dispatch(issueQueueInvite(campusId, criteria))} />
      <Button
        content="Atšaukti"
        onClick={() => history.back()} />
    </Fragment>
  );
};
