import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Button, Table } from 'semantic-ui-react';
import { Flex } from '../components';
import { Link } from '../router';
import { getAdministrativeUsers } from './selectors';

export const UserIndexPage = props => {
  const users = useSelector(getAdministrativeUsers);
  return (
    <Fragment>
      <Flex m={-2}>
        <Flex.Item m={2} grow={2}>
          <h1>Vartotojai</h1>
        </Flex.Item>
        <Flex.Item m={2}>
          <Button as={Link}
            routeName="userCreate"
            color="green"
            icon="plus"
            labelPosition="left"
            content="Pridėti vartotoją" />
        </Flex.Item>
      </Flex>
      <Table compact size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell content="Vardas, pavardė" />
            <Table.HeaderCell content="El. paštas" />
            <Table.HeaderCell content="Rolė" />
            <Table.HeaderCell content="Miestelis" />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {users.map(user => (
            <Table.Row key={user.id}>
              <Table.Cell>
                <Link
                  routeName="userShow"
                  routeParams={{
                    userId: user.id,
                  }}>
                  {user.displayName}
                </Link>
              </Table.Cell>
              <Table.Cell>
                {user.email}
              </Table.Cell>
              <Table.Cell>
                {user.role}
              </Table.Cell>
              <Table.Cell>
                {user.campuses.map(x => x.abbr).join(', ') || '—'}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Fragment>
  );
};
