import React, { Fragment } from 'react';
import { Box, Flex } from '../components';
import { IssueQueueStatistics } from './IssueQueueStatistics';

export const IssueQueueStatisticsPage = props => {
  return (
    <Fragment>
      <Box>
        <Flex>
          <Flex.Item>
            <h1>Eilės statistika</h1>
          </Flex.Item>
        </Flex>
        <IssueQueueStatistics />
      </Box>
    </Fragment>
  );
};
