import { flow } from 'functional';
import { map, filter } from 'lodash/fp';

export const getLabels = state => flow([
  state => state.label.all,
  map(id => state.label.byId[id]),
])(state);

export const getLabelById = id => state => state.label.byId[id];

export const getLabelsByIssueId = id => state => flow([
  state => state.label.all,
  map(id => state.label.byId[id]),
  filter(label => label.issues && label.issues.includes(id)),
])(state);

export const getLabelLoaded = state => state.label.loaded;
export const getLabelLoading = state => state.label.loading;
