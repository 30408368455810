import { flow } from 'functional';
import { filter, map } from 'lodash/fp';
import { getCampusById } from '../campus/selectors';
import { getFacultyById } from '../faculty/selectors';

export const populateUser = (state, user) => {
  return user && {
    ...user,
    faculties: map(id => getFacultyById(id)(state))(user.scope.faculty),
    campuses: map(id => getCampusById(id)(state))(user.scope.campus),
    queues: map(queue => queue)(user.scope.queues),
  };
};

export const getUsers = state => flow([
  state => state.user.all,
  map(id => state.user.byId[id]),
  map(user => populateUser(state, user)),
])(state);

export const getAdministrativeUsers = state => flow([
  state => state.user.all,
  map(id => state.user.byId[id]),
  filter(user => user.role === 'administrator'
    || user.role === 'superintendent'),
  map(user => populateUser(state, user)),
])(state);

export const getUserById = id => state =>
  populateUser(state, state.user.byId[id]);

export const getUserForm = state => state.user.form;
