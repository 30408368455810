import React from 'react';

const rem = value => value ? value + 'rem' : undefined;

const MARGIN_PER_INTEGER = 0.5;

// Nullish coalesce function
const firstDefined = (...args) => {
  for (let arg of args) {
    if (arg !== undefined && arg !== null) {
      return arg;
    }
  }
};

export const computeBoxProps = props => {
  const {
    m = 0, mx, my, mt, mb, ml, mr,
    opacity,
    width,
    height,
    inline,
    ...rest
  } = props;
  return {
    ...rest,
    style: {
      display: inline ? 'inline-block' : 'block',
      ...rest.style,
      marginTop:    rem(MARGIN_PER_INTEGER * firstDefined(mt, my, m)),
      marginBottom: rem(MARGIN_PER_INTEGER * firstDefined(mb, my, m)),
      marginLeft:   rem(MARGIN_PER_INTEGER * firstDefined(ml, mx, m)),
      marginRight:  rem(MARGIN_PER_INTEGER * firstDefined(mr, mx, m)),
      opacity,
      width,
      height,
    },
  };
};

export const Box = props => <div {...computeBoxProps(props)} />;
