import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Header, Icon, Modal } from 'semantic-ui-react';
import { Box, DropdownSelect, LabeledButton } from '../components';
import { Label } from '../label';
import { labelAddIssues, labelRemoveIssues } from '../label/actions';
import { getLabels } from '../label/selectors';

export const AssignLabelModal = props => {
  const { issues } = props;

  const [modalOpened, setModalOpened] = useState(false);
  const [label, setLabel] = useState();

  // Add an extra filter
  const labels = useSelector(getLabels);
  const dispatch = useDispatch();

  const onSubmit = (options = {}) => () => {
    const { add, remove } = options;
    if (!label) {
      return;
    }
    const issueIds = issues.map(issue => issue.id);
    if (add) {
      dispatch(labelAddIssues(label.id, issueIds));
    }
    else if (remove) {
      dispatch(labelRemoveIssues(label.id, issueIds));
    }
    setModalOpened(false);
  };

  return (
    <Modal size="small"
      open={modalOpened}
      onOpen={() => setModalOpened(true)}
      trigger={(
        <LabeledButton
          text={`Žymos (${issues.length})`}
          icon="sticky note outline" />
      )}>
      <Header icon="sticky note outline"
        content="Žymų valdymas" />
      <Modal.Content>
        <Box mx={2}>
          <DropdownSelect
            text="Pasirinkite žymą"
            items={labels.map(label => ({
              key: label.id,
              value: label,
              text: (
                <Label
                  value={label.name}
                  color={label.color} />
              ),
            }))}
            value={label}
            onChange={value => setLabel(value)} />
        </Box>
      </Modal.Content>
      <Modal.Actions>
        <Button basic
          onClick={() => setModalOpened(false)}>
          <Icon name="remove" />
          Atšaukti
        </Button>
        <Button color="red"
          onClick={onSubmit({ remove: true })}>
          <Icon name="remove" />
          Nuimti
          ({issues.length})
        </Button>
        <Button color="green"
          onClick={onSubmit({ add: true })}>
          <Icon name="plus" />
          Pridėti
          ({issues.length})
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
