import React from 'react';
import { Table } from 'semantic-ui-react';

export const LabeledList = props => {
  const { children } = props;
  return (
    <Table basic="very" compact>
      <Table.Body>
        {children}
      </Table.Body>
    </Table>
  );
};

export const LabeledListItem = props => {
  const { label, optional, content, warning, children } = props;
  // Hide this list item if it has no content and is marked as optional
  if (optional && !(content || children)) {
    return null;
  }
  return (
    <Table.Row warning={!!warning}>
      <Table.Cell className="text-bold"
        collapsing
        content={label} />
      <Table.Cell>
        {warning && (
          <i className="icon yellow warning sign"
            title={warning} />
        )}
        {content || children || '—'}
      </Table.Cell>
    </Table.Row>
  );
};

LabeledList.Item = LabeledListItem;
