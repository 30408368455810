import { get } from 'lodash';
import { getIssueById } from '../issue/selectors';
import { getUserById } from '../user/selectors';

export const WINDOW_TITLE = 'Dashboard';

const authChecker = (router, deps) => async (toState, fromState) => {
  const { store } = deps;
  const state = store.getState();
  const token = get(state, ['auth', 'token']);
  if (!token) {
    console.warn("No token in state, can not navigate to route");
    throw {
      redirect: {
        name: 'login',
      },
    };
  }
  return true;
};

/**
 * Waits for a given selector to return a non nullish value
 * within a small timeframe (timeout is about 2.5 seconds).
 */
const waitForSelector = async (store, selector) => {
  for (let i = 0; i < 5; i++) {
    const item = selector(store.getState());
    if (item !== undefined && item !== null) {
      return;
    }
    await new Promise(resolve => setTimeout(resolve, 500));
  }
  throw {};
};

export const ROUTES = [
  {
    name: 'issueQueueDashboard',
    path: '/',
    canActivate: authChecker,
  },
  {
    title: 'Log In',
    name: 'login',
    path: '/login',
  },
  {
    title: 'Issues',
    name: 'issueIndex',
    path: '/issue',
  },
  {
    title: 'Show Issue',
    name: 'issueShow',
    path: '/issue/:issueId',
  },
  {
    title: 'Edit Issue',
    name: 'issueEdit',
    path: '/issue/:issueId/edit',
    canActivate: (router, deps) => async (toState, fromState) => {
      const { store } = deps;
      const { issueId } = toState.params;
      await waitForSelector(store, getIssueById(issueId));
    },
  },
  {
    title: 'Log',
    name: 'issueLog',
    path: '/issue/log',
  },
  {
    title: 'Invite',
    name: 'issueQueueInvite',
    path: '/issueQueue/invite',
  },
  {
    title: 'Logs',
    name: 'issueQueueLog',
    path: '/issueQueue/log',
  },
  {
    title: 'Statistics',
    name: 'issueQueueStatistics',
    path: '/issueQueue/statistics',
  },
  {
    title: 'Freshmen',
    name: 'freshmen',
    path: '/freshmen',
  },
  {
    title: 'Candidates',
    name: 'freshmenCandidates',
    path: '/freshmen/candidates',
  },
  {
    title: 'Statistics',
    name: 'freshmenStatistics',
    path: '/freshmen/statistics',
  },
  {
    title: 'Log',
    name: 'freshmenLog',
    path: '/freshmen/log',
  },
  {
    title: 'International',
    name: 'international',
    path: '/international',
  },
  {
    title: 'Statistics',
    name: 'internationalStatistics',
    path: '/international/statistics',
  },
  {
    title: 'Log',
    name: 'internationalLog',
    path: '/international/log',
  },
  {
    title: 'Settings',
    name: 'settings',
    path: '/settings',
  },
  {
    title: 'Users',
    name: 'userIndex',
    path: '/user',
  },
  {
    title: 'Show user',
    name: 'userShow',
    path: '/user/:userId',
    canActivate: (router, deps) => async (toState, fromState) => {
      const { store } = deps;
      const { userId } = toState.params;
      await waitForSelector(store, getUserById(userId));
    },
  },
  {
    title: 'Create user',
    name: 'userCreate',
    path: '/user/create',
  },
  {
    title: 'Campuses',
    name: 'campusIndex',
    path: '/campus',
  },
  {
    title: 'Show campus',
    name: 'campusShow',
    path: '/campus/:campusId',
  },
  {
    title: 'Faculties',
    name: 'facultyIndex',
    path: '/faculty',
  },
  {
    title: 'Show faculty',
    name: 'facultyShow',
    path: '/faculty/:facultyId',
  },
  {
    title: 'Labels',
    name: 'labelIndex',
    path: '/label',
  },
];

export const ROUTER_OPTIONS = {
  // defaultRoute: 'index',
  queryParams: {
    arrayFormat: 'brackets',
    booleanFormat: 'string',
    nullFormat: 'hidden',
  },
};
