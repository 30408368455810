import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Header, Icon, Input, Modal } from 'semantic-ui-react';
import { requestApi } from '../api';
import { getCampuses } from '../campus/selectors';
import { DatePicker, DropdownSelect, LabeledButton } from '../components';
import { getCandidateFilters } from './selectors';

// TODO: Refactor this into Saga
const sendInvitations = (queueType, data) => {
  return requestApi.post(`/application/invite/${queueType}`, data);
};

export const SendInvitationModal = props => {
  const { queueType, issues } = props;

  const [modalOpened, setModalOpened] = useState(false);
  const [invitationSending, setInvitationSending] = useState(false);
  const [invitationDate, setInvitationDate] = useState();
  const [invitationAddress, setInvitationAddress] = useState('');
  const [invitationCampus, setInvitationCampus] = useState();

  // Add an extra filter
  const filters = useSelector(getCandidateFilters);
  const campuses = useSelector(getCampuses);

  return (
    <Modal basic size="small"
      open={modalOpened}
      onOpen={() => setModalOpened(true)}
      trigger={(
        <LabeledButton
          text={`Kviesti (${issues.length})`}
          color="blue"
          icon="envelope open outline" />
      )}>
      <Header icon="envelope open outline"
        content="Siųsti kvietimus" />
      {invitationSending && (
        <Modal.Content>
          <div className="ui loader massive inverted"></div>
        </Modal.Content>
      ) || (
          <Modal.Content>
            <p>Jūs kviečiate {issues.length} studentų
            pagal šiuos kriterijus:</p>
            <ul>
              {filters.facultyName && (
                <li>Fakultetas: <strong>{filters.facultyName}</strong></li>
              )}
              <li>Registruotas sistemoje kaip pirmakursis</li>
              {filters.showPendingOnly && (
                <li>Registruotas sistemoje, bet nepakviestas</li>
              )}
            </ul>
            <div>Pasirašymo data:<br />
              <DatePicker
                value={invitationDate}
                onChange={date => setInvitationDate(date)} />
            </div>
            <div>Pasirašymo adresas:<br />
              <Input value={invitationAddress}
                onChange={(e, data) => setInvitationAddress(data.value)} />
            </div>
            <div>Pasirinkite miestelį:<br />
              <DropdownSelect button
                text="Miestelis"
                items={campuses}
                value={invitationCampus}
                mapToKey={x => x.id}
                mapToValue={x => x}
                mapToText={x => x && x.abbr}
                onChange={x => setInvitationCampus(x)} />
            </div>
          </Modal.Content>
        )}
      <Modal.Actions>
        <Button basic color="red" inverted
          onClick={() => setModalOpened(false)}>
          <Icon name="remove" />
          Atšaukti
        </Button>
        <Button color="teal" inverted
          onClick={async () => {
            console.log('inviting...');
            try {
              setInvitationSending(true);
              const res = await sendInvitations(
                queueType,
                {
                  ids: issues.map(issue => issue.id),
                  campusId: invitationCampus && invitationCampus.id,
                  invitationDate,
                  invitationAddress,
                }
              );
              console.log('OK', res);
            }
            finally {
              setInvitationSending(false);
            }
            setModalOpened(false);
          }}>
          <Icon name="paper plane outline" />
          Siųsti kvietimus ({issues.length})
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
