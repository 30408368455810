import { flow } from 'functional';
import { filter, map, toPairs } from 'lodash/fp';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { actionEmittingSaga } from 'redux-tools/saga';
import { requestApi } from '../api';
import { getAuthState } from '../auth/selectors';
import { getSelectedCampusId } from '../globalCampusFilter';
import { issueLoad } from '../issue/actions';
import { isTransitionTo, routerActions } from '../router';
import { issueQueueLoadStatistics } from './actions';
import { getIssueQueueCriteria } from './selectors';

export const issueQueueSaga = function* () {
  yield all([
    takeLatest('auth/READY', function* () {
      const auth = yield select(getAuthState);
      if (!auth.token) {
        return;
      }
      yield put(issueQueueLoadStatistics(false));
    }),
    takeLatest('issueQueue/LOAD_STATISTICS', issueQueueLoadStatisticsSaga),
    takeLatest([
      'issueQueue/CRITERIA_SET',
      'globalCampusFilter/UPDATE',
      isTransitionTo('issueQueueInvite'),
    ], function* () {
      yield put(issueQueueLoadStatistics(true));
    }),
    takeLatest('issueQueue/INVITE', issueQueueInviteSaga),
    takeLatest('issueQueue/INVITE_SUCCESS', function* (action) {
      const { issueId } = action.payload;
      yield put(routerActions.navigateTo('issueShow', { issueId }));
    }),
  ]);
};

const buildPostData = (campusId, criteria) => {
  const postData = {};
  if (campusId) {
    postData['campus'] = campusId;
  }
  if (criteria) {
    for (let name of ['sex', 'roomType', 'accommodated', 'faculty']) {
      const criteriaKeys = flow([
        // Convert object into key value pairs
        toPairs,
        // Filter empty values
        filter(([_, value]) => !!value),
        // Build a final array consisting of only keys
        map(([key, _]) => key),
      ])(criteria[name]);
      if (criteriaKeys.length > 0) {
        postData[name] = criteriaKeys;
      }
    }
  }
  return postData;
};

const issueQueueLoadStatisticsSaga = actionEmittingSaga(function* (action) {
  const { withCriteria } = action.payload;
  const campusId = yield select(getSelectedCampusId);
  const criteria = withCriteria && (
    yield select(getIssueQueueCriteria));
  const postData = buildPostData(campusId, criteria);
  const res = yield call(requestApi.post,
    '/application/queue/statistics', postData);
  return res.data;
});

const issueQueueInviteSaga = actionEmittingSaga(function* (action) {
  const { campusId, criteria } = action.payload;
  const postData = buildPostData(campusId, criteria);
  const res = yield call(requestApi.post,
    '/application/invite', postData);
  const issue = res.data;
  yield put(issueLoad([issue]));
  return {
    issueId: issue.id,
    issue,
  };
});
