import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Flex, LabeledList, Loader } from '../components';
import { InternationalStatisticsByFacultyStatus, InternationalStatisticsByFacultySex, InternationalStatisticsByStudyCycle, InternationalStatisticsByDate, InternationalStatisticsByStatus } from './InternationalStatistics';
import { Grid, Statistic } from 'semantic-ui-react';
import { getInternationalIssues, getInternationalLoading } from './selectors';

export const InternationalStatisticsPage = props => {
  const issues = useSelector(getInternationalIssues);
  const loading = useSelector(getInternationalLoading);
  if (loading) {
    return <Loader />;
  }
  return (
    <Box>
      <Flex m={-1} mb={1}>
        <Flex.Item m={1} grow={1}>
          <h1>Registruotų tarptautinių statistika</h1>
        </Flex.Item>
      </Flex>
      <LabeledList>
        <LabeledList.Item label="Registracijų skaičius">
          <Statistic horizontal
            value={issues.length} />
        </LabeledList.Item>
      </LabeledList>
      <InternationalStatisticsByDate
        issues={issues} />
      <Box>
        <h2>Pagal fakultetą</h2>
        <InternationalStatisticsByFacultySex
          issues={issues}
          height={30} />
        <InternationalStatisticsByFacultyStatus
          issues={issues}
          height={30} />
      </Box>
      <Grid>
        <Grid.Column width={10}>
          <h2>Pagal statusą</h2>
          <InternationalStatisticsByStatus
            issues={issues}
            height={60} />
        </Grid.Column>
        <Grid.Column width={6}>
          <h2>Pagal studijų pakopą</h2>
          <InternationalStatisticsByStudyCycle
            issues={issues}
            height={70} />
        </Grid.Column>
      </Grid>
    </Box>
  );
};
