import React from 'react';
import { Bar } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { Loader } from '../components';
import { getFaculties } from '../faculty/selectors';
import { getIssueQueueStatistics } from './selectors';

export const IssueQueueStatistics = props => {
  const statistics = useSelector(getIssueQueueStatistics);
  const faculties = useSelector(getFaculties);
  if (!statistics || !faculties) {
    return <Loader />;
  }
  return (
    <Bar
      width={100}
      height={25}
      data={{
        datasets: [
          {
            data: faculties
              .map(faculty => statistics.faculties[faculty.id]),
            label: 'Studentų skaičius pagal fakultetą',
            backgroundColor: 'rgba(0, 0, 0, 0.25)',
          },
        ],
        labels: faculties.map(faculty => faculty.abbr),
      }}
      options={{
        animation: false,
        scales: {
          yAxes: [{
            ticks: {
              suggestedMin: 0,
              suggestedMax: 5,
              precision: 0,
            },
          }]
        }
      }} />
  );
};
