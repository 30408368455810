export const readFileAsText = file => {
  const reader = new FileReader();
  reader.readAsText(file, 'UTF-8');
  return new Promise((resolve, reject) => {
    reader.addEventListener('load', e => resolve(e.target.result));
    reader.addEventListener('load', e => reject(e));
  });
};

export const serveTextAsFile = (filename, text) => {
  var element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,'
    + encodeURIComponent(text));
  element.setAttribute('download', filename);
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};
