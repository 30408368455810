import produce from 'immer';

const initialState = {};

export const settingsReducer = produce((state, action) => {
  const { type, payload } = action;

  if (type === 'settings/FETCH_SUCCESS') {
    Object.assign(state, payload);
  }

  if (type === 'settings/UPDATE_SUCCESS') {
    Object.assign(state, payload);
  }
}, initialState);
