import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, Input, Button, Loader } from 'semantic-ui-react';
import { SendInvitationModal, SendMailModal } from '../candidate';
import { AssignLabelModal } from '../candidate/AssignLabelModal';
import { Box, DropdownSelect, Flex, LabeledButton } from '../components';
import { STATUSES, STUDY_CYCLES } from '../constants';
import { getFaculties } from '../faculty/selectors';
import { IssueTable } from '../issue/IssueTable';
import { Label } from '../label';
import { getLabels } from '../label/selectors';
import { getSafeMode } from '../safeMode';
import { freshmenCloseIssues, freshmenDeleteAll, freshmenExport, freshmenFilterSet, freshmenSearchTerm, freshmenFetchAll } from './actions';
import { getFilteredFreshmenIssues, getFreshmenFilters, getFreshmenLoading } from './selectors';

export const FreshmenPage = props => {
  const filters = useSelector(getFreshmenFilters);
  const issues = useSelector(getFilteredFreshmenIssues);
  const loading = useSelector(getFreshmenLoading);
  const faculties = useSelector(getFaculties);
  const labels = useSelector(getLabels);
  const safeMode = useSelector(getSafeMode);
  const dispatch = useDispatch();
  const discrepancies = issues.filter(issue => issue.discrepancy);
  return (
    <Fragment>
      <Flex m={-1} mb={1}>
        <Flex.Item m={1}>
          <h1>Registruoti pirmakursiai</h1>
        </Flex.Item>
        <Flex.Item m={1}>
          {loading && (
            <Loader inline active />
          ) || (
            <Button basic
              icon="refresh"
              onClick={() => dispatch(freshmenFetchAll())} />
          )}
        </Flex.Item>
        <Flex.Item grow={1} />
        <Flex.Item m={1}>
          <Input
            icon="search"
            placeholder="Ieškoti..."
            value={filters.searchText}
            onChange={(e, data) => dispatch(
              freshmenSearchTerm(data.value))} />
        </Flex.Item>
      </Flex>
      <Flex alignItems="center">
        <Flex.Item>
          <DropdownSelect inline
            text="Fakultetas"
            items={[
              {
                key: 'undefined',
                value: undefined,
                text: 'Visi fakultetai',
              },
              ...faculties.map(faculty => ({
                key: faculty.id,
                value: faculty.id,
                text: faculty.name,
              })),
            ]}
            value={filters.faculty}
            onChange={faculty => dispatch(
              freshmenFilterSet({ faculty }))} />
            <DropdownSelect inline
              text="Studijų pakopa"
              items={STUDY_CYCLES.map(item => ({
                key: String(item.id),
                value: item.id,
                text: item.name,
                description: issues
                  .filter(issue => issue.studyCycle === item.id)
                  .length,
              }))}
              value={filters.studyCycle}
              onChange={studyCycle => dispatch(
                freshmenFilterSet({ studyCycle }))} />
            <DropdownSelect inline
              text="Būsena"
              items={[
                {
                  key: 'undefined',
                  value: undefined,
                  text: 'Visos būsenos',
                },
                ...STATUSES.map(item => ({
                  key: String(item.id),
                  value: item.id,
                  text: item.name,
                  description: issues
                    .filter(issue => issue.status === item.id)
                    .length,
                })),
              ]}
              value={filters.status}
              onChange={status => dispatch(
                freshmenFilterSet({ status }))} />
            <DropdownSelect inline
              text="Žyma"
              items={[
                {
                  key: 'undefined',
                  value: undefined,
                  text: 'Visos žymos',
                },
                ...labels.map(label => ({
                  key: label.id,
                  value: label.id,
                  text: (
                    <Label color={label.color} value={label.name} />
                  ),
                })),
              ]}
              value={filters.labelId}
              onChange={labelId => dispatch(
                freshmenFilterSet({ labelId }))} />
        </Flex.Item>
        <Flex.Item grow={1} />
        {!safeMode && (
          <Flex.Item>
            <LabeledButton
              icon="warning sign"
              color="red"
              text="Išvalyti eilę"
              onClick={() => dispatch(freshmenDeleteAll())} />
          </Flex.Item>
        )}
      </Flex>
      <Box my={2}>
        <Box my={1}>
          <Checkbox
            label={`Rodyti socialiai remtinus`}
            checked={filters.disadvantaged}
            onChange={(e, data) => {
              dispatch(freshmenFilterSet({
                disadvantaged: data.checked,
              }));
            }} />
        </Box>
        <Box my={1}>
          <Checkbox
            label={`Rodyti neįgaliuosius`}
            checked={filters.handicapped}
            onChange={(e, data) => {
              dispatch(freshmenFilterSet({
                handicapped: data.checked,
              }));
            }} />
        </Box>
        <Box my={1}>
          <Checkbox
            label={`Rodyti registruotus VU duomenu bazėje`}
            checked={filters.validStudent}
            onChange={(e, data) => {
              dispatch(freshmenFilterSet({
                validStudent: data.checked,
              }));
            }} />
        </Box>
        {discrepancies.length > 0 && (
          <Box my={1}>
            <Checkbox
              label={`Rodyti registracijas su neatitikimais (${discrepancies.length})`}
              checked={filters.discrepancy}
              onChange={(e, data) => {
                dispatch(freshmenFilterSet({
                  discrepancy: data.checked,
                }));
              }} />
          </Box>
        )}
      </Box>
      <IssueTable
        issues={issues}
        fields={{
          displayName: true,
          personalCode: true,
          faculty: true,
          studyCycle: true,
          disadvantaged: true,
          createdAt: true,
          status: true,
          labels: true,
        }} />
      <LabeledButton
        text={`Parsisiusti CSV (${issues.length})`}
        icon="download"
        onClick={() => dispatch(freshmenExport())} />
      {!safeMode && (
        <LabeledButton
          icon="dropbox"
          color="yellow"
          text={`Atmesti (${issues.length})`}
          onClick={() => dispatch(freshmenCloseIssues(issues))} />
      )}
      <AssignLabelModal issues={issues} />
      <SendMailModal queueType={'freshmen'} issues={issues} />
      <SendInvitationModal queueType={'freshmen'} issues={issues} />
    </Fragment>
  );
};
