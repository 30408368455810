import React, { Component } from 'react';
import ReactDatePicker from 'react-datepicker';
import { format, parse } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';

const DATE_FORMAT = 'yyyy-MM-dd';

class SemanticUiInput extends Component {
  render() {
    const { props } = this;
    return (
      <div className="ui input">
        <input {...props} />
      </div>
    );
  }
}

const dateToString = date => format(date, DATE_FORMAT);
const stringToDate = string => parse(string, DATE_FORMAT, new Date());

export const DatePicker = props => {
  const {
    value = dateToString(new Date()),
    onChange,
    ...rest
  } = props;
  return <ReactDatePicker {...rest}
    selected={stringToDate(value)}
    onChange={date => {
      onChange && onChange(dateToString(date));
    }}
    customInput={<SemanticUiInput />}
    dateFormat={DATE_FORMAT} />;
};
