import React, { Component } from 'react';
import { Tab as SemanticTab } from 'semantic-ui-react';

export const Tabs = props => {
  const { children } = props;
  return (
    <SemanticTab
      panes={children.map((x) => {
        return {
          menuItem: x.props.name,
          render: () => (
            <SemanticTab.Pane>
              {x.props.render()}
            </SemanticTab.Pane>
          ),
        };
      })} />
  );
};

// Dummy element to contain tab metadata
export const Tab = props => {
  // { name, render }
};

// Export it through the main component
Tabs.Tab = Tab;
