import React from 'react';
import { Label as SuiLabel } from 'semantic-ui-react';
import { Editable } from '../components';

export const Label = props => {
  const {
    editable,
    value,
    placeholder,
    color,
    onChange,
    onDelete,
    ...rest
  } = props;
  if (!editable) {
    return (
      <SuiLabel horizontal color={color}
        content={value || '—'}
        {...rest} />
    );
  }
  return (
    <SuiLabel horizontal color={color} {...rest}>
      <Editable value={value}
        placeholder={placeholder}
        onChange={onChange} />
    </SuiLabel>
  );
};
