import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthUser } from './auth/selectors';
import { getCampuses } from './campus/selectors';
import { DropdownSelect } from './components';

export const getSelectedCampusId = state => state.globalCampusFilter;
export const getSelectedCampus = state => {
  return state.campus && state.campus[state.globalCampusFilter];
};

export const globalCampusFilterReducer = (state = null, action) => {
  const { type, payload } = action;
  if (type === 'globalCampusFilter/UPDATE') {
    return payload.campusId || null;
  }
  return state;
};

export const globalCampusFilterUpdate = campusId => ({
  type: 'globalCampusFilter/UPDATE',
  payload: { campusId },
});

export const GlobalCampusFilter = props => {
  const { countingFn } = props;
  const user = useSelector(getAuthUser);
  const campuses = useSelector(getCampuses)
    // TODO: Refactor into an appropriate selector
    .filter(campus => {
      if (user.role === 'administrator') {
        return true;
      }
      return campus.id === undefined
        || user.scope.campus.includes(campus.id);
    });
  const campusId = useSelector(getSelectedCampusId);
  const dispatch = useDispatch();
  return (
    <DropdownSelect button
      icon="filter"
      text="Visi bendrabučiai"
      items={[
        {
          key: 'undefined',
          value: undefined,
          text: 'Visi bendrabučiai',
        },
        ...campuses.map(campus => ({
          key: campus.id,
          value: campus.id,
          text: campus.abbr,
          description: countingFn(campus),
        })),
      ]}
      value={campusId}
      onChange={campusId => dispatch(
        globalCampusFilterUpdate(campusId))} />
  );
};
