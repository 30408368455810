import { parse, differenceInYears } from 'date-fns';

/**
 * Calculate a checksum (last digit) for a given personal code.
 *
 * Accepts a personal code as an input with or without a checksum.
 */
export const personalCodeChecksum = code => {
  let b = 1, c = 3, d = 0, e = 0, i, digit;
  for (i = 0; i < 10; i++) {
    digit = parseInt(code[i], 10);
    d += digit * b;
    e += digit * c;
    if (++b === 10) {
      b = 1;
    }
    if (++c === 10) {
      c = 1;
    }
  }
  d %= 11;
  e %= 11;
  if (d < 10) {
    return d;
  }
  else if (e < 10) {
    return e;
  }
  else {
    return 0;
  }
}

/**
 * Validates a given personal code.
 */
export const isPersonalCodeValid = code => {
  if (typeof code !== 'number' && typeof code !== 'string') {
    return false;
  }
  // Cast and trim the string
  const cleanCode = String(code).trim();
  if (cleanCode.length !== 11) {
    return false;
  }
  const checksum = personalCodeChecksum(cleanCode);
  if (checksum !== parseInt(cleanCode.charAt(10), 10)) {
    return false;
  }
  return true;
}

/**
 * Decompose personal code into its components.
 */
export const parsePersonalCode = (code, now = new Date()) => {
  if (typeof code !== 'number' && typeof code !== 'string') {
    return null;
  }
  const cleanCode = String(code).trim();
  // Parse sex
  const number = parseInt(cleanCode.substr(0, 1), 10);
  const sex = number % 2 === 0
    ? 'female'
    : 'male';
  // Parse date of birth in ISO format
  const century = 17 + Math.ceil(parseInt(cleanCode.substr(0, 1), 10) / 2);
  const year = parseInt(cleanCode.substr(1, 2), 10);
  const month = parseInt(cleanCode.substr(3, 2), 10);
  const day = parseInt(cleanCode.substr(5, 2), 10);
  const dateOfBirth = '' + (100 * century + year)
    + '-' + String(month).padStart(2, '0')
    + '-' + String(day).padStart(2, '0');
  // Parse serial number
  const serial = parseInt(cleanCode.substr(7, 3), 10);
  // Parse checksum
  const checksum = parseInt(cleanCode.substr(10, 1), 10);
  // Calculate age
  const age = getAge(dateOfBirth, now);
  // Return parsed result
  return { sex, dateOfBirth, age, serial, checksum };
};

const getAge = (dateOfBirth, now = new Date()) => {
  const parsedDate = dateOfBirth instanceof Date
    ? dateOfBirth
    : parse(dateOfBirth, 'yyyy-MM-dd', now);
  return differenceInYears(now, parsedDate);
};

/**
 * Calculate person's age relative to "now" or a given date.
 */
export const personalCodeToAge = (code, now = new Date()) => {
  const parsedCode = parsePersonalCode(code);
  return parsedCode && getAge(parsedCode, now);
};
