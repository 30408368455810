import React, { Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Flex } from '../components';
import { IssueLogTable } from '../issueLog/IssueLogTable';
import { findIssueLogEntries, getIssueLogLoading } from '../issueLog/selectors';
import { Button, Loader } from 'semantic-ui-react';
import { issueLogFetchInternational } from '../issueLog/actions';

export const InternationalLogPage = props => {
  const entries = useSelector(findIssueLogEntries({
    issueType: 'international',
  }));
  const loading = useSelector(getIssueLogLoading);
  const dispatch = useDispatch();
  return (
    <Fragment>
      <Box>
        <Flex m={-1}>
          <Flex.Item m={1}>
            <h1>Pirmakursių veiksmų žurnalas</h1>
          </Flex.Item>
          <Flex.Item m={1}>
            {loading && (
              <Loader inline active />
            ) || (
              <Button basic
                icon="refresh"
                onClick={() => dispatch(issueLogFetchInternational())} />
            )}
          </Flex.Item>
        </Flex>
        <IssueLogTable entries={entries} />
      </Box>
    </Fragment>
  );
};
