import { all, call, delay, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { actionEmittingSaga } from 'redux-tools/saga';
import { requestApi, setRequestAuthToken } from '../api';
import { routerActions } from '../router';
import * as userActions from '../user/actions';
import * as authActions from './actions';

export const authSaga = function* () {
  yield loadTokenSaga();
  yield all([
    takeLatest('auth/LOGIN', loginSaga),
    takeLatest('auth/LOGIN_SUCCESS', function* () {
      yield put(routerActions.navigateTo('issueQueueDashboard'));
    }),
    takeLatest('auth/LOGOUT', logoutSaga),
  ]);
};

const loginSaga = actionEmittingSaga(function* (action) {
  const { payload } = action;
  const res = yield call(requestApi.post, '/auth/login', payload);
  const { token } = res.data;
  localStorage.removeItem('authToken');
  if (token) {
    localStorage.setItem('authToken', token);
  }
  yield loadTokenSaga();
});

const logoutSaga = actionEmittingSaga(function* (action) {
  yield unloadTokenSaga();
});

const loadTokenSaga = function* () {
  // Small delay to allow all sagas to load
  yield delay(10);
  const token = localStorage.getItem('authToken');
  if (!token) {
    yield put(authActions.authReady());
    yield put(routerActions.navigateTo('login'));
    return;
  }
  console.debug('loading token', token);
  setRequestAuthToken(token);
  try {
    const res = yield call(requestApi.get, '/auth/user');
    const user = res.data;
    yield put(userActions.userLoad([user]));
    yield put(authActions.tokenLoad(token, user.id));
    yield put(authActions.authReady());
  }
  catch (err) {
    localStorage.removeItem('authToken');
    yield put(authActions.tokenUnload());
    yield put(authActions.authReady());
    yield put(routerActions.navigateTo('login'));
  }
};

const unloadTokenSaga = function* () {
  localStorage.removeItem('authToken');
  yield put(authActions.tokenUnload());
  yield put(routerActions.navigateTo('login'));
};
