import React, { useState, useRef } from 'react';

/**
 * Editable field which fires an event after you committed changes by
 * pressing Enter or clicking away from it.
 */
export const Editable = props => {
  const { value, placeholder, onChange, editable, className } = props;
  const [editing, setEditing] = useState(false);
  const [editedValue, setEditedValue] = useState('');
  const inputRef = useRef();

  // Transactions
  const beginChange = () => {
    setEditing(true);
    setEditedValue(value);
    setTimeout(() => inputRef.current.focus());
  };
  const commitChange = () => {
    setEditing(false);
    if (onChange && value !== editedValue) {
      onChange(editedValue);
    }
  };
  const revertChange = () => {
    setEditing(false);
  };

  // Editable state
  if (editing) {
    return (
      <input type="text"
        className={className}
        ref={inputRef}
        style={{
          width: '100%',
          border: '0',
          boxSizing: 'border-box',
          outline: '0',
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
          fontFamily: 'inherit',
          fontWeight: 'inherit',
          color: 'inherit',
          margin: 0,
          padding: 0,
        }}
        value={editedValue}
        onChange={e => setEditedValue(e.target.value)}
        onBlur={commitChange}
        onKeyUp={e => {
          if (e.key === 'Enter') {
            commitChange();
          }
          if (e.key === 'Escape') {
            revertChange();
          }
        }} />
    );
  }

  // View-only state
  return (
    <div
      className={className}
      style={{
        width: '100%',
        height: '100%',
      }}
      onClick={e => {
        if (editable === false) {
          return;
        }
        beginChange();
      }}>
      {value || placeholder || '—'}
    </div>
  )
};
