import { concat, uniq, without } from 'lodash';
import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Message, Button } from 'semantic-ui-react';
import { getCampuses } from '../campus/selectors';
import { Flex, Loader } from '../components';
import { getUserById, getUserForm } from './selectors';
import { QUEUES } from 'src/constants/index.js';
const ROLES = [
  'administrator',
  'superintendent',
];

export const UserEditPage = props => {
  const { userId } = props;
  const user = useSelector(getUserById(userId));
  const userForm = useSelector(getUserForm);
  const campuses = useSelector(getCampuses);
  const dispatch = useDispatch();
  if (!campuses) {
    return <Loader />;
  }
  const [fields, setFields] = useState({
    firstName: user && user.firstName || '',
    lastName: user && user.lastName || '',
    email: user && user.email || '',
    ldapUid: user && user.ldapUid || '',
    role: user && user.role || '',
    campuses: user && user.campuses.map(x => x.id) || [],
    queues: user && user.queues.map(x => x) || [],
    password1: '',
    password2: '',
  });
  const inputFieldProps = name => ({
    value: fields[name],
    onChange: (e, data) => setFields({
      ...fields,
      [name]: data.value,
    }),
  });
  return (
    <Fragment>
      <Flex m={-2} mb={0}>
        <Flex.Item m={2}>
          <Button icon="left arrow"
            onClick={() => history.back()} />
        </Flex.Item>
        <Flex.Item m={2} grow={2}>
          <h1>Vartotojas</h1>
        </Flex.Item>
      </Flex>
      {userForm.error && (
        <Message error content={userForm.message} />
      )}
      <Form
        onSubmit={() => {
          dispatch({
            type: 'user/UPDATE',
            payload: {
              userId,
              fields,
            },
          });
        }}>
        <Form.Input
          type="text"
          label="Vardas"
          {...inputFieldProps('firstName')} />
        <Form.Input
          type="text"
          label="Pavardė"
          {...inputFieldProps('lastName')} />
        <Form.Input
          type="text"
          label="El. paštas"
          {...inputFieldProps('email')} />
        <Form.Input
          type="text"
          label="LDAP prisijungimo vardas"
          {...inputFieldProps('ldapUid')} />
        <Form.Input
          type="password"
          autoComplete="new-password"
          label="Slaptažodis"
          {...inputFieldProps('password1')} />
        <Form.Input
          type="password"
          autoComplete="new-password"
          label="Pakartokite slaptažodį"
          {...inputFieldProps('password2')} />
        <Form.Select
          label="Rolė"
          options={ROLES.map(role => ({
            key: role,
            value: role,
            text: role,
          }))}
          {...inputFieldProps('role')} />
        {fields.role === 'superintendent' && campuses.map(campus => (
          <Form.Checkbox
            key={campus.id}
            label={campus.abbr}
            checked={fields.campuses.includes(campus.id)}
            onChange={(e, data) => {
              if (data.checked) {
                setFields({
                  ...fields,
                  campuses: uniq(concat(fields.campuses, campus.id)),
                });
              }
              else {
                setFields({
                  ...fields,
                  campuses: without(fields.campuses, campus.id),
                });
              }
            }} />
        ))}
        {fields.role === 'superintendent' && QUEUES.map(queue => (
          <Form.Checkbox
            key={queue.id}
            label={queue.name}
            checked={fields.queues.includes(queue.id)}
            onChange={(e, data) => {
              if (data.checked) {
                setFields({
                  ...fields,
                  queues: uniq(concat(fields.queues, queue.id)),
                });
              }
              else {
                setFields({
                  ...fields,
                  queues: without(fields.queues, queue.id),
                });
              }
            }} />
        ))}
        <Form.Button content="Išsaugoti" />
      </Form>
    </Fragment>
  );
};
