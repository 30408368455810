import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox } from 'semantic-ui-react';
import { getAuthUser } from '../auth/selectors';
import { Flex, LabeledList } from '../components';
import { settingsUpdate } from './actions';
import { getSettings } from './selectors';

const CheckboxItem = props => {
  const { label, name } = props;
  const settings = useSelector(getSettings);
  const user = useSelector(getAuthUser);
  const dispatch = useDispatch();
  const canEditSettings = user.role === 'administrator';
  const value = settings[name] || false;
  return (
    <LabeledList.Item label={label}>
      <Checkbox toggle
        checked={value}
        onChange={() => {
          dispatch(settingsUpdate({
            [name]: !value,
          }));
        }}
        disabled={!canEditSettings} />
    </LabeledList.Item>
  );
};

export const SettingsPage = props => {
  return (
    <Fragment>
      <Flex m={-1} mb={1}>
        <Flex.Item m={1} grow={1}>
          <h1>Nustatymai</h1>
        </Flex.Item>
      </Flex>
      <LabeledList>
        <CheckboxItem
          label="Registracija į bendrą eilę"
          name="enable_queue_registration" />
        <CheckboxItem
          label="Registracija pirmakursiams"
          name="enable_freshmen_registration" />
        <CheckboxItem
          label="Asmens kodo tikrinimas VU duomenu bazėje"
          name="personal_code_checks_enabled" />
        <CheckboxItem
          label="Leisti registruotis bakalaurams"
          name="enable_freshmen_cycle_1" />
        <CheckboxItem
          label="Leisti registruotis magistrams"
          name="enable_freshmen_cycle_2" />
        <CheckboxItem
          label="Leisti registruotis doktorantams ir rezidentams"
          name="enable_freshmen_cycle_3" />
        <CheckboxItem
          label="Leisti registruotis vientisų studijų studentams"
          name="enable_freshmen_cycle_4" />
        <CheckboxItem
          label="Leisti registruotis tarptautinių studijų studentams"
          name="enable_international_registration" />
      </LabeledList>
    </Fragment>
  );
};
