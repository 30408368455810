import { flow } from 'functional';
import { filter, map, values } from 'lodash/fp';
import { populateIssue, searchTermMatcher } from '../issue/selectors';

export const getInternationalFilters = state => state.international.filters;

export const getInternationalIssues = state => {
  return flow([
    state => state.issue.all,
    map(id => state.issue.byId[id]),
    filter(issue => issue.type === 'international'),
    map(issue => populateIssue(state, issue)),
  ])(state);
};

export const getFilteredInternationalIssues = state => {
  const filters = getInternationalFilters(state);
  return flow([
    state => state.issue.all,
    map(id => state.issue.byId[id]),
    filter(issue => issue.type === 'international'),
    filters.searchText && (
      filter(searchTermMatcher(filters.searchText))
    ),
    filters.faculty && (
      filter(issue => issue.faculty === filters.faculty)
    ),
    filters.studyCycle && (
      filter(issue => issue.studyCycle === filters.studyCycle)
    ),
    filters.status && (
      filter(issue => issue.status === filters.status)
    ),
    filters.discrepancy && (
      filter(issue => issue.discrepancy)
    ),
    filters.disadvantaged && (
      filter(issue => issue.disadvantaged === filters.disadvantaged)
    ),
    filters.handicapped && (
      filter(issue => issue.handicapped === filters.handicapped)
    ),
    filters.validStudent && (
      filter(issue => issue.validStudent === filters.validStudent)
    ),
    map(issue => populateIssue(state, issue)),
    filters.labelId && (
      filter(issue => issue.labels
        .some(label => label.id === filters.labelId))
    ),
  ])(state);
};

export const getInternationalLoading = state => state.international.loading;
export const getInternationalLoaded = state => state.international.loaded;
