import { all, call, put, select, takeEvery } from 'redux-saga/effects';
import { actionEmittingSaga } from 'redux-tools/saga';
import { requestApi } from '../api';
import { getAuthState } from '../auth/selectors';
import { routerActions } from '../router';
import { userLoad, userFetchAll } from './actions';

export const userSaga = function* () {
  yield all([
    takeEvery('auth/READY', function* () {
      const auth = yield select(getAuthState);
      if (!auth.token) {
        return;
      }
      yield put(userFetchAll());
    }),
    takeEvery('user/FETCH_ALL', userFetchAllSaga),
    takeEvery('user/FETCH_MANY', userFetchManySaga),
    takeEvery('user/UPDATE', userUpdateSaga),
    takeEvery('user/UPDATE_SUCCESS', function* () {
      yield put(routerActions.navigateTo('userIndex'));
    }),
  ]);
};

const userFetchAllSaga = actionEmittingSaga(function* () {
  const res = yield call(requestApi.get, '/user?administrative=true');
  yield put(userLoad(res.data));
});

const userFetchManySaga = actionEmittingSaga(function* (action) {
  const { userIds } = action.payload;
  const res = yield call(requestApi.post, '/user/find_bulk', {
    ids: userIds,
  });
  yield put(userLoad(res.data));
});

const userUpdateSaga = actionEmittingSaga(function* (action) {
  const { userId, fields } = action.payload;
  // Build POST data
  const postData = {
    ...fields,
    scope: {
      campus: fields.campuses,
      queues: fields.queues,
    },
  };
  // Validate password
  if (fields.password1) {
    if (fields.password1 !== fields.password2) {
      yield put({
        type: `user/${actionPrefix}_ERROR`,
        payload: {
          error: true,
          message: 'Passwords do not match!',
        },
      });
      return;
    }
    postData.password = fields.password1;
  }
  // Remove irrelevant fields
  postData.password1 = undefined;
  postData.password2 = undefined;
  postData.campuses = undefined;
  postData.queues = undefined;
  // Call API
  const res = yield call(
    userId ? requestApi.put : requestApi.post,
    userId ? `/user/${userId}` : '/user',
    postData);
  yield put(userLoad([res.data]));
});
