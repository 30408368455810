import produce from 'immer';

export const freshmenReducer = produce((state, action) => {
  const { type, payload } = action;

  if (type === 'freshmen/SEARCH_TERM') {
    state.filters.searchText = payload;
  }

  if (type === 'freshmen/FILTER_SET') {
    Object.assign(state.filters, payload);
  }

  if (type === 'freshmen/FETCH_START') {
    state.loading = true;
  }

  if (type === 'freshmen/FETCH_SUCCESS') {
    state.loading = false;
    state.loaded = true;
  }

  if (type === 'freshmen/FETCH_ERROR') {
    state.loading = false;
  }
}, {
  filters: {
    searchText: '',
    loading: false,
    loaded: false,
  },
});
