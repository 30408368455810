import { format as dateFnsFormat } from 'date-fns';
import { ROOM_TYPES, STATUSES } from './constants';

const DATE_FORMAT = 'yyyy-MM-dd';
const TIME_FORMAT = 'HH:mm';
const DATETIME_FORMAT = 'yyyy-MM-dd HH:mm'

export const formatYesNo = value => value ? 'taip' : 'ne';

export const formatDate = value => {
  return value && dateFnsFormat(new Date(value), DATE_FORMAT);
};

export const formatTime = value => {
  return value && dateFnsFormat(new Date(value), TIME_FORMAT);
};

export const formatDateTime = value => {
  return value && dateFnsFormat(new Date(value), DATETIME_FORMAT);
};

export const formatRoom = value => {
  if (value === 'any') {
    return '—';
  }
  const roomType = ROOM_TYPES.find(item => item.id === value);
  return roomType && roomType.name || value || '—';
};

export const formatUser = value => value
  && (value.displayName || value.fullName || value.email)
  || '—';

export const formatStatus = value => {
  const status = STATUSES.find(item => item.id === value);
  return status && status.name || value || '—';
};

export const formatIssueType = value => {
  const ISSUE_TYPE_MAP = {
    queue: 'bendra eilė',
    freshmen: 'pirmakursis',
  };
  return ISSUE_TYPE_MAP[value] || '—';
};

export const formatStudyType = value => {
  const STUDY_TYPE_MAP = {
    full: 'Pilnas',
    exchange: 'Mainų',
  };
  return STUDY_TYPE_MAP[value] || '—';
}