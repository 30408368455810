import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthUser } from './auth/selectors';
import { getCampuses } from './campus/selectors';
import { Box } from './components';

export const getSafeMode = state => state.safeMode;

export const safeModeReducer = (state = true, action) => {
  const { type, payload } = action;
  if (type === 'safeMode/UPDATE') {
    return payload;
  }
  return state;
};

export const safeModeUpdate = value => ({
  type: 'safeMode/UPDATE',
  payload: value,
});

export const SafeModeToggle = props => {
  const safeMode = useSelector(getSafeMode);
  const dispatch = useDispatch();
  return (
    <Box
      style={{
        fontSize: '9pt',
        lineHeight: 1,
        fontWeight: 'bold',
        padding: '0.3rem 0.5rem',
        color: '#fff',
        backgroundColor: safeMode
          ? 'rgba(40, 255, 0, 0.35)'
          : 'rgba(255, 0, 0, 0.8)',
        cursor: 'pointer',
        userSelect: 'none',
      }}
      onClick={() => dispatch(safeModeUpdate(!safeMode))}>
      Safe Mode: {safeMode ? 'ON' : 'OFF'}
    </Box>
  );
};
