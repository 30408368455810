import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Button, Table } from 'semantic-ui-react';
import { Flex } from '../components';
import { Link } from '../router';
import { getCampuses } from './selectors';
import { getAuthUser } from '../auth/selectors';

export const CampusIndexPage = props => {
  const user = useSelector(getAuthUser);
  const campuses = useSelector(getCampuses)
    // TODO: Refactor into an appropriate selector
    .filter(campus => {
      if (user.role === 'administrator') {
        return true;
      }
      return campus.id === undefined
        || user.scope.campus.includes(campus.id);
    });
  return (
    <Fragment>
      <Flex m={-2}>
        <Flex.Item m={2} grow={2}>
          <h1>Miesteliai</h1>
        </Flex.Item>
      </Flex>
      <Table compact size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell content="Sutrumpinimas" />
            <Table.HeaderCell content="Pilnas pavadinimas" />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {campuses.map(campus => (
            <Table.Row key={campus.id}>
              <Table.Cell>
                <Link
                  routeName="campusShow"
                  routeParams={{
                    campusId: campus.id,
                  }}>
                  {campus.abbr}
                </Link>
              </Table.Cell>
              <Table.Cell>
                {campus.name}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Fragment>
  );
};
