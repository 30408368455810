const FRESHMEN_TEMPLATES = [
  {
    key: 'none',
    text: '— nesiųsti —',
    noMail: true,
  },
  {
    key: 'freeform',
    text: 'Laisva forma',
    freeform: true,
  },
  {
    key: 'freshmen_accepted',
    text: 'Informacija pakviestiems studentams (generic)',
  },
  {
    key: 'freshmen_new_information',
    text: 'Informacija pakviestiems studentams (personalized, old)',
    requireCampus: true,
  },
  {
    key: 'freshmen_second_round',
    text: 'Nepakviestiems studentams (apie antrą turą)',
  },
  {
    key: 'freshmen_cancelled',
    text: 'Jūsų prašymas buvo atšauktas',
  },
];

const INTERNATIONAL_TEMPLATES = [
  FRESHMEN_TEMPLATES[0],
  FRESHMEN_TEMPLATES[1],
  {
    key: 'international_accepted',
    text: 'Informacija pakviestiems studentams (generic)',
  },
  {
    key: 'international_new_information',
    text: 'Informacija pakviestiems studentams (personalized, old)',
    requireCampus: true,
  },
  {
    key: 'international_cancelled',
    text: 'Jūsų prašymas buvo atšauktas',
  },
];

export default {
  freshmen: FRESHMEN_TEMPLATES,
  international: INTERNATIONAL_TEMPLATES
};