/**
 * Removes excess whitespace and indentation from the string
 * @param  {string} str
 * @return {string}
 */
export const compact = str => {
  return str
    .trim()
    .split('\n')
    .map((x) => x.trim())
    .filter((x) => x.length > 0)
    .join('\n');
};

/**
 * Template literal tag for rendering HTML
 */
export const html = (strings, ...expressions) => {
  const length = strings.length;
  let output = '';
  for (let i = 0; i < length; i++) {
    output += strings[i];
    let expr = expressions[i];
    if (typeof expr === 'boolean' || expr === undefined || expr === null) {
      // Nothing
    }
    else if (Array.isArray(expr)) {
      output += expr.join('\n');
    }
    else {
      output += expr;
    }
  }
  return output;
};

/**
 * Matches strings with wildcards.
 * Example: testGlobPattern('*@domain')('user@domain') === true
 */
export const testGlobPattern = pattern => {
  const escapeString = str => str.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&');
  const regex = new RegExp('^'
    + pattern.split(/\*+/).map(escapeString).join('.*')
    + '$');
  return str => regex.test(str);
};
