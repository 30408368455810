import React from 'react';

export const UploadButton = props => {
  const { text, icon, color, onUpload } = props;
  return (
    <label className={`ui button labeled icon ${color}`}>
      <i className={`icon ${icon || 'upload'}`}></i>
      {text}
      <input type="file"
        style={{ display: 'none' }}
        onChange={e => {
          onUpload && onUpload(e.target.files[0]);
        }} />
    </label>
  );
}
