import { find, map } from 'lodash/fp';
import React from 'react';
import { classes } from 'react-tools';
import { Dropdown } from 'semantic-ui-react';

export const DropdownSelect = props => {
  const {
    button,
    inline,
    direction,
    text,
    items,
    value,
    icon = 'dropdown',
    onChange = () => undefined,
    mapToText = obj => obj.text || obj.value,
    mapToValue = obj => obj.value,
    mapToKey = obj => obj.key || String(obj.value),
    mapToDescription = obj => obj.description || null,
  } = props;
  const activeItem = find((x, i) => mapToValue(x, i) === value)(items);
  // Derive text displayed on the button.
  // HACK: Silently drop any exceptions if item is undefined
  let displayedText;
  try {
    displayedText = mapToText(activeItem);
  }
  catch {}
  displayedText = displayedText || text;
  return (
    <Dropdown
      inline={inline}
      direction={direction}
      className={classes([
        button && 'icon labeled button',
        inline && 'mx-1',
      ])}
      icon={icon}
      text={displayedText}>
      <Dropdown.Menu>
        {/*
        <Dropdown.Header icon={icon} content={props.text} />
        <Dropdown.Divider />
        */}
        {map((x, i) => (
          <Dropdown.Item
            key={mapToKey(x, i)}
            content={mapToText(x, i)}
            description={mapToDescription(x, i)}
            onClick={() => onChange(mapToValue(x, i))} />
        ))(items)}
      </Dropdown.Menu>
    </Dropdown>
  );
};
