export const issueLoad = issues => ({
  type: 'issue/LOAD',
  payload: { issues },
});

export const issueResolve = issueId => ({
  type: 'issue/RESOLVE',
  payload: { issueId },
});

export const issueReject = issueId => ({
  type: 'issue/REJECT',
  payload: { issueId },
});

export const issueFileDelete = (issueId, fileId) => ({
  type: 'issue/FILE_DELETE',
  payload: { issueId, fileId },
});

export const issueFetchDashboard = () => ({
  type: 'issue/FETCH_DASHBOARD',
});

export const issueFetchAll = () => ({
  type: 'issue/FETCH_ALL',
});

export const issueFetchOne = issueId => ({
  type: 'issue/FETCH_ONE',
  payload: { issueId },
});

export const issueFetchMany = issueIds => ({
  type: 'issue/FETCH_MANY',
  payload: { issueIds },
});
