import { flow } from 'functional';
import produce from 'immer';
import { map, values } from 'lodash/fp';

export const facultyReducer = produce((state, action) => {
  const { type, payload } = action;

  if (type === 'faculty/LOAD') {
    for (let faculty of payload.faculties) {
      state.byId[faculty.id] = faculty;
    }
    state.all = flow([
      state => values(state.byId),
      map(faculty => faculty.id),
    ])(state);
  }
}, {
  byId: {},
  all: [],
  form: {},
});
