import { flow } from 'functional';
import { map } from 'lodash/fp';

export const getCampuses = state => flow([
  state => state.campus.all,
  map(id => state.campus.byId[id]),
])(state);

export const getCampusById = id => state => state.campus.byId[id];

export const getCampusForm = state => state.campus.form;
