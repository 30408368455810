import CSV from 'csv';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { actionEmittingSaga } from 'redux-tools/saga';
import { requestApi } from '../api';
import { serveTextAsFile } from '../file';
import { issueLoad } from '../issue/actions';
import { isTransitionTo } from '../router';
import { freshmenFetchAll } from './actions';
import { getFilteredFreshmenIssues, getFreshmenLoaded } from './selectors';

export const freshmenSaga = function* () {
  yield all([
    takeLatest([
      isTransitionTo('freshmen'),
      isTransitionTo('freshmenStatistics'),
    ], function* () {
      const loaded = yield select(getFreshmenLoaded);
      if (loaded) {
        // Skip loading
        return;
      }
      yield put(freshmenFetchAll());
    }),
    takeLatest('freshmen/FETCH', freshmenFetchSaga),
    takeLatest('freshmen/DELETE_ALL', freshmenDeleteAllSaga),
    takeLatest('freshmen/CLOSE_ISSUES', freshmenCloseIssuesSaga),
    takeLatest('freshmen/EXPORT', freshmenExportSaga),
  ]);
};

const freshmenFetchSaga = actionEmittingSaga(function* () {
  const res = yield call(requestApi.get,
    '/application?dashboard=true&type=freshmen');
  yield put(issueLoad(res.data));
});

const freshmenDeleteAllSaga = function* () {
  const challenge = yield call(window.prompt,
    'THIS IS A DANGEROUS OPERATION!\n\n'
    + 'All registrations will be permanently deleted and there is no way back.\n'
    + 'Make sure you have backups before doing this.\n\n'
    + 'Please type "delete all freshmen" to confirm this action.');
  if (challenge !== 'delete all freshmen') {
    return;
  }
  console.debug('Expiring...');
  const res = yield call(requestApi.post, '/application/delete_all/freshmen');
  console.debug('OK', res);
  location.reload();
};

const freshmenCloseIssuesSaga = actionEmittingSaga(function* (action) {
  const confirmed = yield call(window.confirm, 'Are you sure?');
  if (!confirmed) {
    return;
  }
  const { issueIds } = action.payload;
  console.debug('Closing issues...', issueIds);
  // const issues = yield select(state => issueIds
  //   .map(id => getIssueById(id)(state)));
  const res = yield call(requestApi.post,
    '/application/close_applications',
    {
      applications: issueIds,
    });
  console.debug('OK', res);
  yield put(freshmenFetchAll({ force: true }));
});

const freshmenExportSaga = function* () {
  const issues = yield select(getFilteredFreshmenIssues);
  if (!issues || issues.length === 0) {
    yield call(window.alert, 'Nothing to export!');
    return;
  }
  // Build CSV
  const header = [
    'Vardas',
    'Pavardė',
    'El. paštas',
    'Asmens kodas',
    'Fakultetas',
    'Studijų pakopa',
    'Soc. remtinas',
    'Lytis',
  ];
  const data = issues.map(x => [
    x.firstName,
    x.lastName,
    x.email,
    x.personalCode,
    x.faculty && x.faculty.name,
    x.studyCycle,
    x.disadvantaged ? '1' : '0',
    x.sex === 'female' ? 'M' : 'V',
  ]);
  data.unshift(header);
  const csv = new CSV(data);
  // Serve the file
  yield call(serveTextAsFile, 'freshmen.export.csv', csv.encode());
};
