import { flow } from 'functional';
import { filter, map } from 'lodash/fp';
import { API_BASE } from '../api';
import { getCampusById } from '../campus/selectors';
import { getFacultyById } from '../faculty/selectors';
import { formatIssueType, formatRoom, formatStatus } from '../formatters';
import { getLabelsByIssueId } from '../label/selectors';
import { getAuthUser } from '../auth/selectors';

export const populateIssue = (state, issue) => {
  return issue && {
    ...issue,
    faculty: getFacultyById(issue.faculty)(state),
    campus: getCampusById(issue.campus)(state),
    labels: getLabelsByIssueId(issue.id)(state),
  };
};

export const searchTermMatcher = searchTerm => issue => {
  const contentItems = [
    issue.seqId,
    issue.fullName,
    issue.type,
    formatIssueType(issue.type),
    issue.status,
    formatStatus(issue.status),
    formatRoom(issue.roomType),
    issue.faculty && issue.faculty.name,
    issue.faculty && issue.faculty.abbr,
    issue.campus && issue.campus.name,
    issue.campus && issue.campus.abbr,
    issue.personalCode,
    issue.studentCode,
    issue.email,
  ];
  const content = contentItems.join(' ').toLowerCase();
  const searchTermWords = searchTerm.toLowerCase().split(' ');
  return searchTermWords.every(word => content.includes(word));
};

export const getIssues = state => flow([
  state => state.issue.all,
  map(id => state.issue.byId[id]),
  map(issue => populateIssue(state, issue)),
])(state);

export const getDequeuedIssues = state => {
  const user = getAuthUser(state);
  return flow([
    state => state.issue.dequeued,
    map(id => state.issue.byId[id]),
    filter(issue => !state.globalCampusFilter
      || issue.campus === state.globalCampusFilter),
    user.role === 'superintendent' && (
      filter(issue => user.scope.campus.includes(issue.campus))
    ),
    map(issue => populateIssue(state, issue)),
  ])(state);
};

export const getIssueById = id => state =>
  populateIssue(state, state.issue.byId[id]);

export const getIssueFiles = id => state => {
  const issue = getIssueById(id)(state);
  if (!issue) {
    return;
  }
  const { files, filesMeta = {} } = issue;
  return map(file => ({
    id: file,
    name: file.split('/').pop(),
    url: API_BASE + '/uploads/' + file,
    ...filesMeta[file],
  }))(files);
};

export const getIssueForm = state => state.issue.form;

export const getIssueLoading = state => state.issue.loading;
export const getIssueLoadedAll = state => state.issue.loadedAll;
