export const issueLogLoad = entries => ({
  type: 'issueLog/LOAD',
  payload: { entries },
});

export const issueLogFetch = criteria => ({
  type: 'issueLog/FETCH',
  payload: criteria,
});

export const issueLogFetchAll = () => ({
  type: 'issueLog/FETCH_ALL',
});

export const issueLogFetchDequeued = () => ({
  type: 'issueLog/FETCH_DEQUEUED',
});

export const issueLogFetchFreshmen = () => ({
  type: 'issueLog/FETCH_FRESHMEN',
});

export const issueLogFetchInternational = () => ({
  type: 'issueLog/FETCH_INTERNATIONAL',
});