import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, createReducer, createSaga } from './store.js';

import './styles/index.scss';

const store = createStore();

function renderLayout() {
  const MOUNT_NODE = document.querySelector('.react-root');
  try {
    const Layout = require('./layout').Layout;
    const component = (
      <Provider store={store}>
        <Layout />
      </Provider>
    );
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    ReactDOM.render(component, MOUNT_NODE);
  }
  catch (err) {
    console.error(err);
    // TODO: Custom error handler
  }
}

// Make Layout component hot reloadable
if (module.hot) {
  module.hot.accept([
    './layout',
  ], () => {
    console.debug('Replacing React components...');
    renderLayout();
  });
}

// Make store hot reloadable
if (module.hot) {
  module.hot.accept([
    './store',
  ], () => {
    console.debug('Replacing Redux store components...');
    store.replaceReducer(createReducer());
    store.replaceSaga(createSaga());
  });
}

// Initialize the page
window.addEventListener('load', () => {
  renderLayout();
});
