import { flow } from 'functional';
import produce from 'immer';
import { sortBy } from 'lodash/fp';
import { createIndexer } from 'redux-tools/gendb';
import { testGlobPattern } from 'string-tools';

const { addMany } = createIndexer({
  collections: {
    all: flow([
      sortBy(entry => -entry.createdAt),
    ]),
  },
})

export const issueLogReducer = produce((state, action) => {
  const { type, payload } = action;

  if (type === 'issueLog/LOAD') {
    const { entries } = payload;
    return addMany(state, entries);
  }

  if (testGlobPattern('issueLog/FETCH*_START')(type)) {
    state.loading = true;
  }

  if (testGlobPattern('issueLog/FETCH*_SUCCESS')(type)) {
    state.loading = false;
  }

  if (testGlobPattern('issueLog/FETCH*_ERROR')(type)) {
    state.loading = false;
  }

  if (type === 'issueLog/FETCH_ALL_START') {
    state.loadedAll = false;
  }

  if (type === 'issueLog/FETCH_ALL_SUCCESS') {
    state.loadedAll = true;
  }

  if (type === 'issueLog/FETCH_DEQUEUED_START') {
    state.loadedDequeued = false;
  }

  if (type === 'issueLog/FETCH_DEQUEUED_SUCCESS') {
    state.loadedDequeued = true;
  }

  if (type === 'issueLog/FETCH_FRESHMEN_START') {
    state.loadedFreshmen = false;
  }

  if (type === 'issueLog/FETCH_FRESHMEN_SUCCESS') {
    state.loadedFreshmen = true;
  }
}, {
  byId: {},
  all: [],
  loading: false,
  loadedAll: false,
  loadedDequeued: false,
  loadedFreshmen: false,
});
