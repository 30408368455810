import produce from 'immer';
import { routerActionTypes } from '../router';

const AFTER_TRANSITION = [
  routerActionTypes.TRANSITION_SUCCESS,
  routerActionTypes.TRANSITION_ERROR,
];

export const authReducer = produce((state, action) => {
  const { type, payload } = action;

  if (type === 'auth/LOGIN_START') {
    state.loading = true;
  }

  if (type === 'auth/LOGIN_SUCCESS') {
    state.loading = false;
    state.success = true;
    state.error = undefined;
  }

  if (type === 'auth/LOGIN_ERROR') {
    const { error } = payload;
    state.loading = false;
    state.success = false;
    state.error = error;
  }

  if (type === 'auth/TOKEN_LOAD') {
    const { token, userId } = payload;
    state.token = token;
    state.userId = userId;
  }

  if (type === 'auth/TOKEN_UNLOAD') {
    state.token = undefined;
    state.userId = undefined;
  }

  if (AFTER_TRANSITION.includes(type)) {
    state.loading = undefined;
    state.success = undefined;
    state.error = undefined;
  }
}, {});
