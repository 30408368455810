export const freshmenSearchTerm = text => ({
  type: 'freshmen/SEARCH_TERM',
  payload: text,
});

export const freshmenExport = () => ({
  type: 'freshmen/EXPORT',
});

export const freshmenFilterSet = filters => ({
  type: 'freshmen/FILTER_SET',
  payload: filters,
});

export const freshmenCloseIssues = issues => ({
  type: 'freshmen/CLOSE_ISSUES',
  payload: {
    issueIds: issues.map(issue => issue.id),
  },
});

export const freshmenFetchAll = () => ({
  type: 'freshmen/FETCH',
});

export const freshmenDeleteAll = () => ({
  type: 'freshmen/DELETE_ALL',
});
