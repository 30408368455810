import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'semantic-ui-react';
import { Box, Flex, Loader } from '../components';
import { getSelectedCampus, GlobalCampusFilter } from '../globalCampusFilter';
import { IssueTable } from '../issue/IssueTable';
import { getDequeuedIssues } from '../issue/selectors';
import { findIssueLogEntries } from '../issueLog/selectors';
import { Link } from '../router';
import { IssueQueueLogPage } from './IssueQueueLogPage';
import { IssueQueueStatistics } from './IssueQueueStatistics';

const filterIssuesByCampus = (issues, campus) => issues
  .filter(issue => campus === undefined
    || campus === issue.campus)

export const IssueQueueDashboardPage = props => {
  const issues = useSelector(getDequeuedIssues);
  const entries = useSelector(findIssueLogEntries({
    issueType: 'queue',
  }));
  const campus = useSelector(getSelectedCampus);
  if (!issues) {
    return <Loader />;
  }
  const filteredIssues = filterIssuesByCampus(issues, campus);
  return (
    <Fragment>
      <Box>
        <Flex>
          <Flex.Item>
            <h1>Eilės statistika</h1>
          </Flex.Item>
        </Flex>
        <IssueQueueStatistics />
      </Box>
      <Box mt={2}>
        <Flex alignItems="center" m={-2}>
          <Flex.Item m={2}>
            <h1>Pakviesti studentai</h1>
          </Flex.Item>
          <Flex.Item m={2} grow={1}>
            <GlobalCampusFilter
              countingFn={campus =>
                filterIssuesByCampus(issues, campus).length} />
          </Flex.Item>
          <Flex.Item m={2}>
            <Button as={Link}
              routeName="issueQueueInvite"
              icon="add"
              labelPosition="left"
              color="green"
              content="Kviesti" />
          </Flex.Item>
        </Flex>
        <IssueTable
          issues={filteredIssues}
          fields={{
            displayName: true,
            personalCode: true,
            faculty: true,
            campus: true,
            accommodated: true,
            disadvantaged: true,
            queuedAt: true,
            status: true,
          }} />
      </Box>
      <IssueQueueLogPage />
    </Fragment>
  );
};
