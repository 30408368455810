import { flow } from 'functional';
import { map } from 'lodash/fp';

export const getFaculties = state => flow([
  state => state.faculty.all,
  map(id => state.faculty.byId[id]),
])(state);

export const getFacultyById = id => state => state.faculty.byId[id];

export const getFacultyForm = state => state.faculty.form;
