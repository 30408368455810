import produce from 'immer';

export const progressReducer = produce((state, action) => {
  const { type, payload } = action;

  if (type === 'progress/SETUP') {
    state.keys = payload.keys;
    state.index = 0;
    state.running = false;
  }

  if (type === 'progress/TICK') {
    const { key } = payload;
    state.index = state.keys.indexOf[key];
    state.running = true;
  }

  if (type === 'progress/FINISH' || type === 'progress/ABORT') {
    state.running = false;
  }
}, {
  keys: [],
  index: 0,
  running: false,
});
