import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { actionEmittingSaga } from 'redux-tools/saga';
import { requestApi } from '../api';
import { routerActions } from '../router';
import { campusLoad } from './actions';

export const campusSaga = function* () {
  yield all([
    campusFetchAllSaga(),
    takeLatest('campus/UPDATE', campusUpdateSaga),
    takeLatest('campus/UPDATE_SUCCESS', function* () {
      yield put(routerActions.navigateTo('campusIndex'));
    }),
  ]);
};

const campusFetchAllSaga = actionEmittingSaga(function* () {
  const res = yield call(requestApi.get, '/campus');
  const campuses = res.data;
  yield put(campusLoad(campuses));
}, {
  typeHint: 'campus/FETCH_ALL',
});

const campusUpdateSaga = actionEmittingSaga(function* (action) {
  const { campusId, fields } = action.payload;
  const res = yield call(requestApi.put, `/campus/${campusId}`, fields);
  yield put(campusLoad([res.data]));
});
