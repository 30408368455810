import { call, delay, fork, put, take } from 'redux-saga/effects';

/**
 * Converts a saga into an action emitting saga, which notifies about its
 * execution state (START, SUCCESS and ERROR action types).
 *
 * Actions which are emitted are prefixed by the action which initiated the
 * saga, e.g. if it received 'FETCH_USERS', it will emit a 'FETCH_USERS_START'
 * action when it starts, and 'FETCH_USERS_SUCCESS' when it ends.
 *
 * If action type prefix can't be determined (for example when manually
 * calling a saga), you can set it using a "typeHint" option.
 *
 * Example:
 * const saga = actionEmittingSaga(function* (action) {
 *   ...
 * }, {
 *   typeHint: 'FETCH_USERS',
 * });
 */
export const actionEmittingSaga = (saga, options = {}) => function* (...args) {
  const { typeHint } = options;
  // Deconstruct args
  const [action] = args;
  const { type = typeHint, payload } = action || {};
  if (!type) {
    throw new Error('Could not determine action type.');
  }
  // Create an action type for each state
  const startType = `${type}_START`;
  const successType = `${type}_SUCCESS`;
  const errorType = `${type}_ERROR`;
  // Start
  yield put({
    type: startType,
    payload,
  });
  try {
    // Run saga
    const result = yield saga(...args);
    // Success
    yield put({
      type: successType,
      payload: result || payload,
    });
  }
  catch (err) {
    // Emit an Axios response payload
    if (err.response) {
      yield put({
        type: errorType,
        payload: err.response.data,
      });
    }
    // Emit plain error
    else {
      yield put({
        type: errorType,
        payload: {
          name: err.name,
          code: err.code,
          message: err.message,
          stack: err.stack,
        },
      });
      // Rethrow
      throw err;
    }
  }
};

/**
 * A wrapper which restarts the saga in case of an unhandled exception.
 */
export const supervisorSaga = saga => function* (...args) {
  const MAX_ATTEMPTS = 3;
  console.debug(`supervisor: starting ${saga.name}`);
  for (let i = 0; i < MAX_ATTEMPTS; i++) {
    if (i > 0) {
      yield delay(1000);
      console.warn(`supervisor: restarting ${saga.name}`);
    }
    try {
      yield saga(...args);
      return;
    }
    catch (err) {
      console.error(`supervisor: ${saga.name}:`, err);
      continue;
    }
  }
  console.warn(`supervisor: ${saga.name}: out of restart attempts, exiting...`);
};

/**
 * Take only the first message matching the pattern, and ignore any further
 * messages.
 */
export const takeFirst = (pattern, saga) => fork(function* () {
  const action = yield take(pattern);
  yield call(saga, action);
});
