import { useState } from 'react';

//  Data mappers
// --------------------------------------------------------

export const defaultMapper = {
  encode: value => value,
  decode: value => value,
};

export const integerMaskMapper = bit => {
  const mask = 1 << (bit-1);
  return {
    encode: value => !!(value & mask),
    decode: value => value
      ? value | mask
      : value & ~mask,
  };
};

export const fieldMappers = {
  default: defaultMapper,
  integerMask: integerMaskMapper,
};


//  Semantic UI fields
// --------------------------------------------------------

const suiInputFieldProps = stateHook => (name, mapper = defaultMapper) => {
  const [fields, setFields] = stateHook;
  return {
    value: mapper.decode(fields[name]),
    onChange: (e, data) => setFields({
      ...fields,
      [name]: mapper.encode(data.value),
    }),
  };
};

const suiCheckboxFieldProps = stateHook => (name, mapper = defaultMapper) => {
  const [fields, setFields] = stateHook;
  return {
    checked: mapper.decode(fields[name]),
    onChange: (e, data) => setFields({
      ...fields,
      [name]: mapper.encode(data.checked),
    }),
  };
};


//  Hook itself
// --------------------------------------------------------

/**
 * React hook which helps in building forms.
 */
export const useForm = initialFields => {
  const stateHook = useState(initialFields);
  const [fields, setFields] = stateHook;

  const fieldProps = {
    suiInput: suiInputFieldProps(stateHook),
    suiCheckbox: suiCheckboxFieldProps(stateHook),
  };

  return [fields, setFields, fieldProps];
};
