import produce from 'immer';
import { set } from 'lodash';

const initialState = {
  criteria: {
    sex: {},
    roomType: {},
    faculty: {},
  },
};

export const issueQueueReducer = produce((state, action) => {
  const { type, payload } = action;

  if (type === 'issueQueue/CRITERIA_SET') {
    const { path, value } = payload;
    set(state.criteria, path, value);
  }

  if (type === 'issueQueue/LOAD_STATISTICS_SUCCESS') {
    state.statistics = payload;
  }
}, initialState);
