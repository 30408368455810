import { uniq, without } from 'lodash/fp';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { actionEmittingSaga } from 'redux-tools/saga';
import { requestApi } from '../api';
import { labelFetchAll, labelLoad, labelUpdate } from './actions';
import { getLabelById, getLabelLoaded } from './selectors';

export const labelSaga = function* () {
  yield all([
    takeLatest('auth/READY', function* () {
      yield put(labelFetchAll());
    }),
    takeLatest('label/FETCH_ALL', labelFetchAllSaga),
    takeLatest('label/CREATE', labelCreateSaga),
    takeLatest('label/UPDATE', labelUpdateSaga),
    takeLatest('label/CHANGE_NAME', labelChangeNameSaga),
    takeLatest('label/CHANGE_COLOR', labelChangeColorSaga),
    takeLatest('label/DELETE', labelDeleteSaga),
    takeLatest('label/ADD_ISSUES', labelAddIssuesSaga),
    takeLatest('label/REMOVE_ISSUES', labelRemoveIssuesSaga),
  ]);
};

export const labelFetchAllSaga = actionEmittingSaga(function* () {
  const loaded = yield select(getLabelLoaded);
  if (loaded) {
    return;
  }
  const res = yield call(requestApi.get, '/label');
  yield put(labelLoad(res.data));
});

export const labelCreateSaga = actionEmittingSaga(function* (action) {
  const { name } = action.payload;
  const res = yield call(requestApi.post, '/label', { name });
  yield put(labelLoad([res.data]));
});

export const labelUpdateSaga = actionEmittingSaga(function* (action) {
  const res = yield call(requestApi.post, '/label', action.payload);
  yield put(labelLoad([res.data]));
});

export const labelChangeNameSaga = function* (action) {
  const { labelId, name } = action.payload;
  const label = yield select(getLabelById(labelId));
  yield put(labelUpdate({ ...label, name }));
};

export const labelChangeColorSaga = function* (action) {
  const { labelId, color } = action.payload;
  const label = yield select(getLabelById(labelId));
  yield put(labelUpdate({ ...label, color }));
};

export const labelDeleteSaga = actionEmittingSaga(function* (action) {
  const { labelId } = action.payload;
  yield call(requestApi.post, `/label/${labelId}/delete`);
});

export const labelAddIssuesSaga = actionEmittingSaga(function* (action) {
  const { labelId, issueIds } = action.payload;
  const label = yield select(getLabelById(labelId));
  yield put(labelUpdate({
    ...label,
    issues: uniq([...label.issues, ...issueIds]),
  }));
});

export const labelRemoveIssuesSaga = actionEmittingSaga(function* (action) {
  const { labelId, issueIds } = action.payload;
  const label = yield select(getLabelById(labelId));
  yield put(labelUpdate({
    ...label,
    issues: without(issueIds)(label.issues),
  }));
});
