export const labelFetchAll = () => ({
  type: 'label/FETCH_ALL',
});

export const labelSave = label => ({
  type: 'label/SAVE',
  payload: label,
});

export const labelLoad = labels => ({
  type: 'label/LOAD',
  payload: { labels },
});

export const labelCreate = name => ({
  type: 'label/CREATE',
  payload: { name },
});

export const labelUpdate = label => ({
  type: 'label/UPDATE',
  payload: label,
});

export const labelDelete = labelId => ({
  type: 'label/DELETE',
  payload: { labelId },
});

export const labelChangeName = (labelId, name) => ({
  type: 'label/CHANGE_NAME',
  payload: { labelId, name },
});

export const labelChangeColor = (labelId, color) => ({
  type: 'label/CHANGE_COLOR',
  payload: { labelId, color },
});

export const labelAddIssues = (labelId, issueIds) => ({
  type: 'label/ADD_ISSUES',
  payload: { labelId, issueIds },
});

export const labelRemoveIssues = (labelId, issueIds) => ({
  type: 'label/REMOVE_ISSUES',
  payload: { labelId, issueIds },
});
