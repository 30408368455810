import { flow } from 'functional';
import produce from 'immer';
import { filter, sortBy } from 'lodash/fp';
import { parsePersonalCode } from 'personal-code-tools';
import { createIndexer } from 'redux-tools/gendb';
import { testGlobPattern } from 'string-tools';
import { routerActionTypes } from '../router';

const DERIVED_FIELDS = {
  disclosed: issue => (
    issue.type === 'freshmen'
    || issue.type === 'queue' && !!issue.queuedAt
  ),
  unconfirmed: issue => (
    issue.status === 'unconfirmed'
  ),
  pending: issue => (
    issue.status === 'pending'
    || issue.status === 'queued'
  ),
  queued: issue => (
    issue.type === 'queue'
    && issue.status === 'queued'
  ),
  dequeued: issue => (
    issue.type === 'queue'
    && !!issue.invitedAt
  ),
  resolved: issue => (
    issue.status === 'completed'
  ),
  rejected: issue => (
    issue.status === 'cancelled'
    || issue.status === 'expired'
    || issue.status === 'void'
  ),
};

const computeDerivedFields = prevIssue => {
  const issue = { ...prevIssue };
  // Rudimentary derivations
  issue.fullName = [issue.firstName, issue.lastName]
    .filter(x => !!x)
    .join(' ')
    .trim()
    || null;
  issue.displayName = issue.fullName || issue.email;
  // Compute derived state from status
  for (let key of Object.keys(DERIVED_FIELDS)) {
    const fn = DERIVED_FIELDS[key];
    issue[key] = fn(issue);
  }
  // Compute derived state from personal code
  if (issue.personalCode) {
    const { sex, dateOfBirth, age } = parsePersonalCode(issue.personalCode);
    Object.assign(issue, { sex, dateOfBirth, age });
  }
  // Detect discrepancies
  let hasDiscrepancies = false;
  const discrepancy = {};
  if (Number.isNaN(issue.age)) {
    hasDiscrepancies = true;
    const msg = `Neteisinga gimimo data (${issue.dateOfBirth})`;
    discrepancy.personalCode = msg;
    discrepancy.dateOfBirth = msg;
  }
  if (issue.studyCycle === 2 && issue.age < 21) {
    hasDiscrepancies = true;
    const msg = `Neteisinga studijų pakopa? (Studento amžius: ${issue.age})`;
    discrepancy.studyCycle = msg;
    discrepancy.age = msg;
  }
  if (issue.studyCycle === 3 && issue.age < 23) {
    hasDiscrepancies = true;
    const msg = `Neteisinga studijų pakopa? (Studento amžius: ${issue.age})`;
    discrepancy.studyCycle = msg;
    discrepancy.age = msg;
  }
  if (hasDiscrepancies) {
    Object.assign(issue, { discrepancy });
  }
  // Return the result
  return issue;
};

const { addMany } = createIndexer({
  collections: {
    all: flow([
      sortBy(issue => -issue.seqId),
    ]),
    dequeued: flow([
      filter(issue => issue.dequeued),
      filter(issue => !issue.resolved && !issue.rejected),
      sortBy(issue => issue.seqId),
    ]),
  },
});

export const issueReducer = produce((state, action) => {
  const { type, payload } = action;

  if (type === 'issue/LOAD') {
    const { issues } = payload;
    return addMany(state, issues.map(computeDerivedFields));
  }

  if (testGlobPattern('issue/FETCH*_START')(type)) {
    state.loading = true;
  }

  if (testGlobPattern('issue/FETCH*_SUCCESS')(type)) {
    state.loading = false;
  }

  if (testGlobPattern('issue/FETCH*_ERROR')(type)) {
    state.loading = false;
  }

  if (type === 'issue/FETCH_ALL_START') {
    state.loadedAll = false;
  }

  if (type === 'issue/FETCH_ALL_SUCCESS') {
    state.loadedAll = true;
  }

  if (type === 'issue/UPDATE_ERROR') {
    state.form.error = payload;
  }

  if (type === routerActionTypes.TRANSITION_SUCCESS) {
    state.form = {};
    state.confirmationToken = {
      sending: false,
      sent: false,
    };
  }

  if (type === 'issue/SEND_CONFIRMATION_TOKEN_START') {
    state.confirmationToken.sending = true;
  }

  if (type === 'issue/SEND_CONFIRMATION_TOKEN_SUCCESS') {
    state.confirmationToken.sending = false;
    state.confirmationToken.sent = true;
  }

  if (type === 'issue/SEND_CONFIRMATION_TOKEN_ERROR') {
    state.confirmationToken.sending = false;
    state.confirmationToken.sent = false;
  }
}, {
  byId: {},
  all: [],
  loading: false,
  loadedAll: false,
  dequeued: [],
  form: {},
  confirmationToken: {
    sending: false,
    sent: false,
  },
});
