import { values } from 'lodash/fp';

export const createIndexer = ({ indexes = {}, collections = {} }) => {
  // Primary index
  if (!indexes.id) {
    indexes.id = item => item.id;
  }
  // Primary collection
  if (!collections.all) {
    collections.all = items => items;
  }
  const indexer = { indexes, collections };
  return {
    ...indexer,
    addMany: addMany(indexer),
    addOne: addOne(indexer),
    removeOne: removeOne(indexer),
  };
};

const copyState = state => ({
  ...state,
  byId: {
    ...state.byId,
  },
});

const mutablyReindex = (indexer, stateMut) => {
  const { indexes, collections } = indexer;
  const allItems = values(stateMut.byId);
  for (let collName of Object.keys(collections)) {
    const collectionMapper = collections[collName];
    stateMut[collName] = collectionMapper(allItems).map(indexes.id);
  }
};

const addOne = indexer => (state, item) => {
  const { indexes } = indexer;
  const stateMut = copyState(state);
  const id = indexes.id(item);
  stateMut.byId[id] = item;
  mutablyReindex(indexer, stateMut);
  return stateMut;
};

const addMany = indexer => (state, items) => {
  const { indexes } = indexer;
  const stateMut = copyState(state);
  for (let item of items) {
    const id = indexes.id(item);
    stateMut.byId[id] = item;
  }
  mutablyReindex(indexer, stateMut);
  return stateMut;
};

const removeOne = indexer => (state, id) => {
  const stateMut = copyState(state);
  delete stateMut.byId[id];
  mutablyReindex(indexer, stateMut);
  return stateMut;
};
