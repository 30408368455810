import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { classes } from 'react-tools';
import { Dropdown } from 'semantic-ui-react';
import { logout } from './auth/actions';
import { LoginPage } from './auth/LoginPage';
import { getAuthUser } from './auth/selectors';
import { CampusEditPage } from './campus/CampusEditPage';
import { CampusIndexPage } from './campus/CampusIndexPage';
import { CandidatesPage } from './candidate/CandidatesPage';
import { InternationalCandidatesPage } from './candidate/InternationalCandidatesPage';
import { Box, Flex, Loader } from './components';
import { FacultyEditPage } from './faculty/FacultyEditPage';
import { FacultyIndexPage } from './faculty/FacultyIndexPage';
import { FreshmenLogPage } from './freshmen/FreshmenLogPage';
import { FreshmenPage } from './freshmen/FreshmenPage';
import { FreshmenStatisticsPage } from './freshmen/FreshmenStatisticsPage';
import { InternationalLogPage } from './international/InternationalLogPage';
import { InternationalPage } from './international/InternationalPage';
import { InternationalStatisticsPage } from './international/InternationalStatisticsPage';
import { IssueEditPage } from './issue/IssueEditPage';
import { IssueIndexPage } from './issue/IssueIndexPage';
import { IssueLogPage } from './issue/IssueLogPage';
import { IssueShowPage } from './issue/IssueShowPage';
import { IssueQueueDashboardPage } from './issueQueue/IssueQueueDashboardPage';
import { IssueQueueInvitePage } from './issueQueue/IssueQueueInvitePage';
import { IssueQueueLogPage } from './issueQueue/IssueQueueLogPage';
import { IssueQueueStatisticsPage } from './issueQueue/IssueQueueStatisticsPage';
import { LabelIndexPage } from './label/LabelIndexPage';
import { getRoute, Link } from './router';
import { SafeModeToggle } from './safeMode';
import { SettingsPage } from './settings/SettingsPage';
import { UserEditPage } from './user/UserEditPage';
import { UserIndexPage } from './user/UserIndexPage';

const routedPageComponents = {
  campusCreate: CampusEditPage,
  campusIndex: CampusIndexPage,
  campusShow: CampusEditPage,
  facultyIndex: FacultyIndexPage,
  facultyShow: FacultyEditPage,
  freshmen: FreshmenPage,
  freshmenCandidates: CandidatesPage,
  freshmenLog: FreshmenLogPage,
  freshmenStatistics: FreshmenStatisticsPage,
  international: InternationalPage,
  internationalCandidates: InternationalCandidatesPage,
  internationalLog: InternationalLogPage,
  internationalStatistics: InternationalStatisticsPage,
  issueEdit: IssueEditPage,
  issueIndex: IssueIndexPage,
  issueLog: IssueLogPage,
  issueQueueDashboard: IssueQueueDashboardPage,
  issueQueueInvite: IssueQueueInvitePage,
  issueQueueLog: IssueQueueLogPage,
  issueQueueStatistics: IssueQueueStatisticsPage,
  issueShow: IssueShowPage,
  labelIndex: LabelIndexPage,
  login: LoginPage,
  settings: SettingsPage,
  userCreate: UserEditPage,
  userIndex: UserIndexPage,
  userShow: UserEditPage,
};

const getRoutedPage = route => {
  if (!route) {
    return <Loader />;
  }
  const { name, params } = route;
  const Page = routedPageComponents[name];
  if (Page) {
    return <Page {...params} />;
  }
  return null;
};

export const Layout = props => {
  const dispatch = useDispatch();
  const user = useSelector(getAuthUser);
  const route = useSelector(getRoute);
  const routedPage = getRoutedPage(route);
  return (
    <div className="Layout">
      <div className="Layout__Y-navbar Navbar">
        <div className="Layout__X-side">
          <Flex
            alignItems="center"
            height="100%">
            <Flex.Item m={2}>
              <Link className="Navbar__item Navbar__brand"
                routeName="issueQueueDashboard">
                Dashboard
              </Link>
            </Flex.Item>
          </Flex>
        </div>
        <div className="Layout__X-main">
          <Flex
            className="Layout__container"
            alignItems="center"
            height="100%">
            <Flex.Item grow={1} />
            {user && user.role === 'administrator' && (
              <SafeModeToggle />
            )}
            {user && (
              <Flex.Item m={2}>
                <Dropdown className="Navbar__item"
                  text={user.displayName}>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      text="Logout"
                      onClick={() => dispatch(logout())}
                    />
                  </Dropdown.Menu>
                </Dropdown>
              </Flex.Item>
            )}
          </Flex>
        </div>
      </div>
      <div className="Layout__Y-main">
        {user && (
          <Fragment>
            <div className="Layout__X-side Sidebar">
              {(
                user.role === 'administrator' ||
                user.scope.queues.includes('queue')
              ) &&
                <Fragment>
                  <SidebarItem
                    routeName="issueQueueDashboard"
                    text="Bendra eilė" />
                  <SidebarItem indent={1}
                    routeName="issueQueueInvite"
                    text="Kviesti"
                    icon="plus" />
                </Fragment>
              }
              {/*
              <SidebarItem indent={1}
                routeName="issueQueueStatistics"
                text="Statistika"
                icon="chart line" />
              <SidebarItem indent={1}
                routeName="issueQueueLog"
                text="Veiksmų žurnalas"
                icon="list alternate outline" />
              */}
              {(
                user.role === 'administrator' ||
                user.scope.queues.includes('freshmen')
              ) &&
                <Fragment>
                  <SidebarItem
                    routeName="freshmen"
                    text="Pirmakursiai" />
                  <SidebarItem indent={1}
                    routeName="freshmenCandidates"
                    text="Sąrašai"
                    icon="file outline" />
                  <SidebarItem indent={1}
                    routeName="freshmenStatistics"
                    text="Statistika"
                    icon="chart line" />
                  <SidebarItem indent={1}
                    routeName="freshmenLog"
                    text="Veiksmų žurnalas"
                    icon="list alternate outline" />
                </Fragment>
              }
              {(
                user.role === 'administrator' ||
                user.scope.queues.includes('international')
              ) &&
                <Fragment>
                  <SidebarItem
                    routeName="international"
                    text="Tarptautiniai" />
                  <SidebarItem indent={1}
                    routeName="freshmenCandidates"
                    text="Sąrašai"
                    icon="file outline" />
                  <SidebarItem indent={1}
                    routeName="internationalStatistics"
                    text="Statistika"
                    icon="chart line" />
                  <SidebarItem indent={1}
                    routeName="internationalLog"
                    text="Veiksmų žurnalas"
                    icon="list alternate outline" />
                </Fragment>
              }
              {user.role === 'administrator' && (
                <SidebarItem
                  routeName="issueIndex"
                  text="Visos registracijos" />
              )}
              {user.role === 'administrator' && (
                <SidebarItem indent={1}
                  routeName="issueLog"
                  text="Veiksmų žurnalas"
                  icon="list alternate outline" />
              )}
              <SidebarItem
                routeName="settings"
                text="Nustatymai" />
              {user.role === 'administrator' && (
                <SidebarItem indent={1}
                  routeName="userIndex"
                  text="Vartotojai"
                  icon="user" />
              )}
              <SidebarItem indent={1}
                routeName="campusIndex"
                text="Miesteliai"
                icon="building" />
              {user.role === 'administrator' && (
                <SidebarItem indent={1}
                  routeName="facultyIndex"
                  text="Fakultetai"
                  icon="graduation" />
              )}
              {user.role === 'administrator' && (
                <SidebarItem indent={1}
                  routeName="labelIndex"
                  text="Žymos"
                  icon="tag" />
              )}
            </div>
            <div className="Layout__X-main">
              <Box m={2}>
                <div className="Layout__container">
                  {routedPage}
                </div>
              </Box>
            </div>
          </Fragment>
        ) || (
            <Fragment>
              {routedPage}
            </Fragment>
          )}
      </div>
    </div>
  );
}

const SidebarItem = props => {
  const { indent, text, icon, routeName, children, ...rest } = props;
  const route = useSelector(getRoute);
  const activeRouteName = route && route.name;
  const routeActive = activeRouteName === routeName;
  return (
    <Fragment>
      <Link {...rest}
        className={classes('Sidebar__item', {
          'Sidebar__item--active': routeActive,
        })}
        style={{
          borderLeftWidth: indent && `${indent}rem`,
        }}
        routeName={routeName}>
        <Flex>
          {icon !== undefined && (
            <Flex.Item opacity={0.4} mr={1}>
              <i className={`icon ${icon}`} />
            </Flex.Item>
          )}
          <Flex.Item grow={1}>
            {text}
          </Flex.Item>
        </Flex>
      </Link>
    </Fragment>
  );
};
