import CSV from 'csv';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { actionEmittingSaga } from 'redux-tools/saga';
import { requestApi } from '../api';
import { serveTextAsFile } from '../file';
import { issueLoad } from '../issue/actions';
import { isTransitionTo } from '../router';
import { internationalFetchAll } from './actions';
import { getFilteredInternationalIssues, getInternationalLoaded } from './selectors';

export const internationalSaga = function* () {
  yield all([
    takeLatest([
      isTransitionTo('international'),
      isTransitionTo('internationalStatistics'),
    ], function* () {
      const loaded = yield select(getInternationalLoaded);
      if (loaded) {
        // Skip loading
        return;
      }
      yield put(internationalFetchAll());
    }),
    takeLatest('international/FETCH', internationalFetchSaga),
    takeLatest('international/DELETE_ALL', internationalDeleteAllSaga),
    takeLatest('international/CLOSE_ISSUES', internationalCloseIssuesSaga),
    takeLatest('international/EXPORT', internationalExportSaga),
  ]);
};

const internationalFetchSaga = actionEmittingSaga(function* () {
  const res = yield call(requestApi.get,
    '/application?dashboard=true&type=international');
  yield put(issueLoad(res.data));
});

const internationalDeleteAllSaga = function* () {
  const challenge = yield call(window.prompt,
    'THIS IS A DANGEROUS OPERATION!\n\n'
    + 'All registrations will be permanently deleted and there is no way back.\n'
    + 'Make sure you have backups before doing this.\n\n'
    + 'Please type "delete all international" to confirm this action.');
  if (challenge !== 'delete all international') {
    return;
  }
  console.debug('Expiring...');
  const res = yield call(requestApi.post, '/application/delete_all/international');
  console.debug('OK', res);
  location.reload();
};

const internationalCloseIssuesSaga = actionEmittingSaga(function* (action) {
  const confirmed = yield call(window.confirm, 'Are you sure?');
  if (!confirmed) {
    return;
  }
  const { issueIds } = action.payload;
  console.debug('Closing issues...', issueIds);
  // const issues = yield select(state => issueIds
  //   .map(id => getIssueById(id)(state)));
  const res = yield call(requestApi.post,
    '/application/close_applications',
    {
      applications: issueIds,
    });
  console.debug('OK', res);
  yield put(internationalFetchAll({ force: true }));
});

const internationalExportSaga = function* () {
  const issues = yield select(getFilteredInternationalIssues);
  if (!issues || issues.length === 0) {
    yield call(window.alert, 'Nothing to export!');
    return;
  }
  // Build CSV
  const header = [
    'Vardas',
    'Pavardė',
    'El. paštas',
    'Fakultetas',
    'Studijų pakopa',
    'Lytis',
  ];
  const data = issues.map(x => [
    x.firstName,
    x.lastName,
    x.email,
    x.faculty && x.faculty.name,
    x.studyCycle,
    x.sex === 'female' ? 'M' : 'V',
  ]);
  data.unshift(header);
  const csv = new CSV(data);
  // Serve the file
  yield call(serveTextAsFile, 'international.export.csv', csv.encode());
};
