import * as actionTypes from './actionTypes';

export { actionTypes };
export * from './router';

export const COLORS = [
  'red',
  'orange',
  'yellow',
  'olive',
  'green',
  'teal',
  'blue',
  'violet',
  'purple',
  'pink',
  'brown',
  'grey',
  'black',
];

export const ROOM_TYPES = [
  {
    id: undefined,
    name: 'Nesvarbu',
  },
  {
    id: 'double',
    name: 'Dvivietis',
  },
  {
    id: 'double_1',
    name: 'Dvivietis (visas)',
  },
  {
    id: 'triple',
    name: 'Trivietis',
  },
  {
    id: 'triple_2',
    name: 'Trivietis (dviem)',
  },
  {
    id: 'triple_1',
    name: 'Trivietis (visas)',
  },
];

export const SEXES = [
  {
    id: 'male',
    name: 'Vyras',
  },
  {
    id: 'female',
    name: 'Moteris',
  },
];

export const STUDY_CYCLES = [
  {
    id: undefined,
    name: 'Visos pakopos',
  },
  {
    id: 1,
    name: 'Bakalaurai',
  },
  {
    id: 2,
    name: 'Magistrai',
  },
  {
    id: 3,
    name: 'Doktorantai',
  },
  {
    id: 4,
    name: 'Vientisos',
  },
];

/**
 * Explanation for some of the derived states:
 *
 * disclosed - issue can be (or was) disclosed to superintendents
 * queued - issue is inside the queue
 * dequeued - issue was been processed by the queue
 */
export const STATUSES = [
  // Normal status flow
  {
    id: 'unconfirmed',
    name: 'nepatvirtintas',
  },
  {
    id: 'pending',
    name: 'patvirtintas',
  },
  {
    id: 'queued',
    name: 'eilėje',
  },
  {
    id: 'invited',
    name: 'pakviestas',
  },
  {
    id: 'accepted',
    name: 'atvykstantis',
  },
  {
    id: 'completed',
    name: 'apgyvendintas',
  },
  // Special statuses
  {
    id: 'cancelled',
    name: 'atšauktas',
  },
  {
    id: 'expired',
    name: 'atmesta',
  },
  {
    id: 'void',
    name: 'anuliuotas',
  },
];

export const QUEUES = [
  {
    id: 'queue',
    name: 'Bendra eilė',
  },
  {
    id: 'freshmen',
    name: 'Pirmakursiai',
  },
  {
    id: 'international',
    name: 'Tarptautiniai',
  }
];
