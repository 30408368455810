import { concat, uniq, without } from 'lodash';
import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Divider, Form, Grid, Message } from 'semantic-ui-react';
import { Flex, Loader } from '../components';
import { getFaculties } from '../faculty/selectors';
import { getCampusById, getCampusForm } from './selectors';

export const CampusEditPage = props => {
  const { campusId } = props;
  const editing = !!campusId;
  const campus = useSelector(getCampusById(campusId));
  const campusForm = useSelector(getCampusForm);
  const faculties = useSelector(getFaculties);
  const dispatch = useDispatch();
  if (!faculties) {
    return <Loader />;
  }
  const [fields, setFields] = useState({
    name: campus && campus.name || '',
    abbr: campus && campus.abbr || '',
    email: campus && campus.email || '',
    address: campus && campus.address || '',
    mapUrl: campus && campus.mapUrl || '',
    info: campus && campus.info || '',
    faculties: campus && campus.faculties || [],
    studyCycleMask: campus && campus.studyCycleMask || 0,
    roomTypeEnabled: campus && campus.roomTypeEnabled || false,
    forcePcValidation: campus && campus.forcePcValidation || false,
  });
  const inputFieldProps = name => ({
    value: fields[name],
    onChange: (e, data) => setFields({
      ...fields,
      [name]: data.value,
    }),
  });
  const booleanCheckboxFieldProps = name => ({
    checked: fields[name],
    onChange: (e, data) => setFields({
      ...fields,
      [name]: data.checked,
    }),
  });
  const integerMaskCheckboxFieldProps = (name, bit) => ({
    checked: !!(fields[name] & (1 << (bit-1))),
    onChange: (e, data) => setFields({
      ...fields,
      [name]: data.checked
        ? fields[name] | (1 << (bit-1))
        : fields[name] & ~(1 << (bit-1)),
    }),
  });
  return (
    <Fragment>
      <Flex m={-2} mb={0}>
        <Flex.Item m={2}>
          <Button icon="left arrow"
            onClick={() => history.back()} />
        </Flex.Item>
        <Flex.Item m={2} grow={2}>
          <h1>Miestelis</h1>
        </Flex.Item>
      </Flex>
      {campusForm.error && (
        <Message error content={campusForm.message} />
      )}
      <Form
        onSubmit={() => {
          dispatch({
            type: `campus/${editing ? 'UPDATE' : 'CREATE'}`,
            payload: {
              campusId,
              fields,
            },
          });
        }}>
        <Form.Input
          type="text"
          label="Pavadinimas"
          {...inputFieldProps('name')} />
        <Form.Input
          type="text"
          label="Sutrumpinimas"
          {...inputFieldProps('abbr')} />
        <Form.Input
          type="text"
          label="El. paštas"
          {...inputFieldProps('email')} />
        <Form.Input
          type="text"
          label="Adresas"
          {...inputFieldProps('address')} />
        <Form.Input
          type="text"
          label="Nuoroda i Google Maps"
          {...inputFieldProps('mapUrl')} />
        <Form.TextArea
          rows={6}
          label="Kontaktai"
          {...inputFieldProps('info')} />
        <Divider />
        <Grid columns={8}>
          {faculties.map(faculty => (
            <Grid.Column key={String(faculty.id)}>
              <Form.Checkbox
                key={faculty.id}
                label={faculty.abbr}
                checked={fields.faculties.includes(faculty.id)}
                onChange={(e, data) => {
                  if (data.checked) {
                    setFields({
                      ...fields,
                      faculties: uniq(concat(fields.faculties, faculty.id)),
                    });
                  }
                  else {
                    setFields({
                      ...fields,
                      faculties: without(fields.faculties, faculty.id),
                    });
                  }
                }} />
            </Grid.Column>
          ))}
        </Grid>
        <Divider />
        <Form.Checkbox
          label="Leisti registruotis bakalaurams"
          {...integerMaskCheckboxFieldProps('studyCycleMask', 1)} />
        <Form.Checkbox
          label="Leisti registruotis magistrams"
          {...integerMaskCheckboxFieldProps('studyCycleMask', 2)} />
        <Form.Checkbox
          label="Leisti registruotis doktorantams ir rezidentams"
          {...integerMaskCheckboxFieldProps('studyCycleMask', 3)} />
        <Form.Checkbox
          label="Leisti registruotis vientisų studijų studentams"
          {...integerMaskCheckboxFieldProps('studyCycleMask', 4)} />
        <Divider />
        <Form.Checkbox
          label="Leisti pasirinkti kambario tipą"
          {...booleanCheckboxFieldProps('roomTypeEnabled')} />
        <Form.Checkbox
          label="Leisti pasirinkti kambario tipą"
          {...booleanCheckboxFieldProps('forcePcValidation')} />
        <Form.Button content="Išsaugoti" />
      </Form>
    </Fragment>
  );
};
