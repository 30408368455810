export const userLoad = users => ({
  type: 'user/LOAD',
  payload: { users },
});

export const userFetchAll = () => ({
  type: 'user/FETCH_ALL',
});

export const userFetchMany = userIds => ({
  type: 'user/FETCH_MANY',
  payload: { userIds },
});
