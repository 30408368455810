import { compact } from 'lodash';
import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Label as SuiLabel } from 'semantic-ui-react';
import { Box, Flex, LabeledList, Loader } from '../components';
import { formatDateTime, formatIssueType, formatRoom, formatStatus, formatYesNo } from '../formatters';
import { IssueLogTable } from '../issueLog/IssueLogTable';
import { findIssueLogEntries } from '../issueLog/selectors';
import { Label } from '../label';
import { getLabelsByIssueId } from '../label/selectors';
import { Link } from '../router';
import { getSafeMode } from '../safeMode';
import { issueFileDelete, issueReject, issueResolve } from './actions';
import { getIssueById, getIssueFiles } from './selectors';

export const IssueShowPage = props => {
  const { issueId } = props;
  const issue = useSelector(getIssueById(issueId));
  const labels = useSelector(getLabelsByIssueId(issueId));
  const files = useSelector(getIssueFiles(issueId));
  const changeLog = useSelector(findIssueLogEntries({ issueId }));
  const confirmationToken = useSelector(
    state => state.issue.confirmationToken);
  const safeMode = useSelector(getSafeMode);
  const dispatch = useDispatch();
  if (!issue) {
    return <Loader />;
  }
  const status = issue.status;
  const icons = compact([
    issue.handicapped && (
      <i key="handicapped"
        className="icon big blue wheelchair"
        title="Neįgalusis" />
    ),
    issue.discrepancy && (
      <i key="discrepancy"
        className="icon big yellow warning sign"
        title="Yra neatitinkančių duomenų" />
    ),
  ]);
  return (
    <Fragment>
      <Box mb={2}>
        <Flex alignItems="center" m={-2}>
          <Flex.Item m={2}>
            <Button icon="left arrow"
              onClick={() => history.back()} />
          </Flex.Item>
          <Flex.Item m={2}>
            <h1>Prašymas #{issue.seqId}</h1>
          </Flex.Item>
          {!safeMode && (
            <Flex.Item m={2}>
              <Button as={Link}
                routeName="issueEdit"
                routeParams={{ issueId }}
                icon="pencil"
                color="yellow" />
            </Flex.Item>
          )}
          <Flex.Item m={2}>
            <SuiLabel content={formatIssueType(issue.type)} />
            <SuiLabel
              color={issue.status === 'void' && 'red'
                || issue.status === 'completed' && 'green'
                || issue.status === 'cancelled' && 'yellow'
                || issue.status === 'expired' && 'yellow'
                || 'blue'}
              content={formatStatus(status)} />
          </Flex.Item>
          {icons.length > 0 && (
            <Flex.Item m={2} children={icons} />
          )}
          <Flex.Item grow={1} />
          {status === 'accepted' && (
            <Flex.Item m={2}>
              <Button compact
                color="red"
                content="Atšaukti"
                onClick={() => dispatch(issueReject(issueId))} />
              <Button compact
                color="green"
                content="Apgyvendinti"
                onClick={() => dispatch(issueResolve(issueId))} />
            </Flex.Item>
          )}
        </Flex>
      </Box>
      <Box mb={2}>
        <LabeledList>
          <LabeledList.Item label="Vardas"
            content={issue.firstName} />
          <LabeledList.Item label="Pavardė"
            content={issue.lastName} />
          <LabeledList.Item label="Asmens kodas"
            content={issue.personalCode}
            warning={issue.discrepancy && issue.discrepancy.personalCode} />
          <LabeledList.Item label="Studento kodas"
            content={issue.studentCode}
            optional />
          <LabeledList.Item label="Gimimo data"
            content={issue.dateOfBirth && `${issue.dateOfBirth}`}
            warning={issue.discrepancy && issue.discrepancy.dateOfBirth}
            optional />
          <LabeledList.Item label="Amžius"
            content={issue.age && `${issue.age}`}
            warning={issue.discrepancy && issue.discrepancy.age}
            optional />
          <LabeledList.Item label="El. paštas">
            {issue.email}
            {issue.status === 'unconfirmed' && (
              <Fragment>
                <Button compact size="tiny"
                  content="Išsiųsti patvirtinimo laišką"
                  loading={confirmationToken.sending}
                  style={{ marginLeft: '1rem' }}
                  onClick={() => dispatch({
                    type: 'issue/SEND_CONFIRMATION_TOKEN',
                    payload: {
                      issueId,
                    },
                  })} />
                {confirmationToken.sent && (
                  <i className="icon green checkmark ml-1" />
                )}
              </Fragment>
            )}
          </LabeledList.Item>
          <LabeledList.Item label="Miestelis"
            content={issue.campus && issue.campus.abbr} />
          <LabeledList.Item label="Fakultetas"
            content={issue.faculty && issue.faculty.name} />
          <LabeledList.Item label="Šalis"
            content={issue.country} />
          <LabeledList.Item label="Miestas"
            content={issue.city} />
          <LabeledList.Item label="Studijų pakopa"
            content={issue.studyCycle}
            warning={issue.discrepancy && issue.discrepancy.studyCycle}
            optional />
          <LabeledList.Item label="Kambario tipas"
            content={formatRoom(issue.roomType)} />
          <LabeledList.Item label="Apgyvendintas"
            content={formatYesNo(issue.accommodated)} />
          <LabeledList.Item label="Socialiai remtinas"
            content={formatYesNo(issue.disadvantaged)} />
          <LabeledList.Item label="Neįgalusis"
            content={formatYesNo(issue.handicapped)} />
          <LabeledList.Item label="Pageidavimai"
            content={issue.notes && (
              <div style={{ whiteSpace: 'pre-wrap' }}>
                {issue.notes}
              </div>
            )} />
          <LabeledList.Item label="Žymos">
            {labels.map(label => (
              <Label color={label.color} value={label.name} />
            ))}
            {labels.length === 0 && '—'}
          </LabeledList.Item>
          {issue.queued && (
            <Fragment>
              <LabeledList.Item label="Vieta eilėje">
                <div className="ui horizontal statistic">
                  <div className="value">
                    {issue.position}
                  </div>
                  &nbsp;iš {issue.queueSize}
                </div>
              </LabeledList.Item>
              <LabeledList.Item label="Pagal miestelį">
                <div className="ui horizontal statistic small">
                  <div className="value">
                    {issue.positionBroad}
                  </div>
                  &nbsp;iš {issue.queueSizeBroad}
                </div>
              </LabeledList.Item>
            </Fragment>
          )}
          <LabeledList.Item label="Sukūrimo data"
            content={formatDateTime(issue.createdAt)} />
          <LabeledList.Item label="Eilėje nuo"
            content={formatDateTime(issue.queuedAt)}
            optional />
          <LabeledList.Item label="Kvietimo data"
            content={formatDateTime(issue.invitedAt)}
            optional />
          <LabeledList.Item label="Patvirtinimo data"
            content={formatDateTime(issue.acceptedAt)}
            optional />
          <LabeledList.Item label="Atšaukimo data"
            content={formatDateTime(issue.cancelledAt)}
            optional />
        </LabeledList>
      </Box>
      <Box mb={2}>
        <Flex mb={2}>
          <Flex.Item>
            <h2>Prisegti failai</h2>
          </Flex.Item>
        </Flex>
        {files.map(file => (
          <Flex key={file.id} m={-1} alignItems="center">
            <Flex.Item m={1}>
              <i className="icon file circular fitted" />
            </Flex.Item>
            <Flex.Item m={1} grow={1}>
              <div>
                {file.name}
              </div>
              {file.deleted && (
                <div className="text-bold"
                  style={{ color: 'rgb(170, 34, 0)' }}>
                  Byla pašalinta, priežastis: {file.reason}
                </div>
              )}
            </Flex.Item>
            {!file.deleted && (
              <Flex.Item m={1}>
                <Button as="a" basic compact
                  target="_blank"
                  href={file.url}
                  size="tiny"
                  icon="download"
                  content="Parsisiusti" />
                <Button basic compact
                  size="tiny"
                  icon="remove"
                  content="Ištrinti"
                  onClick={() => {
                    dispatch(issueFileDelete(issueId, file.id));
                  }} />
              </Flex.Item>
            )}
          </Flex>
        ))}
      </Box>
      <Box mb={2}>
        <Flex mb={2}>
          <Flex.Item>
            <h2>Pakeitimai</h2>
          </Flex.Item>
        </Flex>
        <IssueLogTable entries={changeLog} />
      </Box>
    </Fragment>
  );
};
