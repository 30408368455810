import { createRouter } from 'router5';
import browserPlugin from 'router5-plugin-browser';
import { get } from 'lodash';
import { WINDOW_TITLE, ROUTES, ROUTER_OPTIONS } from './constants';

// Instantiate router
export const router = createRouter(ROUTES, ROUTER_OPTIONS);
router.usePlugin(browserPlugin());


//  Actions
// --------------------------------------------------------

import { actions as routerActions } from 'redux-router5';
import { actionTypes as routerActionTypes } from 'redux-router5';

export { routerActions };
export { routerActionTypes };


//  Selectors
// --------------------------------------------------------

export const getRoute = state => get(state, ['router', 'route']);


//  Middleware
// --------------------------------------------------------

import { router5Middleware as _routerMiddleware } from 'redux-router5';

export const routerMiddleware = store => {
  window.router = router;
  const instance = _routerMiddleware(router)(store);

  let routerStarted = false;

  return next => instance(action => {
    const { type, payload } = action;

    // Start router only when the authentication module is ready
    if (type === 'auth/READY' && !routerStarted) {
      routerStarted = true;
      router.start()
    }

    // Set window title on successful transition
    if (type === routerActionTypes.TRANSITION_SUCCESS) {
      const routeName = payload.route.name;
      const routeDef = ROUTES.find(routeDef => routeDef.name === routeName);
      if (routeDef) {
        if (routeDef.title) {
          window.document.title = `${routeDef.title} - ${WINDOW_TITLE}`;
        }
        else {
          window.document.title = WINDOW_TITLE;
        }
      }
    }

    // Scroll to top
    if (type === routerActionTypes.TRANSITION_SUCCESS) {
      window.scrollTo(0, 0);
    }

    // Forward the action
    next(action);
  });
}


//  Reducer
// --------------------------------------------------------

import { router5Reducer as routerReducer } from 'redux-router5';

export { routerReducer };


//  Components
// --------------------------------------------------------

import React from 'react';
import { useDispatch } from 'react-redux';

export const Link = props => {
  const {
    as,
    children,
    content,
    routeName,
    routeParams,
    ...rest
  } = props;
  const ElementType = props.as;
  if (routeName && !rest.href) {
    rest.href = router.buildUrl(routeName, routeParams);
  }
  const dispatch = useDispatch();
  return (
    <ElementType {...rest}
      onClick={e => {
        if (routeName) {
          dispatch(routerActions.navigateTo(routeName, routeParams));
          e.preventDefault();
          return;
        }
      }}>
      {content}
      {children}
    </ElementType>
  );
};

Link.defaultProps = {
  as: 'a',
  role: 'link',
};

//  Helpers
// --------------------------------------------------------

export const isTransitionTo = routeName => action => {
  const { type, payload } = action;
  return type === routerActionTypes.TRANSITION_START
    && payload.route.name === routeName;
};
