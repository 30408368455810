import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { classes } from 'react-tools';
import { Button, Table } from 'semantic-ui-react';
import { getCampusById } from '../campus/selectors.js';
import { getFacultyById } from '../faculty/selectors.js';
import { PaginatedTable } from '../components';
import { formatDateTime, formatUser } from '../formatters';
import { Link } from '../router';
import { ROOM_TYPES, SEXES } from '../constants';

export const IssueLogTable = props => {
  const { entries = [], itemsPerPage = 20 } = props;
  return (
    <PaginatedTable
      collection={entries}
      itemsPerPage={itemsPerPage}
      striped={false}
      renderHeader={() => (
        <Table.Row>
          <Table.HeaderCell content="Laikas" collapsing />
          <Table.HeaderCell content="Vartotojas" collapsing />
          <Table.HeaderCell content="Veiksmas" />
          <Table.HeaderCell collapsing />
        </Table.Row>
      )}
      renderItem={entry => {
        const { type, payload = {}, meta = {}, issue, initiator } = entry;
        const user = initiator;
        const userRole = user && user.role;
        const { status, issueId } = payload;
        return (
          <Table.Row
            key={entry.id}
            positive={status === 'completed'}
            negative={status === 'void'}
            warning={status === 'cancelled' || status === 'expired'}>
            <Table.Cell collapsing>
              {formatDateTime(meta.now)}
            </Table.Cell>
            <Table.Cell collapsing
              className={classes({
                'text-bold': userRole !== 'normal',
                'text-italic': userRole === 'normal',
              })}>
              {formatUser(user)}
            </Table.Cell>
            <Table.Cell>
              <EntryText entry={entry} />
            </Table.Cell>
            <Table.Cell style={{ padding: '0 0.25em' }}>
              {issueId && (
                <Button as={Link} basic compact
                  routeName="issueShow"
                  routeParams={{ issueId }}
                  size="mini"
                  icon="right arrow" />
              )}
            </Table.Cell>
          </Table.Row>
        );
      }} />
  );
};

const EntryText = props => {
  const { entry } = props;
  const { type, payload = {}, meta = {}, issue, initiator } = entry;

  if (type === 'issue/void') {
    return (
      <Fragment>
        <i className="red remove circle icon" />
        Administratorius anuliavo prašymą.
      </Fragment>
    );
  }

  if (type === 'issue/cancel') {
    return (
      <Fragment>
        <i className="yellow warning sign icon" />
        Vartotojas atšaukė savo prašymą.
      </Fragment>
    );
  }

  if (type === 'issue/cancelByAdmin') {
    return (
      <Fragment>
        <i className="yellow warning sign icon" />
        Administratorius atšaukė prašymą.
      </Fragment>
    );
  }

  if (type === 'issue/expireDidNotArrive') {
    return (
      <Fragment>
        <i className="yellow warning sign icon" />
        Vartotojas neatvyko pasirašyti sutarties per numatytą laiką.
      </Fragment>
    );
  }

  if (type === 'issue/expireDidNotAccept') {
    return (
      <Fragment>
        <i className="yellow warning sign icon" />
        Vartotojas nepriėmė kvietimo apsigyventi bendrabutyje per numatytą laiką.
      </Fragment>
    );
  }

  if (type === 'issue/expire') {
    return (
      <Fragment>
        <i className="yellow warning sign icon" />
        Prašymas atmestas.
      </Fragment>
    );
  }

  if (type === 'issue/invite') {
    const verbose_parts = [];
    if (payload.campus) {
      const campus = useSelector(getCampusById(payload.campus));
      campus && verbose_parts.push(`Bendrabutis: ${campus.abbr}`)
    }
    if (payload.faculty) {
      const faculties = payload.faculty.map(
        facultyId => useSelector(
          getFacultyById(facultyId)).abbr)
        .join(', ');
      faculties && verbose_parts.push(`Fakultetas: ${faculties}`)
    }
    if (payload.roomType) {
      const rooms = ROOM_TYPES.filter(
        room => payload.roomType.indexOf(room.id) > -1).map((room) => {
          return room.name;
        }).join(', ');
      rooms && verbose_parts.push(`Kambarys: ${rooms}`)
    }
    if (payload.sex) {
      const sexes = SEXES.filter(
        sex => payload.sex.indexOf(sex.id) > -1).map((sex) => {
          return sex.name;
        }).join(', ');
      sexes && verbose_parts.push(`Lytis: ${sexes}`)
    }
    const verbose_action = verbose_parts.join('; ');
    return (
      <Fragment>
        <i className="grey plus icon" />
        Administratorius pakvietė vartotoją apsigyventi bendrabutyje
        {verbose_action && ': '}{verbose_action}
      </Fragment>
    );
  }

  if (type === 'issue/inviteAccept') {
    return (
      <Fragment>
        <i className="blue info circle icon" />
        Vartotojas priėmė kvietimą apsigyventi bendrabutyje.
      </Fragment>
    );
  }

  if (type === 'issue/resolve') {
    return (
      <Fragment>
        <i className="green check circle icon" />
        Administratorius apgyvendino vartotoją.
      </Fragment>
    );
  }

  if (type === 'issue/updateStudentValidity') {
    const { valid } = entry.payload;
    if (valid) {
      return (
        <Fragment>
          <i className="green check circle icon" />
          Vartotojas pažymetas kaip VU studentas.
        </Fragment>
      );
    }
    else {
      return (
        <Fragment>
          <i className="red remove circle icon" />
          Vartotojas pažymetas kaip ne VU studentas.
        </Fragment>
      );
    }
  }

  if (type === 'issue/sendMail') {
    const { template, subject } = entry.payload.mailData;
    return (
      <Fragment>
        <i className="mail icon outline" />
        Išsiustas laiškas: <em>{subject || template}</em>.
      </Fragment>
    );
  }

  if (type === 'issue/create') {
    if (meta.type === 'freshmen') {
      return (
        <Fragment>
          <i className="grey plus icon" />
          Vartotojas užsiregistravo kaip pirmakursis.
        </Fragment>
      );
    }
    else {
      return (
        <Fragment>
          <i className="grey plus icon" />
          Vartotojas užsiregistravo į bendrą eilę.
        </Fragment>
      );
    }
  }

  if (type === 'issue/enqueue') {
    return (
      <Fragment>
        <i className="grey checkmark icon" />
        Vartotojas patvirtino savo prašymą ir irašytas į eilę.
      </Fragment>
    );
  }

  if (type === 'issue/confirm') {
    return (
      <Fragment>
        <i className="grey checkmark icon" />
        Vartotojas patvirtino registraciją.
      </Fragment>
    );
  }

  if (type === 'issue/update') {
    return (
      <Fragment>
        <i className="grey pencil icon" />
        {meta.admin
          ? 'Administratorius paredagavo prašymą.'
          : 'Vartotojas paredagavo savo prašymą.'}
      </Fragment>
    );
  }

  if (type === 'issue/expireNonStudent') {
    return (
      <Fragment>
        <i className="red remove circle icon" />
        Vartotojas pažymetas kaip ne VU studentas ir buvo išmestas iš bendros
        eilės.
      </Fragment>
    );
  }

  if (type === 'auth/login') {
    return (
      <Fragment>
        <i className="user icon" />
        Vartotojas prisijungė prie sistemos.
      </Fragment>
    );
  }

  // Default text
  return entry.type;
};
