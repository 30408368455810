import { flow } from 'functional';
import { map, filter, sortBy } from 'lodash/fp';
import { getIssueById } from '../issue/selectors';
import { getUserById } from '../user/selectors';

export const findIssueLogEntries = (options = {}) => state => {
  const { issueType, issueId } = options;
  return flow([
    state => state.issueLog.all,
    map(id => state.issueLog.byId[id]),
    issueType === 'queue' && (
      filter(entry => entry.meta.type === 'queue' && entry.meta.dequeued)
    ),
    issueType === 'freshmen' && (
      filter(entry => entry.meta.type === 'freshmen')
    ),
    issueId && (
      filter(entry => entry.payload && entry.payload.issueId === issueId)
    ),
    map(entry => {
      const { type, payload = {} } = entry;
      const { issueId, initiatorId } = payload;
      return {
        ...entry,
        issue: issueId && getIssueById(issueId)(state),
        initiator: initiatorId && getUserById(initiatorId)(state),
      };
    }),
    sortBy(entry => -entry.meta.now),
  ])(state);
};

export const getIssueLogLoading = state => state.issueLog.loading;
export const getIssueLogLoadedAll = state => state.issueLog.loadedAll;
export const getIssueLogLoadedFreshmen = state => state.issueLog.loadedFreshmen;
export const getIssueLogLoadedDequeued = state => state.issueLog.loadedDequeued;
