import produce from 'immer';
import { routerActionTypes } from '../router';

const AFTER_TRANSITION = [
  routerActionTypes.TRANSITION_SUCCESS,
  routerActionTypes.TRANSITION_ERROR,
];

export const campusReducer = produce((state, action) => {
  const { type, payload } = action;

  if (type === 'campus/LOAD') {
    for (let campus of payload.campuses) {
      state.byId[campus.id] = campus;
    }
    state.all = Object.keys(state.byId);
  }

  if (type === 'campus/CREATE_ERROR') {
    state.form = payload;
  }

  if (type === 'campus/UPDATE_ERROR') {
    state.form = payload;
  }

  if (AFTER_TRANSITION.includes(type)) {
    state.form = {};
  }
}, {
  byId: {},
  all: [],
  form: {},
});
