import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-tools';
import { Button, Form, Message } from 'semantic-ui-react';
import { Flex } from '../components';
import { getFacultyById, getFacultyForm } from './selectors';

export const FacultyEditPage = props => {
  const { facultyId } = props;
  const editing = !!facultyId;
  const faculty = useSelector(getFacultyById(facultyId));
  const facultyForm = useSelector(getFacultyForm);
  const dispatch = useDispatch();
  const [fields, setFields, fieldProps] = useForm({
    name: faculty && faculty.name || '',
    abbr: faculty && faculty.abbr || '',
  });
  return (
    <Fragment>
      <Flex m={-2} mb={0}>
        <Flex.Item m={2}>
          <Button icon="left arrow"
            onClick={() => history.back()} />
        </Flex.Item>
        <Flex.Item m={2} grow={2}>
          <h1>Miestelis</h1>
        </Flex.Item>
      </Flex>
      {facultyForm.error && (
        <Message error content={facultyForm.message} />
      )}
      <Form
        onSubmit={() => {
          dispatch({
            type: `faculty/${editing ? 'UPDATE' : 'CREATE'}`,
            payload: {
              facultyId,
              fields,
            },
          });
        }}>
        <Form.Input
          type="text"
          label="Pavadinimas"
          {...fieldProps.suiInput('name')} />
        <Form.Input
          type="text"
          label="Sutrumpinimas"
          {...fieldProps.suiInput('abbr')} />
        <Form.Button content="Išsaugoti" />
      </Form>
    </Fragment>
  );
};
