import React from 'react';
import { Table } from 'semantic-ui-react';
import { Link } from '../router';
import {
  formatDate,
  formatRoom,
  formatYesNo,
  formatIssueType,
  formatStatus,
  formatStudyType,
} from '../formatters';
import { PaginatedTable } from '../components';
import { Label } from '../label';

const DEFAULT_FIELDS = {
  displayName: true,
  personalCode: true,
  status: true,
};

export const IssueTable = props => {
  const { issues } = props;
  // Assign defaults
  const fields = {
    ...DEFAULT_FIELDS,
    ...(props.fields || {})
  };
  return (
    <PaginatedTable
      collection={issues}
      renderHeader={() => (
        <Table.Row>
          {fields.seqId && (
            <Table.HeaderCell collapsing
              textAlign="right"
              content="#" />
          )}
          {fields.displayName && (
            <Table.HeaderCell content="Vardas, pavardė" />
          )}
          {fields.personalCode && (
            <Table.HeaderCell content="Asmens kodas" />
          )}
          {fields.email && (
            <Table.HeaderCell content="El. paštas" />
          )}
          {fields.studyType && (
            <Table.HeaderCell content="Studijų tipas" />
          )}
          {fields.campus && (
            <Table.HeaderCell content="Miestelis" />
          )}
          {fields.faculty && (
            <Table.HeaderCell collapsing>
              <abbr title="Fakultetas">Fak.</abbr>
            </Table.HeaderCell>
          )}
          {fields.studyCycle && (
            <Table.HeaderCell collapsing>
              <abbr title="Studijų pakopa">SP</abbr>
            </Table.HeaderCell>
          )}
          {fields.accommodated && (
            <Table.HeaderCell collapsing>
              <abbr title="Apgyvendintas">
                <i className="icon building" />
              </abbr>
            </Table.HeaderCell>
          )}
          {fields.disadvantaged && (
            <Table.HeaderCell collapsing>
              <abbr title="Socialiai remtinas">Soc.</abbr>
            </Table.HeaderCell>
          )}
          {fields.roomType && (
            <Table.HeaderCell content="Kambarys" />
          )}
          {fields.createdAt && (
            <Table.HeaderCell content="Sukurta" />
          )}
          {fields.queuedAt && (
            <Table.HeaderCell content="Eilėje nuo" />
          )}
          {fields.type && (
            <Table.HeaderCell content="Tipas" />
          )}
          {fields.status && (
            <Table.HeaderCell content="Būsena" />
          )}
          {fields.labels && (
            <Table.HeaderCell content="Žymos" />
          )}
        </Table.Row>
      )}
      renderItem={issue => (
        <Table.Row key={issue.id}>
          {fields.seqId && (
            <Table.Cell collapsing
              textAlign="right"
              content={issue.seqId} />
          )}
          {fields.displayName && (
            <Table.Cell>
              <Link routeName="issueShow"
                routeParams={{
                  issueId: issue.id,
                }}>
                {issue.displayName}
              </Link>
            </Table.Cell>
          )}
          {fields.personalCode && (
            <Table.Cell>
              {issue.personalCode || issue.studentCode || '—'}
              {issue.sex === 'male' && <i className="icon male blue" />}
              {issue.sex === 'female' && <i className="icon female pink" />}
              {issue.studentCode && (
                <i className="icon id card outline"
                  title="Studento pažymejimas" />
              )}
              {issue.handicapped && (
                <i className="icon blue wheelchair"
                  title="Neįgalusis" />
              )}
              {issue.discrepancy && (
                <i className="icon yellow warning sign"
                  title="Yra neatitinkančių duomenų" />
              )}
              {issue.validStudent && (
                <i className="icon green checkmark" />
              )}
            </Table.Cell>
          )}
          {fields.email && (
            <Table.Cell collapsing>
              {issue.email}
            </Table.Cell>
          )}
          {fields.studyType && (
            <Table.Cell collapsing>
              {formatStudyType(issue.studyType)}
            </Table.Cell>
          )}
          {fields.campus && (
            <Table.Cell>
              {issue.campus && (
                <abbr title={issue.campus.name}>
                  {issue.campus.abbr}
                </abbr>
              )}
            </Table.Cell>
          )}
          {fields.faculty && (
            <Table.Cell collapsing>
              {issue.faculty && (
                <abbr title={issue.faculty.name}>
                  {issue.faculty.abbr}
                </abbr>
              )}
            </Table.Cell>
          )}
          {fields.studyCycle && (
            <Table.Cell collapsing>
              {issue.studyCycle}
            </Table.Cell>
          )}
          {fields.accommodated && (
            <Table.Cell collapsing>
              {formatYesNo(issue.accommodated)}
            </Table.Cell>
          )}
          {fields.disadvantaged && (
            <Table.Cell collapsing>
              {formatYesNo(issue.disadvantaged)}
            </Table.Cell>
          )}
          {fields.roomType && (
            <Table.Cell collapsing>
              {formatRoom(issue.roomType)}
            </Table.Cell>
          )}
          {fields.createdAt && (
            <Table.Cell collapsing>
              {formatDate(issue.createdAt)}
            </Table.Cell>
          )}
          {fields.queuedAt && (
            <Table.Cell collapsing>
              {formatDate(issue.queuedAt)}
            </Table.Cell>
          )}
          {fields.type && (
            <Table.Cell collapsing>
              {formatIssueType(issue.type)}
            </Table.Cell>
          )}
          {fields.status && (
            <Table.Cell collapsing>
              {formatStatus(issue.status)}
            </Table.Cell>
          )}
          {fields.labels && (
            <Table.Cell collapsing>
              {issue.labels.map(label => (
                <Label size="tiny"
                  key={label.id}
                  value={label.name}
                  color={label.color} />
              ))}
              {issue.labels.length === 0 && '—'}
            </Table.Cell>
          )}
        </Table.Row>
      )} />
  );
};
