import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { actionEmittingSaga, takeFirst } from 'redux-tools/saga';
import { requestApi } from '../api';
import { getAuthState } from '../auth/selectors';
import { isTransitionTo, routerActions } from '../router';
import { issueLoad, issueFetchOne, issueFetchAll, issueFetchDashboard } from './actions';
import { getIssueById, getIssueLoadedAll } from './selectors';

export const issueSaga = function* () {
  console.debug('running issueSaga');
  yield all([
    takeLatest('auth/READY', function* () {
      const auth = yield select(getAuthState);
      if (!auth.token) {
        return;
      }
      yield put(issueFetchDashboard());
    }),
    // TODO: Replace with takeLatest, but ensure they are called only once
    takeLatest('issue/FETCH_DASHBOARD', issueFetchDashboardSaga),
    takeLatest('issue/FETCH_ALL', issueFetchAllSaga),
    takeEvery('issue/FETCH_ONE', issueFetchOneSaga),
    takeEvery('issue/FETCH_MANY', issueFetchManySaga),
    takeLatest([
      isTransitionTo('issueShow'),
      isTransitionTo('issueEdit'),
    ], function* (action) {
      const { issueId } = action.payload.route.params;
      yield put(issueFetchOne(issueId));
    }),
    takeLatest(isTransitionTo('issueIndex'), function* () {
      const loaded = yield select(getIssueLoadedAll);
      if (loaded) {
        return;
      }
      yield put(issueFetchAll());
    }),
    takeLatest('issue/RESOLVE', issueResolveSaga),
    takeLatest('issue/REJECT', issueRejectSaga),
    takeLatest('issue/FILE_DELETE', issueFileDeleteSaga),
    takeEvery('issue/FILE_DELETE_SUCCESS', function* (action) {
      const { issueId } = action.payload;
      yield put(issueFetchOne(issueId));
    }),
    takeLatest('issue/UPDATE', issueUpdateSaga),
    takeEvery('issue/UPDATE_SUCCESS', function* (action) {
      const { issueId } = action.payload;
      yield put(routerActions.navigateTo('issueShow', { issueId }));
    }),
    takeLatest('issue/SEND_CONFIRMATION_TOKEN', issueSendConfirmationTokenSaga),
  ]);
};

const issueFetchDashboardSaga = actionEmittingSaga(function* (action) {
  const res = yield call(requestApi.get, '/application?dashboard=true');
  const issues = res.data;
  yield put(issueLoad(issues));
});

const issueFetchOneSaga = actionEmittingSaga(function* (action) {
  const { issueId } = action.payload;
  const res = yield call(requestApi.get, `/application/${issueId}`);
  const issue = res.data;
  yield put(issueLoad([issue]));
});

const issueFetchAllSaga = actionEmittingSaga(function* () {
  const res = yield call(requestApi.get, `/application?all`);
  const issues = res.data;
  yield put(issueLoad(issues));
});

const issueFetchManySaga = actionEmittingSaga(function* (action) {
  const { issueIds } = action.payload;
  const res = yield call(requestApi.post, `/application/find_bulk`, {
    ids: issueIds,
  });
  const issues = res.data;
  yield put(issueLoad(issues));
});

const issueResolveSaga = actionEmittingSaga(function* (action) {
  const { issueId } = action.payload;
  yield call(requestApi.post, `/application/${issueId}/settle`);
  yield put(issueFetchOne(issueId));
});

const issueRejectSaga = actionEmittingSaga(function* (action) {
  const { issueId } = action.payload;
  yield call(requestApi.post, `/application/${issueId}/void`);
  yield put(issueFetchOne(issueId));
});

const issueFileDeleteSaga = actionEmittingSaga(function* (action) {
  const { issueId, fileId } = action.payload;
  const reason = yield call(window.prompt,
    'Provide a reason for deleting the file. '
    + 'Press cancel to abort deletion of this file.');
  const confirmed = reason !== null;
  if (confirmed) {
    console.log('Deleting a file with a reason:', reason);
    yield call(requestApi.post,
      `/application/${issueId}/deleteFile`, {
        file: fileId,
        reason,
      });
    return payload;
  }
});

const issueUpdateSaga = actionEmittingSaga(function* (action) {
  const { issueId, fields } = action.payload;
  const issue = yield select(getIssueById(issueId));
  yield call(requestApi.put, `/application/${issueId}`, {
    type: issue.type,
    ...fields,
  });
});

const issueSendConfirmationTokenSaga = actionEmittingSaga(function* (action) {
  const { issueId } = action.payload;
  yield call(requestApi.post, `/application/${issueId}/send_token`);
});
