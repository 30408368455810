import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Label as SuiLabel, Message, Table } from 'semantic-ui-react';
import { getAuthUser } from '../auth/selectors';
import { Box, Flex } from '../components';
import { COLORS } from '../constants';
import { labelChangeColor, labelChangeName, labelCreate, labelDelete } from './actions';
import { getLabels } from './selectors';
import { Label } from './Label';

export const LabelIndexPage = props => {
  const user = useSelector(getAuthUser);
  const labels = useSelector(getLabels);
  const dispatch = useDispatch();
  if (user.role !== 'administrator') {
    return (
      <Message content="Jūs neturite prieigos prie sito funkcionalumo." />
    );
  }
  return (
    <Fragment>
      <Flex m={-2} mb={0}>
        <Flex.Item m={2} grow={2}>
          <h1>Žymos</h1>
        </Flex.Item>
      </Flex>
      <Table basic="very" collapsing celled compact size="small">
        <Table.Body>
          {labels.map(label => (
            <Table.Row>
              <Table.Cell>
                <Label editable
                  value={label.name}
                  color={label.color}
                  onChange={value => dispatch(labelChangeName(label.id, value))} />
              </Table.Cell>
              <Table.Cell>
                <ColorSelector
                  onChange={color => dispatch(labelChangeColor(label.id, color))} />
              </Table.Cell>
              <Table.Cell>
                <Button basic size="tiny" compact
                  icon="delete"
                  onClick={() => dispatch(labelDelete(label.id))} />
              </Table.Cell>
            </Table.Row>
          ))}
          {labels.length === 0 && (
            <Table.Row>
              <Table.Cell colSpan={3}>
                Nėra žymų
              </Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
      <Label editable
        placeholder="+ nauja žyma"
        onChange={value => dispatch(labelCreate(value))} />
    </Fragment>
  );
};

const ColorSelector = props => {
  const { onChange } = props;
  return (
    <Box mt={0.5}>
      {COLORS.map(color => (
        <SuiLabel circular empty size="large"
          style={{ cursor: 'pointer' }}
          key={color}
          color={color}
          onClick={() => onChange(color)} />
      ))}
    </Box>
  );
};
