import { toPairs } from 'lodash';
import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { actionEmittingSaga } from 'redux-tools/saga';
import { requestApi } from '../api';
import { isTransitionTo } from '../router';
import { settingsFetch } from './actions';

export const settingsSaga = function* () {
  yield all([
    takeLatest(isTransitionTo('settings'), function* () {
      yield put(settingsFetch());
    }),
    takeLatest('settings/FETCH', settingsFetchSaga),
    takeEvery('settings/UPDATE', settingsUpdateSaga),
  ]);
};

const settingsFetchSaga = actionEmittingSaga(function* () {
  const res = yield call(requestApi.get, '/settings');
  return res.data;
});

const settingsUpdateSaga = actionEmittingSaga(function* (action) {
  const settings = action.payload;
  for (let [key, value] of toPairs(settings)) {
    console.debug('about to update setting', key, value);
    yield call(requestApi.put, `/settings/${key}`, { value });
  }
  return settings;
});
