export const candidateSearchTerm = text => ({
  type: 'candidate/SEARCH_TERM',
  payload: text,
});

export const candidateFilterSet = filters => ({
  type: 'candidate/FILTER_SET',
  payload: filters,
});

export const candidateImportFile = file => ({
  type: 'candidate/IMPORT',
  payload: { file },
});

export const candidateObjectFetch = () => ({
  type: 'candidate/OBJECT_FETCH',
});

export const candidateImportObject = objectUuid => ({
  type: 'candidate/IMPORT',
  payload: { objectUuid },
});

export const candidateExport = () => ({
  type: 'candidate/EXPORT',
});

export const candidateLoad = payload => ({
  type: 'candidate/LOAD',
  payload,
});

export const candidateSave = () => ({
  type: 'candidate/SAVE',
});

export const candidateDelete = object => ({
  type: 'candidate/DELETE',
  payload: { object },
});

export const candidateSendInvitation = fields => ({
  type: 'candidate/SEND_INVITATION',
  payload: fields,
});
