import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { actionEmittingSaga } from 'redux-tools/saga';
import { requestApi } from '../api';
import { routerActions } from '../router';
import { facultyLoad } from './actions';

export const facultySaga = function* () {
  yield all([
    facultyFetchAllSaga(),
    takeLatest('faculty/UPDATE', facultyUpdateSaga),
    takeLatest('faculty/UPDATE_SUCCESS', function* () {
      yield put(routerActions.navigateTo('facultyIndex'));
    }),
  ]);
};

const facultyFetchAllSaga = actionEmittingSaga(function* () {
  const res = yield call(requestApi.get, '/faculty');
  const faculties = res.data;
  yield put(facultyLoad(faculties));
}, {
  typeHint: 'faculty/FETCH_ALL',
});

const facultyUpdateSaga = actionEmittingSaga(function* (action) {
  const { facultyId, fields } = action.payload;
  const res = yield call(requestApi.post, `/faculty/${facultyId}`, fields);
  yield put(facultyLoad([res.data]));
});
