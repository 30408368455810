export const login = (username, password) => ({
  type: 'auth/LOGIN',
  payload: { username, password },
});

export const logout = () => ({
  type: 'auth/LOGOUT',
});

export const tokenLoad = (token, userId) => ({
  type: 'auth/TOKEN_LOAD',
  payload: { token, userId },
});

export const tokenUnload = () => ({
  type: 'auth/TOKEN_UNLOAD',
});

export const authReady = () => ({
  type: 'auth/READY',
});
