import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'semantic-ui-react';
import { Loader } from '../components';
import { login } from './actions';
import { getAuthState } from './selectors';

export const LoginPage = props => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { success, error, loading } = useSelector(getAuthState);
  const dispatch = useDispatch();
  return (
    <div className="ui narrow top-padded text container">
      <h1 className="ui header">
        Log In
      </h1>
      {error && (
        <div className="ui error message">
          Error: {error.message}
        </div>
      )}
      {success && (
        <div className="ui success message">
          Success!
        </div>
      )}
      <Form>
        <Form.Input type="text"
          label="Username"
          value={username}
          onChange={e => setUsername(e.target.value)} />
        <Form.Input type="password"
          label="Password"
          value={password}
          onChange={e => setPassword(e.target.value)} />
        <Form.Button type="submit"
          fluid primary
          size="huge"
          onClick={() => dispatch(login(username, password))}
          content="Log In" />
      </Form>
      {loading && <Loader />}
    </div>
  );
};
