import { all, call, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';
import { actionEmittingSaga } from 'redux-tools/saga';
import { requestApi } from '../api';
import { getAuthState } from '../auth/selectors';
import { issueLogLoad, issueLogFetchDequeued, issueLogFetchFreshmen, issueLogFetchAll, issueLogFetch } from './actions';
import { getIssueLogLoadedAll, getIssueLogLoadedDequeued, getIssueLogLoadedFreshmen } from './selectors';
import { isTransitionTo } from '../router';
import { issueFetchMany } from '../issue/actions';
import { userFetchMany } from '../user/actions';

export const issueLogSaga = function* () {
  yield all([
    takeLatest(isTransitionTo('issueLog'), function* () {
      const loaded = yield select(getIssueLogLoadedAll);
      if (loaded) {
        return;
      }
      yield put(issueLogFetchAll());
    }),
    takeLatest(isTransitionTo('freshmenLog'), function* () {
      const loaded = yield select(getIssueLogLoadedFreshmen);
      if (loaded) {
        return;
      }
      yield put(issueLogFetchFreshmen());
    }),
    takeLatest(isTransitionTo('issueQueueDashboard'), function* () {
      const loaded = yield select(getIssueLogLoadedDequeued);
      if (loaded) {
        return;
      }
      yield put(issueLogFetchDequeued());
    }),
    takeLatest([
      'issue/RESOLVE_SUCCESS',
      'issue/REJECT_SUCCESS',
    ], function* (action) {
      const { issueId } = action.payload;
      yield put(issueLogFetch({ issueId }));
    }),
    takeLatest(isTransitionTo('issueShow'), function* (action) {
      const { issueId } = action.payload.route.params;
      yield put(issueLogFetch({ issueId }));
    }),
    takeLatest('issueLog/FETCH', issueLogFetchSaga),
    takeLatest('issueLog/FETCH_ALL', issueLogFetchAllSaga),
    takeLatest('issueLog/FETCH_DEQUEUED', issueLogFetchDequeuedSaga),
    takeLatest('issueLog/FETCH_FRESHMEN', issueLogFetchFreshmenSaga),
  ]);
};

const buildQueryString = params => Object.keys(params)
  .filter(key => params[key])
  .map(key => encodeURIComponent(key)
    + '=' + encodeURIComponent(params[key]))
  .join('&');

/**
 * A generic parametric log fetcher
 */
const issueLogFetchSaga = actionEmittingSaga(function* (action) {
  const { issueId } = action.payload;
  const queryString = buildQueryString({
    'payload.issueId': issueId,
    limit: 1000,
  });
  const res = yield call(requestApi.get, '/actionLog?' + queryString);
  const entries = res.data;
  yield put(issueLogLoad(entries));
  yield call(issueLogChainLoadSaga, entries);
});

const issueLogFetchAllSaga = actionEmittingSaga(function* (action) {
  const res = yield call(requestApi.get,
    '/actionLog?limit=1000');
  const entries = res.data;
  yield put(issueLogLoad(entries));
  yield call(issueLogChainLoadSaga, entries);
});

const issueLogFetchDequeuedSaga = actionEmittingSaga(function* (action) {
  const res = yield call(requestApi.get,
    '/actionLog?meta.type=queue&meta.dequeued=true&limit=200');
  const entries = res.data;
  yield put(issueLogLoad(entries));
  yield call(issueLogChainLoadSaga, entries);
});

const issueLogFetchFreshmenSaga = actionEmittingSaga(function* (action) {
  const res = yield call(requestApi.get,
    '/actionLog?meta.type=freshmen&limit=500');
  const entries = res.data;
  yield put(issueLogLoad(entries));
  yield call(issueLogChainLoadSaga, entries);
});

/**
 * Chain-loads log entry references
 */
const issueLogChainLoadSaga = function* (entries) {
  // Fetch issues
  const issuesById = yield select(state => state.issue.byId);
  const issueIds = entries
    .map(entry => entry.payload && entry.payload.issueId)
    .filter(issueId => issueId)
    .filter(issueId => !issuesById[issueId]);
  if (issueIds.length > 0) {
    yield put(issueFetchMany(issueIds));
  }
  // Fetch users
  const usersById = yield select(state => state.user.byId);
  const userIds = entries
    .map(entry => entry.payload && entry.payload.initiatorId)
    .filter(userId => userId)
    .filter(userId => !usersById[userId]);
  if (userIds.length > 0) {
    yield put(userFetchMany(userIds));
  }
};
