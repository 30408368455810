import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Message, Table } from 'semantic-ui-react';
import { getAuthUser } from '../auth/selectors';
import { Flex } from '../components';
import { Link } from '../router';
import { getFaculties } from './selectors';

export const FacultyIndexPage = props => {
  const user = useSelector(getAuthUser);
  const faculties = useSelector(getFaculties);
  if (user.role !== 'administrator') {
    return (
      <Message content="Jūs neturite prieigos prie sito funkcionalumo." />
    );
  }
  return (
    <Fragment>
      <Flex m={-2}>
        <Flex.Item m={2} grow={2}>
          <h1>Fakultetai</h1>
        </Flex.Item>
      </Flex>
      <Table compact size="small">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell content="Pavadinimas" />
            <Table.HeaderCell content="Sutrumpinimas" />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {faculties.map(faculty => (
            <Table.Row key={faculty.id}>
              <Table.Cell>
                <Link
                  routeName="facultyShow"
                  routeParams={{
                    facultyId: faculty.id,
                  }}>
                  {faculty.name}
                </Link>
              </Table.Cell>
              <Table.Cell>
                {faculty.abbr}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </Fragment>
  );
};
