import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, Input, Button, Loader } from 'semantic-ui-react';
import { SendInvitationModal, SendMailModal } from '../candidate';
import { AssignLabelModal } from '../candidate/AssignLabelModal';
import { Box, DropdownSelect, Flex, LabeledButton } from '../components';
import { STATUSES, STUDY_CYCLES } from '../constants';
import { getFaculties } from '../faculty/selectors';
import { IssueTable } from '../issue/IssueTable';
import { Label } from '../label';
import { getLabels } from '../label/selectors';
import { getSafeMode } from '../safeMode';
import {
  internationalCloseIssues,
  internationalDeleteAll,
  internationalExport,
  internationalFilterSet,
  internationalSearchTerm,
  internationalFetchAll
} from './actions';
import { getFilteredInternationalIssues, getInternationalFilters, getInternationalLoading } from './selectors';

export const InternationalPage = props => {
  const filters = useSelector(getInternationalFilters);
  const issues = useSelector(getFilteredInternationalIssues);
  const loading = useSelector(getInternationalLoading);
  const faculties = useSelector(getFaculties);
  const labels = useSelector(getLabels);
  const safeMode = useSelector(getSafeMode);
  const dispatch = useDispatch();
  return (
    <Fragment>
      <Flex m={-1} mb={1}>
        <Flex.Item m={1}>
          <h1>Registruoti tarptautiniai</h1>
        </Flex.Item>
        <Flex.Item m={1}>
          {loading && (
            <Loader inline active />
          ) || (
              <Button basic
                icon="refresh"
                onClick={() => dispatch(internationalFetchAll())} />
            )}
        </Flex.Item>
        <Flex.Item grow={1} />
        <Flex.Item m={1}>
          <Input
            icon="search"
            placeholder="Ieškoti..."
            value={filters.searchText}
            onChange={(e, data) => dispatch(
              internationalSearchTerm(data.value))} />
        </Flex.Item>
      </Flex>
      <Flex alignItems="center">
        <Flex.Item>
          <DropdownSelect inline
            text="Fakultetas"
            items={[
              {
                key: 'undefined',
                value: undefined,
                text: 'Visi fakultetai',
              },
              ...faculties.map(faculty => ({
                key: faculty.id,
                value: faculty.id,
                text: faculty.name,
              })),
            ]}
            value={filters.faculty}
            onChange={faculty => dispatch(
              internationalFilterSet({ faculty }))} />
          <DropdownSelect inline
            text="Studijų pakopa"
            items={STUDY_CYCLES.map(item => ({
              key: String(item.id),
              value: item.id,
              text: item.name,
              description: issues
                .filter(issue => issue.studyCycle === item.id)
                .length,
            }))}
            value={filters.studyCycle}
            onChange={studyCycle => dispatch(
              internationalFilterSet({ studyCycle }))} />
          <DropdownSelect inline
            text="Būsena"
            items={[
              {
                key: 'undefined',
                value: undefined,
                text: 'Visos būsenos',
              },
              ...STATUSES.map(item => ({
                key: String(item.id),
                value: item.id,
                text: item.name,
                description: issues
                  .filter(issue => issue.status === item.id)
                  .length,
              })),
            ]}
            value={filters.status}
            onChange={status => dispatch(
              internationalFilterSet({ status }))} />
          <DropdownSelect inline
            text="Žyma"
            items={[
              {
                key: 'undefined',
                value: undefined,
                text: 'Visos žymos',
              },
              ...labels.map(label => ({
                key: label.id,
                value: label.id,
                text: (
                  <Label color={label.color} value={label.name} />
                ),
              })),
            ]}
            value={filters.labelId}
            onChange={labelId => dispatch(
              internationalFilterSet({ labelId }))} />
        </Flex.Item>
        <Flex.Item grow={1} />
        {!safeMode && (
          <Flex.Item>
            <LabeledButton
              icon="warning sign"
              color="red"
              text="Išvalyti eilę"
              onClick={() => dispatch(internationalDeleteAll())} />
          </Flex.Item>
        )}
      </Flex>
      <IssueTable
        issues={issues}
        fields={{
          displayName: true,
          faculty: true,
          studyCycle: true,
          studyType: true,
          createdAt: true,
          status: true,
          labels: true,
          personalCode: false,
          email: true,
        }} />
      <LabeledButton
        text={`Parsisiusti CSV (${issues.length})`}
        icon="download"
        onClick={() => dispatch(internationalExport())} />
      {!safeMode && (
        <LabeledButton
          icon="dropbox"
          color="yellow"
          text={`Atmesti (${issues.length})`}
          onClick={() => dispatch(internationalCloseIssues(issues))} />
      )}
      <AssignLabelModal issues={issues} />
      <SendMailModal queueType={'international'} issues={issues} />
      <SendInvitationModal queueType={'international'} issues={issues} />
    </Fragment>
  );
};
