import axios from 'axios';

export const API_BASE = window.API_BASE || '/api/v1';

export const requestApi = axios.create({
  baseURL: API_BASE,
  timeout: 30000,
  headers: {},
});

export const setRequestAuthToken = token => {
  requestApi.defaults.headers.common['Authorization'] = token;
};
