import CSV from 'csv';
import { flow } from 'functional';
import { filter, map, sortBy, uniqBy } from 'lodash/fp';
import { all, call, put, select, take, takeEvery, takeLatest } from 'redux-saga/effects';
import { actionEmittingSaga } from 'redux-tools/saga';
import { requestApi } from '../api';
import { readFileAsText, serveTextAsFile } from '../file';
import { getFreshmenIssues, getFreshmenLoaded } from '../freshmen/selectors';
import { isTransitionTo } from '../router';
import { candidateLoad, candidateObjectFetch } from './actions';
import { getCandidateCsvData, getFilteredCandidates } from './selectors';
import { freshmenFetchAll } from '../freshmen/actions';

export const candidateSaga = function* () {
  yield all([
    takeLatest(isTransitionTo('freshmenCandidates'), function* () {
      // yield put(freshmenFetchStart());
      yield put(candidateObjectFetch());
    }),
    takeEvery('candidate/OBJECT_FETCH', candidateObjectFetchSaga),
    takeEvery('candidate/IMPORT', function* (action) {
      const freshmenLoaded = yield select(getFreshmenLoaded);
      if (!freshmenLoaded) {
        yield put(freshmenFetchAll());
        yield take('freshmen/FETCH_SUCCESS');
      }
      yield call(candidateImportSaga, action);
    }),
    takeEvery('candidate/EXPORT', candidateExportSaga),
    takeEvery('candidate/SAVE', candidateObjectSaveSaga),
    takeEvery('candidate/DELETE', candidateObjectDeleteSaga),
  ]);
};

const CSV_COLUMN_MAPPING = new Map([
  ['Vardas', {
    propName: 'firstName',
    csvToObject: x => x,
  }],
  ['Pavardė', {
    propName: 'lastName',
    csvToObject: x => x,
  }],
  ['El. paštas', {
    propName: 'email',
    csvToObject: x => x,
  }],
  ['Asmens kodas', {
    propName: 'personalCode',
    csvToObject: x => x,
  }],
  ['Fakultetas', {
    propName: 'facultyName',
    csvToObject: x => x,
  }],
  ['Studijų programa', {
    propName: 'programme',
    csvToObject: x => x,
  }],
  ['Gatvė', {
    propName: 'street',
    csvToObject: x => x,
  }],
  ['Gyvenvietė', {
    propName: 'region',
    csvToObject: x => x,
  }],
  ['Indeksas', {
    propName: 'postalCode',
    csvToObject: x => x,
  }],
  ['Miestas', {
    propName: 'city',
    csvToObject: x => x,
  }],
  ['Telefonai', {
    propName: 'phone',
    csvToObject: x => x,
  }],
  ['Studijų pakopa', {
    propName: 'studyCycle',
    csvToObject: x => x,
  }],
  ['Lytis', {
    propName: 'sex',
    csvToObject: str => {
      if (!str) {
        return;
      }
      const lstr = String(str).toLowerCase();
      if (lstr === 'v') {
        return 'male';
      }
      if (lstr === 'm') {
        return 'female';
      }
    },
    objectToCsv: str => {
      if (!str) {
        return;
      }
      const lstr = String(str).toLowerCase();
      if (lstr === 'male') {
        return 'v';
      }
      if (lstr === 'female') {
        return 'm';
      }
    },
  }],
  ['Soc. remtinas', {
    propName: 'disadvantaged',
    csvToObject: x => !!x,
    objectToCsv: x => x ? 'taip' : 'ne',
  }],
  ['Komentarai', {
    propName: 'notes',
    csvToObject: x => x,
  }],
  ['Būsena', {
    propName: 'status',
    csvToObject: x => x,
  }],
]);

const candidateObjectFetchSaga = actionEmittingSaga(function* () {
  const res = yield call(requestApi.get, `/upload/object`);
  const { objects } = res.data;
  return { objects };
});

const candidateImportSaga = actionEmittingSaga(function* (action) {
  const { file, objectUuid } = action.payload;
  let csvData;
  if (file) {
    // if (file.type !== 'text/csv') {
    //   console.log(file);
    //   throw new Error(`Expected a "text/csv" file, got "${file.type}"`);
    // }
    const text = yield call(readFileAsText, file);
    // Parse and prepare CSV
    const csv = new CSV(text);
    csvData = csv.parse();
    // Save csvData for saving later
  }
  else if (objectUuid) {
    const res = yield call(requestApi.get,
      `/upload/object?uuid=${objectUuid}`);
    const obj = res.data;
    csvData = obj.csvData;
  }
  else {
    throw new Error("Expecting 'file' or 'objectUuid', got none.");
  }
  // Select some pieces of state
  const freshmen = yield select(getFreshmenIssues);
  // Parse CSV
  const csvHeader = csvData[0];
  const csvBody = csvData.slice(1);
  // Build the candidates collection
  const candidates = csvBody.map((row, i) => {
    const candidate = {};
    // Save raw CSV
    candidate.csvRow = row;
    // Map CSV to object
    row.forEach((value, i) => {
      const mapping = CSV_COLUMN_MAPPING.get(csvHeader[i]);
      if (mapping) {
        // Transform value and assign to the object
        candidate[mapping.propName] = mapping.csvToObject(value);
      }
    });
    // Find a matching freshmen application
    const issueMatcherStrings = {
      3: 'pcode',
      2: 'email',
      1: 'name',
      0: undefined,
    };
    const issueMatcher = (candidate, issue) => (
      candidate.personalCode
        && candidate.personalCode === issue.personalCode
        && 3
      // Backup method (by email)
      || candidate.email
        && candidate.email === issue.email
        && 2
      // Backup method (by full name)
      || `${candidate.firstName} ${candidate.lastName}`
        === `${issue.firstName} ${issue.lastName}`
        && 1
      || 0
    );
    // Get first matching issue sorted my match weight
    const matchedObj = flow([
      map(issue => ({
        issue,
        weight: issueMatcher(candidate, issue),
      })),
      filter(obj => obj.weight > 0),
      sortBy(obj => -obj.weight),
    ])(freshmen)[0];
    const { issue, weight } = matchedObj || {};
    if (issue) {
      candidate.issue = issue.id;
      candidate.matchLevel = issueMatcherStrings[weight];
    }
    // Fill missing details from the main issue
    const defaultProp = (candidate, issue) => propName => {
      candidate[propName] = issue && issue[propName] || candidate[propName];
    };
    defaultProp(candidate, issue)('personalCode');
    defaultProp(candidate, issue)('email');
    defaultProp(candidate, issue)('studyCycle');
    defaultProp(candidate, issue)('disadvantaged');
    defaultProp(candidate, issue)('sex');
    defaultProp(candidate, issue)('status');
    defaultProp(candidate, issue)('notes');
    defaultProp(candidate, issue)('phoneNumber');
    // Find a matching faculty
    // candidate.faculty = faculties.find(x => {
    //   return x.name === candidate.facultyName;
    // });
    // Generate a unique id
    candidate.id = `${candidate.personalCode}_${i}`;
    console.debug(
      candidate.firstName,
      candidate.lastName,
      candidate.issue,
      weight,
      candidate.matchLevel,
      issue);
    return candidate;
  });
  // Build the candidate faculty list
  const faculties = uniqBy(x => x.facultyName)(candidates)
    .map(x => x.facultyName);
  // Update state
  yield put(candidateLoad({
    candidates,
    faculties,
    csvHeader,
    csvData,
  }));
});

const candidateExportSaga = actionEmittingSaga(function* () {
  const candidates = yield select(getFilteredCandidates);

  // if (!candidates || candidates.length === 0) {
  //   yield call(window.alert, 'Nothing to export!');
  //   return;
  // }

  // // 1:1 method
  // const csvHeader = yield select(getCandidateCsvHeader);
  // const rows = candidates.map(x => x.csvRow);

  // Dynamic method
  const csvHeader = [];
  CSV_COLUMN_MAPPING.forEach((mapping, key, map) => {
    csvHeader.push(key);
  });

  const rows = candidates.map(candidate => {
    const row = [];
    CSV_COLUMN_MAPPING.forEach((mapping, key, map) => {
      const objectToCsv = mapping.objectToCsv || (x => x);
      const value = objectToCsv(candidate[mapping.propName]);
      row.push(value || '');
    });
    return row;
  });

  // // Build CSV
  const csv = new CSV([
    csvHeader,
    ...rows,
  ]);

  // Serve the file
  yield call(serveTextAsFile, 'export-candidates.csv', csv.encode());
});

const candidateObjectSaveSaga = actionEmittingSaga(function* () {
  const csvData = yield select(getCandidateCsvData);
  if (!csvData) {
    yield call(window.alert, 'Nothing to save!');
    return;
  }
  const name = yield call(window.prompt, 'Enter the name for this file:');
  if (!name) {
    return;
  }
  const res = yield call(requestApi.post,
    '/upload/object', { name, csvData });
  const { uuid } = res.data;
  return {
    object: { name, uuid },
  };
});

const candidateObjectDeleteSaga = actionEmittingSaga(function* (action) {
  const { object } = action.payload;
  const confirmed = yield call(window.confirm, 'Are you sure?');
  if (!confirmed) {
    return;
  }
  const res = yield call(requestApi.delete,
    `/upload/object?uuid=${object.uuid}`);
});
