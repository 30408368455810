import React from 'react';
import { Button, Icon } from 'semantic-ui-react';

export const LabeledButton = props => {
  return (
    <Button {...props} icon labelPosition="left">
      <Icon name={props.icon} />
      {props.text}
    </Button>
  );
};
